import React, { useEffect, useState } from "react";
import Konva from "konva";
import { Stage, Layer, Circle, Rect, Text, Group } from "react-konva";
import "konva/lib/shapes/Rect";

const Adapter = ({ profile, position, connectedProfile }) => {
  const adapterWidth = 15;
  let x = 0;
  let y = 0;
  let width = profile.orientation === "horizontal" ? adapterWidth : 13;
  let height = profile.orientation === "horizontal" ? 13 : adapterWidth;

  //suspended profile/s and the same orientation
  const smallConnectorsAndSameOrientation =
    (profile.type === "suspended" ||
      connectedProfile.type === "suspended" ||
      profile.type === "wall-mounted" ||
      connectedProfile.type === "wall-mounted") &&
    profile.orientation === connectedProfile.orientation;

  if (smallConnectorsAndSameOrientation) {
    width = profile.orientation === "horizontal" ? 0 : 13;
    height = profile.orientation === "horizontal" ? 13 : 0;
  }

  switch (profile.orientation) {
    case "horizontal":
      if (position === "start") {
        if (smallConnectorsAndSameOrientation) {
          x = profile.connectorStart.x;
          y = profile.connectorStart.y;
        } else {
          x = profile.connectorStart.x - adapterWidth;
          y = profile.connectorStart.y;
        }
      } else if (position === "end") {
        if (smallConnectorsAndSameOrientation) {
          x = profile.connectorEnd.x;
          y = profile.connectorEnd.y;
        } else {
          x = profile.connectorEnd.x + profile.connectorEnd.width;
          y = profile.connectorEnd.y;
        }
      }
      break;
    case "vertical":
      if (position === "start") {
        if (smallConnectorsAndSameOrientation) {
          x = profile.connectorStart.x;
          y = profile.connectorStart.y;
        } else {
          x = profile.connectorStart.x;
          y = profile.connectorStart.y - adapterWidth;
        }
      } else if (position === "end") {
        if (smallConnectorsAndSameOrientation) {
          x = profile.connectorEnd.x;
          y = profile.connectorEnd.y;
        } else {
          x = profile.connectorEnd.x;
          y = profile.connectorEnd.y + profile.connectorEnd.height;
        }
      }
      break;
    default:
      break;
  }
  return <Rect x={x} y={y} width={width} height={height} fill="black" stroke="black" strokeWidth={1} name="" />;
};

export default Adapter;
