import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import CustomScroll from "react-custom-scroll";
import ConfiguratorNav from "../components/configuratorNav";
import ProfileObject from "../components/profile-object";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useProject } from "../store/projectProvider";
import axios from "axios";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import i18n from "i18next";
import {useTranslation} from "react-i18next";

const ProductProfile = () => {
  const navigate = useNavigate();
  let { profileId } = useParams();
  const { t, i18n} = useTranslation("translation");

  const { getProfileById, getProfileTypeDetails, project, getProfileCode, updateProfile } = useProject();
  const [profileTypeDetails, setProfileTypeDetails] = useState();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    unregister,
    formState: { errors },
  } = useForm();
  const watchAllFields = watch();
  const [product, setProduct] = useState();
  const [profile, setProfile] = useState({});
  const [profileCode, setProfileCode] = useState();
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {}, [watchAllFields]);

  useEffect(() => {
    //set init value
    if (profile.product?.selectedProperties && editMode) {
      Object.keys(profile.product.selectedProperties).map((prop) => {
        setValue(prop, profile.product.selectedProperties[prop].id);
      });
    }
  }, [product]);

  useEffect(() => {
    (async () => {
      if (watchAllFields.finishing && product) {
        const profileTmp = prepareProfileToUpdate(watchAllFields);
        const profileCode = await getProfileCode(profileTmp);
        setProfileCode(profileCode);
      }
    })();
  }, [watchAllFields]);

  useEffect(() => {
    (async () => {
      const profileTmp = await getProfileById(profileId);
      const profileTypeDetailsTmp = await getProfileTypeDetails(profileTmp.type);
      setProfile(profileTmp);
      setProfileTypeDetails(profileTypeDetailsTmp);
      const profileCode = await getProfileCode(profileTmp);
      setProfileCode(profileCode);

      let url = "https://api.konfigurator.jellydev.pl/api/product/";
      url =  url + profileTmp.length.apiId + "?lang=" + project.language;

      const res = await axios.get(url);
      setProduct(res.data);
      setEditMode(true);
    })();
  }, [profileId]);

  const prepareProfileToUpdate = (data) => {
    Object.keys(data).map((dataElement) => {
      data[dataElement] = getProfilePropertiesOption(data, dataElement);
    });

    if (product) {
      return {
        ...profile,
        product: {
          ...product,
          selectedProperties: data,
        },
      };
    }
  };

  const getProfilePropertiesOption = (data, properties) => {
    const prop = profile.product.properties.filter((prop) => prop.name === properties)[0];
    return prop.options.filter((o) => o.id === parseInt(data[properties]))[0];
  };

  const onSubmit = (data) => {
    const profilToUpdate = prepareProfileToUpdate(data);
    updateProfile(profilToUpdate);
    navigate("/profile/" + profile.id);
  };

  const addToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast(t("translation:profile:product_code") + " " + text + t("translation:profile:added_product"), {
      type: toast.TYPE.INFO,
    });
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ConfiguratorNav />
        <CustomScroll>
          <div className="configurator-wrapper lower">
            <div className="configurator-title">
              <h1>
                {t("translation:profile:config")}
              </h1>
              <p>{t("translation:profile:edit")}</p>
              <div>{profile && <ProfileObject profile={profile} />}</div>
            </div>

            <div className="item-configurator py-3">
              {project.language === "pl" ? (
                <p>{t("translation:profile:title")} {profileTypeDetails && profileTypeDetails.labelPl}</p>
              ):(
                <p>{t("translation:profile:title")} {profileTypeDetails && profileTypeDetails.labelEn}</p>
              )}
              <Row>
                <Col xs={6} className="profile-setup">
                  {profile.product &&
                    profile.product.properties.map((prop) => (
                      <div key={prop.id} className="configurator-select">
                        <p>{prop.label}</p>
                        <Form.Group controlId={prop.name}>
                          {prop.name === "length" ? (
                            <Form.Select aria-label={prop.label} disabled>
                              <option>{profile.length && profile.length.label}</option>
                            </Form.Select>
                          ) : (
                            <Form.Select aria-label={prop.label} {...register(prop.name)}>
                              {prop.options.map((option) => (
                                <option key={option.id} id={option.id} value={option.id}>
                                  {option.label}
                                </option>
                              ))}
                            </Form.Select>
                          )}
                        </Form.Group>
                      </div>
                    ))}
                  {profile.length && (
                    <div className="configurator-select">
                      <p>{t("translation:profile:product_code")}:</p>
                      <div className="clipboard" onClick={() => addToClipboard(profileCode)}>
                        {profileCode}
                        <img src="/img/content_copy.svg" alt="" />
                      </div>
                    </div>
                  )}
                </Col>
                <Col xs={6} className="item-image">
                  <div>
                    <img className="img-fluid" src={`/img/` + (profileTypeDetails && profileTypeDetails.image)} alt="" />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </CustomScroll>
        <div className="item-accept">
          <Link to={"/profile/" + profileId} className="btn btn-primary">
            {t("translation:profile:dont_save")}
          </Link>
          <button className="btn btn-primary" type="submit">
            {t("translation:profile:save_data")}
          </button>
        </div>
      </Form>
    </>
  );
};

export default ProductProfile;
