import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import {useProject} from "../store/projectProvider";
import {useTranslation} from "react-i18next";
// import CustomScroll from "react-custom-scroll";

const ConfiguratorNav = () => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const { project, saveProject, dispatch } = useProject();
  const [alertModal, setAlertModal] = useState(false);
  const { t, i18n} = useTranslation("translation");

  const canvaBlur = document.querySelector('.canva-blur');
  if(canvaBlur) {
    canvaBlur.classList.add('d-none');
  }

  const checkProfiles = () => {
    let check = false;
    project.profiles.map(profile => {
      profile.elements.map(element => {
        if(element.parentId === null && element.product === null) {
          check = true;
        }
      })
    })
    if(check === true) {
      return setAlertModal(true);
    } else {
      setShowModal((prevState) => !prevState);
    }
  }

  if(project.systems.length > 0) {
    const systems = [];
    dispatch({
      type: "UPDATE",
      project: {
        ...project,
        systems: systems,
      },
    });
  }


  return (
    <div className="configurator-nav">
      {" "}
      <Link to="/chooseType" className="btn btn-text">
        <img className="pe-2" src="/img/add-circle.svg" alt="" />
        {t("translation:choose_type:add_profile")}
      </Link>
      <Button className="btn-text btn-next img-right" onClick={() => checkProfiles()}>
        {t("translation:choose_type:end")}
        <img src="/img/arrow-circle-right.svg" alt="" />{" "}
      </Button>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        className="accesories-modal"
      >
        <Modal.Header>{t("translation:choose_type:go_to_accessory")}</Modal.Header>
        <Modal.Title>
          {t("translation:choose_type:end_confirm")}
        </Modal.Title>
        <Modal.Body draggable="true" className="horizontal-scroll">
          <Button
            onClick={() => {
              setShowModal((prevState) => !prevState);
              window.location.href = "/accessories";
              // navigate("/accessories");
            }}
            className="btn-primary"
          >
            {t("translation:choose_type:confirm")}
          </Button>
          <Button
            onClick={() => {
              setShowModal((prevState) => !prevState);
            }}
            className="btn-white"
          >
            {t("translation:choose_type:decline")}
          </Button>
        </Modal.Body>
      </Modal>
      <Modal show={alertModal} className="delete-modal list-modal">
        <Modal.Header className="p-0 pb-3">
          {t("translation:choose_type:warning")}
        </Modal.Header>
        <Button className="w-100" onClick={() => (setAlertModal(false))}>
          ok
        </Button>
      </Modal>
    </div>
  );
};
export default ConfiguratorNav;
