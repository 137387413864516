import React, { useEffect, useState } from "react";
import Konva from "konva";
import { Text } from "react-konva";
import "konva/lib/shapes/Rect";

const Title = ({ box, project, scale }) => {
  return (
    <>
      <Text
        x={0}
        y={-(50/scale)}
        width={box.width}
        height={30}
        align="center"
        text={project.name.toUpperCase()}
        fontSize={12 / scale}
        fill="#bababa"
        fontFamily="Termina"
        fontStyle="bold"
      />
      <Text
        x={0}
        y={-(35/scale)}
        width={box.width}
        height={30}
        align="center"
        text={project.designer.toUpperCase()}
        fontSize={12 / scale}
        fill="#bababa"
        fontFamily="Termina"
      />
    </>
  );
};

export default Title;
