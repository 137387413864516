import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import profileCeilingTypes from "../store/profileCeilingTypes";
import ConfiguratorNav from "../components/configuratorNav";
import { useProject } from "./../store/projectProvider";
import {useTranslation} from "react-i18next";

const Type = (prop) => {
  const { project } = useProject();
  const { t, i18n} = useTranslation("translation");

  const returnProfile = (profile, i) => {
    return (
      <Col xs={6} className="zoom type-item" key={i}>
        <Link to={`/chooseColor/` + profile.type} state={{ profile: profile }}>
          <div className="position-relative overflow-hidden">
            <img className="img-fluid" src={`img/` + profile.image} alt="" />
            <div className="small-shape"></div>
          </div>
        </Link>
        {project.language === "pl" ? (<p className="item-name">{profile.labelPl}</p>) : (<p className="item-name">{profile.labelEn}</p>)}
      </Col>
    );
  };

  const wallMountedProfiles = profileCeilingTypes.filter((profile) => profile.type === "wall-mounted");
  const ceilingMountedProfiles = profileCeilingTypes.filter((profile) => profile.type !== "wall-mounted");

  return (
    <>
      <ConfiguratorNav />
      <div className="configurator-wrapper">
        <div className="configurator-title border-bottom-0">
          <h1>{t("translation:choose_type:montage_type")}</h1>
        </div>
        <Row className="pt-4 mt-2">
          {project.surface === "wall" &&
            wallMountedProfiles.map((profile, i) => {
              return returnProfile(profile, i);
            })}
          {project.surface === "ceiling" &&
            ceilingMountedProfiles.map((profile, i) => {
              return returnProfile(profile, i);
            })}
        </Row>
      </div>
    </>
  );
};

export default Type;
