import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import profileCeilingTypes from "../store/profileCeilingTypes";
import CustomScroll from "react-custom-scroll";
import ConfiguratorNav from "../components/configuratorNav";
import { useProject } from "./../store/projectProvider";
import {useTranslation} from "react-i18next";
import { useLocation } from 'react-router-dom'

const Color = () => {
  const { project } = useProject();
  const { t, i18n} = useTranslation("translation");
  const location = useLocation()
  const { profile } = location.state

  const returnProfile = (profile, i, color) => {
    profile = {
      ...profile,
      apiId: color.apiId,
    }
    return (
      <Col xs={6} className="zoom type-item" key={i}>
        <Link to={`/profiles/` + profile.type + "/" + profile.apiId}>
          <div className="position-relative overflow-hidden">
            <img className="img-fluid" src={`/../img/` + color.image} alt="" />
            <div className="small-shape"></div>
          </div>
        </Link>
        {project.language === "pl" ? (<p className="item-name">{profile.labelPl} {color.labelPl}</p>) : (<p className="item-name">{profile.labelEn} {color.labelEn}</p>)}
      </Col>
    );
  };

  return (
    <>
      <ConfiguratorNav />
      <CustomScroll>
        <div className="configurator-wrapper">
          <div className="configurator-title border-bottom-0">
            <h1>{t("translation:choose_type:montage_color")}</h1>
          </div>
          <Row className="pt-4 mt-2">
            {profile.colors.map((color, i) => {
              return returnProfile(profile, i, color);
            })}
          </Row>
        </div>
      </CustomScroll>
    </>
  );
};

export default Color;