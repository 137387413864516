import { useState } from 'react';

export default function useCurrentProjectId() {
    const getId = () => {
        const idString = localStorage.getItem("projectEditId");
        const projectEditId = JSON.parse(idString);
        return projectEditId;
    };

    const [projectEditId, setProjectEditId] = useState(getId());

    const setId = (projectEditId) => {
        localStorage.setItem("projectEditId", JSON.stringify(projectEditId));
        setProjectEditId(projectEditId);
    };

    const removeId = () => {
        localStorage.removeItem("projectEditId");
    };

    return {
        getCurrentProjectId: getId,
        removeProjectEditId: removeId,
        setProjectEditId: setId,
        projectEditId: projectEditId,
    }
}