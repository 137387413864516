import React, { useContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import projectReducer from "./projectReducer";
import profileCeilingTypes from "./profileCeilingTypes";
import axios from "axios";
import useUser from "../components/useUser";
import useCurrentProjectId from "../components/useCurrentProjectId";
import useCurrentProjectCode from "../components/useCurrentProjectCode";
import ProfileObject from "../components/profile-object";
import systemTemplate from "../jsonTemplates/systemTemplate";
import i18n from "i18next";

export const ProjectContext = React.createContext({
  project: [],
  initialProject: [],
});

let initialProject;

try {
  initialProject = JSON.parse(localStorage.getItem("project")) ?? [];
} catch (e) {
  console.log("Profiles could not be parsed into JSON.");
  initialProject = [];
}

export const ProjectProvider = ({ children }) => {
  const [project, dispatch] = useReducer(projectReducer, initialProject);
  const { setProjectEditId, getCurrentProjectId } = useCurrentProjectId();
  const { setProjectCode } = useCurrentProjectCode();
  const { user, getUser } = useUser();

  const getProfileById = (id) => {
    return project.profiles.filter((profile) => profile.id === parseInt(id))[0];
  };

  const getSystemById = (id) => {
    return project.systems.filter((system) => system.id === parseInt(id))[0];
  }

  const getProfileTypeDetails = (type) => {
    return profileCeilingTypes.filter((t) => t.type === type)[0];
  };

  const updateProfile = (profile) => {
    dispatch({
      type: "UPDATE_PROFILE",
      profile: profile,
    });
    saveProject();
  };

  const updateAccessories = (plugsArray, connectorsArray, pendantsArray, electronicsArray, system) => {
    let plugs = [], connectors = [], pendants = [], electronics = [];
    if (plugsArray) plugs = plugsArray.filter((plug) => plug.count != 0);
    if (connectorsArray) connectors = connectorsArray.filter((connector) => connector.count != 0);
    if (pendantsArray) pendants = pendantsArray.filter((pendant) => pendant.count != 0);
    if (electronicsArray) electronics = electronicsArray.filter((electronics) => electronics.count != 0);

    system.accessories = {
      connectors: connectors,
      plugs: plugs,
      pendants: pendants,
      electronics: electronics
    }

    project.systems[system.id] = system;

    const newSystems = project.systems;

    dispatch({
      type: "UPDATE",
      project: {
        ...project,
        systems: [
          ...newSystems
        ]
      },
    });
    saveProject();
  };

  const saveProject = (us, designer) => {
    const projectData = {
      content: project,
    };
    let url;
    if(user || getUser()) {
      if (getCurrentProjectId()) {
        const id = getCurrentProjectId();
        url = `https://api.konfigurator.jellydev.pl/api/project/${id}?lang=${project.language}`;
      } else {
        url = `https://api.konfigurator.jellydev.pl/api/project?lang=${project.language}`;
      }
      axios
        .post(url, JSON.stringify(projectData))
        .then((response) => {
          setProjectCode(response.data.project.code);
          setProjectEditId(response.data.project.id);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const decodedCookies = decodeURIComponent(document.cookie);
      let projectData2;
      if(designer) {
        projectData2 = {
          content: project,
          "author": designer,
        };
      } else {
        projectData2 = {
          content: project,
          "author": project.designer,
        };
      }
      let ca = decodedCookies.split(';');
      let name = "code", name2 = "id", code, projectId;
      for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          code = c.substring(name.length, c.length);
          code = code.replace("=", "");
        }
        if (c.indexOf(name2) == 0) {
          projectId = c.substring(name2.length, c.length);
          projectId = projectId.replace("=", "");
        }
      }
      if(code) {
        url = `https://api.konfigurator.jellydev.pl/api/project/${projectId}?lang=${project.language}`;
        axios
          .post(url, JSON.stringify(projectData2), {
            headers: {
              "accesskey": `${code}`,
            },
          })
          .then((response) => {
            setProjectEditId(projectId);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        url = `https://api.konfigurator.jellydev.pl/api/project?lang=${project.language}`;
        axios
          .post(url, JSON.stringify(projectData2))
          .then((response) => {
            document.cookie = "code" + "=" + `${response.data.project.accessKey}` + ";path=/";
            document.cookie = "id" + "=" + `${response.data.project.id}` + ";path=/";
            setProjectCode(response.data.project.code);
            setProjectEditId(response.data.project.id);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const sortProfilesBySystems = () => {
    const systemsZm = [];

    const checkNext = (elem, system, prevIdsArray, direction) => {
      let prevId = elem.id;
      let index;
      switch (direction) {
        case "next":
          index = elem.connectorEnd.connectedProfileId;
          break;
        case "prev":
          index = elem.connectorStart.connectedProfileId;
          break;
      }
      while (index) {
        const nextProfil = getProfileById(index);
        let didProfileIsAlreadyInSystem = false;
        let didNextPrevProfileIsAlreadyInSystem = false;
        prevIdsArray.map((id) => {
          if (id === index) {
            didProfileIsAlreadyInSystem = true;
          }
          if (direction === "next") {
            if (id === getProfileById(prevId).connectorStart.connectedProfileId) {
              didNextPrevProfileIsAlreadyInSystem = true;
            }
          } else {
            if (id === getProfileById(prevId).connectorEnd.connectedProfileId) {
              didNextPrevProfileIsAlreadyInSystem = true;
            }
          }
        });
        if (!didProfileIsAlreadyInSystem) {
          system.push(nextProfil);
          prevIdsArray.push(index);
          if (direction === "prev" && nextProfil.connectorStart.connectedProfileId) {
            prevId = index;
            index = nextProfil.connectorStart.connectedProfileId;
          } else if (direction === "next" && nextProfil.connectorEnd.connectedProfileId) {
            prevId = index;
            index = nextProfil.connectorEnd.connectedProfileId;
          }
        } else {
          index = null;
        }
        if (didProfileIsAlreadyInSystem && !didNextPrevProfileIsAlreadyInSystem) {
          if (direction === "prev") checkNext(getProfileById(prevId), system, prevIdsArray, "next");
          else checkNext(getProfileById(prevId), system, prevIdsArray, "prev");
        }
      }
    };

    if(project.systems.length === 0) {
      project.profiles.map((elem) => {
        if (systemsZm.length === 0) {
          const system = [elem];
          const prevIdsArray = [elem.id];
          checkNext(elem, system, prevIdsArray, "next");
          checkNext(elem, system, prevIdsArray, "prev");
          systemsZm.push(system);
        } else {
          let didProfilIsInSystem = false;
          //checking if the profile is just in some system
          systemsZm.forEach((system) => {
            system.forEach((systemProfil) => {
              if (systemProfil.id === elem.id) {
                didProfilIsInSystem = true;
              }
            });
          });
          if (!didProfilIsInSystem) {
            const system = [elem];
            const prevIdsArray = [elem.id];
            checkNext(elem, system, prevIdsArray, "next");
            checkNext(elem, system, prevIdsArray, "prev");
            systemsZm.push(system);
          }
        }
      });

      const systems = [];
      systemsZm.map((system, id) => {
        systems.push({
          ...systemTemplate,
          id: id,
          profiles: system,
        })
      })

      dispatch({
        type: "UPDATE",
        project: {
          ...project,
          systems,
        },
      });
    }
  };

  const getElementById = (profile, elementId) => {
    const element = profile.elements.filter((i) => i.id === parseInt(elementId));
    return element[0];
  };

  const getTrackElementById = (profile, elementId, trackElementId) => {
    const element = getElementById(profile, elementId);
    const trackElement = element.product.elements.filter((i) => i.id === parseInt(trackElementId));
    return trackElement[0];
  };

  const updateElementInProfile = (profile, element) => {
    dispatch({
      type: "UPDATE_PROFILE",
      profile: {
        ...profile,
        elements: profile.elements.map((e) => (e.id === parseInt(element.id) ? element : e)),
      },
    });
    saveProject();
  };

  const updateElementsInProfile = (profile, elements) => {
    dispatch({
      type: "UPDATE_PROFILE",
      profile: {
        ...profile,
        elements: elements,
      },
    });
    saveProject();
  };

  const getProfileConnectors = (profile, orientation = profile.orientation) => {
    if (profile.type === "recessed" || profile.type === "surface") {
      let connectorStart = {};
      let connectorEnd = {};
      if (orientation === "horizontal") {
        connectorStart = { x: 0, y: 0, width: 19, height: 13, connectedProfileId: null };
        connectorEnd = {
          x: profile.elements.length * 39 + 19,
          y: 0,
          width: 19,
          height: 13,
          connectedProfileId: null,
        };
      } else {
        connectorStart = { x: 0, y: 0, width: 13, height: 19, connectedProfileId: null };
        connectorEnd = {
          x: 0,
          y: profile.elements.length * 39 + 19,
          width: 13,
          height: 19,
          connectedProfileId: null,
        };
      }
      return { connectorStart, connectorEnd };
    } else if (profile.type === "suspended" || profile.type === "wall-mounted") {
      let connectorStart = {};
      let connectorEnd = {};
      if (orientation === "horizontal") {
        connectorStart = { x: 0, y: 0, width: 1, height: 13, connectedProfileId: null };
        connectorEnd = {
          x: profile.elements.length * 39 + 1,
          y: 0,
          width: 1,
          height: 13,
          connectedProfileId: null,
        };
      } else {
        connectorStart = { x: 0, y: 0, width: 13, height: 1, connectedProfileId: null };
        connectorEnd = {
          x: 0,
          y: profile.elements.length * 39 + 1,
          width: 13,
          height: 1,
          connectedProfileId: null,
        };
      }
      return { connectorStart, connectorEnd };
    }
  };

  const removeProfile = (profile) => {
    dispatch({
      type: "REMOVE_PROFILE",
      profile: profile,
    });
    clearOtherProfilesConnections(profile);
    saveProject();
  };

  const getProductMaxLength = (profile, elementId) => {
    let elementsNum = 1;
    let elementFull = false;
    profile.elements.map((element, i) => {
      if (element.id > elementId) {
        if (element.product !== null) elementFull = true;
        if (!elementFull) elementsNum++;
      }
    });
    return elementsNum * 280;
  };

  const getProductCode = (product) => {
    let code = product.code;

    const productOrder = {
      "length" : null,
      "electronics": null,
      "temperature": null,
      "finishing": null,
      "optics": null
    }

    const sortedObject = Object.assign(productOrder, product.selectedProperties);
    Object.keys(sortedObject).map((prop) => {
      const propObj = product.selectedProperties[prop];
      if (propObj) {
        code += propObj.isDot ? "." : "";
        code += propObj.code;
      }
    });

    // Object.keys(product.selectedProperties).map((prop) => {
    //   const propObj = product.selectedProperties[prop];
    //   if (propObj) {
    //     code += propObj.isDot ? "." : "";
    //     code += propObj.code;
    //   }
    // });

    return code;
  };

  const getProfileCode = (profile) => {
    let code = profile.length.code;
    if (profile.product.selectedProperties) {
      Object.keys(profile.product.selectedProperties).map((prop) => {
        const propObj = profile.product.selectedProperties[prop];
        if (propObj) {
          code += propObj.isDot ? "." : "";
          code += propObj.code;
        }
      });
    }

    return code;
  };

  const getProfileLength = (profile) => {
    let length;
    let flag = false;
    if(profile && profile.length) {
      length = profile.length.label;
      const words = length.split(' ');
      length = parseInt(words[0]);
    }
    if(profile.connectorEnd && profile.connectorEnd.connectedProfileId) {
      if(getProfileById(profile.connectorEnd.connectedProfileId).orientation !== profile.orientation ) {
        length += 70;
        flag = true;
      }
    }
    if(profile.connectorStart && profile.connectorStart.connectedProfileId) {
      if(getProfileById(profile.connectorStart.connectedProfileId).orientation !== profile.orientation ) {
        length += 70;
        flag = true;
      }
    }
    if(profile.type !== "suspended" && profile.type !== "wall-mounted") {
      length += 280;
    }
    if(flag)  {
      return length;
    }
  }

  const haveIntersection = (r1, r2) => {
    return !(r2.x > r1.x + r1.width || r2.x + r2.width < r1.x || r2.y > r1.y + r1.height || r2.y + r2.height < r1.y);
  };

  const profileHasCollision = (selectedProfile) => {
    let intersect = false;
    project.profiles.map((profile, i) => {
      if (haveIntersection(selectedProfile, profile) && profile.id !== selectedProfile.id) {
        intersect = true;
      }
    });
    return intersect;
  };

  const getProfileNumberOfCollisions = (selectedProfile) => {
    let collisions = 0;
    project.profiles
      .filter((p) => p.id !== selectedProfile.id)
      .map((profile, i) => {
        if (haveIntersection(selectedProfile, profile)) {
          collisions++;
          if(selectedProfile.connectorEnd.connectedProfileId && selectedProfile.connectorStart.connectedProfileId) {
            collisions = 1;
          }
        }
      });
    return collisions;
  };

  const getElementAbsolutePosition = (element, profile) => {
    return {
      ...element,
      x: element.x + profile.x,
      y: element.y + profile.y,
    };
  };

  const profileConnected = (selectedProfile) => {
    let connected = false;
    let newCoords = {
      x: 0,
      Y: 0,
    };

    project.profiles
      .filter((p) => p.id !== selectedProfile.id)
      .map((profile, i) => {
        const selectedProfileConnectorStart = getElementAbsolutePosition(selectedProfile.connectorStart, selectedProfile);
        const selectedProfileConnectorEnd = getElementAbsolutePosition(selectedProfile.connectorEnd, selectedProfile);
        const profileConnectorStart = getElementAbsolutePosition(profile.connectorStart, profile);
        const profileConnectorEnd = getElementAbsolutePosition(profile.connectorEnd, profile);

        if(
            !(
                (selectedProfile.type === "suspended" || profile.type === "suspended") &&
                (
                    selectedProfile.type === "recessed" || selectedProfile.type === "surface"
                    || profile.type === "recessed" || profile.type === "surface"
                )
            )
        ) {
          if (
            haveIntersection(selectedProfileConnectorStart, profileConnectorStart) &&
            notConnected(selectedProfileConnectorStart, profileConnectorStart)
          ) {
            newCoords = profileConnect(selectedProfile, "start", profile, "start");
            findProfileForAutoconnect(selectedProfile, profile, "end", newCoords);
            connected = true;
          } else if (
            haveIntersection(selectedProfileConnectorStart, profileConnectorEnd) &&
            notConnected(selectedProfileConnectorStart, profileConnectorEnd)
          ) {
            newCoords = profileConnect(selectedProfile, "start", profile, "end");
            findProfileForAutoconnect(selectedProfile, profile, "end", newCoords);
            connected = true;
          } else if (
            haveIntersection(selectedProfileConnectorEnd, profileConnectorStart) &&
            notConnected(selectedProfileConnectorEnd, profileConnectorStart)
          ) {
            newCoords = profileConnect(selectedProfile, "end", profile, "start");
            findProfileForAutoconnect(selectedProfile, profile, "start", newCoords);
            connected = true;
          } else if (
            haveIntersection(selectedProfileConnectorEnd, profileConnectorEnd) &&
            notConnected(selectedProfileConnectorEnd, profileConnectorEnd)
          ) {
            newCoords = profileConnect(selectedProfile, "end", profile, "end");
            findProfileForAutoconnect(selectedProfile, profile, "start", newCoords);
            connected = true;
          }
        }
      });
    return {
      connected,
      newCoords,
    };
  };

  const findProfileForAutoconnect = (selectedProfile, connectedProfile, connectorPosition, newCords) => {
    let profileToConnect = connectedProfile;

    const checkNext = (nextProfileId, prevProfileId) => {
      const nextProfile = getProfileById(nextProfileId);
      if(nextProfile.connectorEnd.connectedProfileId && nextProfile.connectorEnd.connectedProfileId !== prevProfileId) {
        checkNext(nextProfile.connectorEnd.connectedProfileId, nextProfile.id);
      } else if(nextProfile.connectorStart.connectedProfileId && nextProfile.connectorStart.connectedProfileId !== prevProfileId) {
        checkNext(nextProfile.connectorStart.connectedProfileId, nextProfile.id);
      } else {
        profileToConnect = nextProfile;
      }
    }
    checkNext(connectedProfile.id, selectedProfile.id);

    selectedProfile.x = newCords.x;
    selectedProfile.y = newCords.y;

    if(connectorPosition === "start") {
      const selectedProfileConnectorPosition = getElementAbsolutePosition(selectedProfile.connectorStart, selectedProfile);
      if(profileToConnect.connectorStart.connectedProfileId === null) {
        const profileToConnectConnectorPosition = getElementAbsolutePosition(profileToConnect.connectorStart, profileToConnect);
        possibleToAutoconnect(selectedProfileConnectorPosition, "start", profileToConnectConnectorPosition, "start", selectedProfile, connectedProfile, profileToConnect);
      } else if(profileToConnect.connectorEnd.connectedProfileId === null) {
        const profileToConnectConnectorPosition = getElementAbsolutePosition(profileToConnect.connectorEnd, profileToConnect);
        possibleToAutoconnect(selectedProfileConnectorPosition, "start", profileToConnectConnectorPosition, "end", selectedProfile, connectedProfile, profileToConnect);
      }
    } else {
      const selectedProfileConnectorPosition = getElementAbsolutePosition(selectedProfile.connectorEnd, selectedProfile);
      if(profileToConnect.connectorStart.connectedProfileId === null) {
        const profileToConnectConnectorPosition = getElementAbsolutePosition(profileToConnect.connectorStart, profileToConnect);
        possibleToAutoconnect(selectedProfileConnectorPosition, "end", profileToConnectConnectorPosition, "start", selectedProfile, connectedProfile, profileToConnect);
      } else if(profileToConnect.connectorEnd.connectedProfileId === null) {
        const profileToConnectConnectorPosition = getElementAbsolutePosition(profileToConnect.connectorEnd, profileToConnect);
        possibleToAutoconnect(selectedProfileConnectorPosition, "end", profileToConnectConnectorPosition, "end", selectedProfile, connectedProfile, profileToConnect);
      }
    }

    // project.profiles
    //   .filter((p) => p.id !== selectedProfile.id)
    //   .filter((p) => p.id !== connectedProfile.id)
    //   .map((profile, i) => {
    //     const profileConnectorStart = getElementAbsolutePosition(profile.connectorStart, profile);
    //     const profileConnectorEnd = getElementAbsolutePosition(profile.connectorEnd, profile);
    //     if (connectorPosition === "start") {
    //       //const selectedProfileConnectorStart = getElementAbsolutePosition(selectedProfile.connectorStart, selectedProfile);
    //     } else if (connectorPosition === "end") {
    //       const selectedProfileConnectorEnd = getElementAbsolutePosition(selectedProfile.connectorEnd, selectedProfile);
    //       //end with 90 end
    //       if (profileConnectorEnd.connectedProfileId === null) {
    //         possibleToAutoconnect(selectedProfileConnectorEnd, "end", profileConnectorEnd, "end");
    //       }
    //       //end with 90 start
    //       else if (profileConnectorStart.connectedProfileId === null) {
    //         possibleToAutoconnect(selectedconst orEnd, "end", profileConnectorStart, "start");
    //       }
    //     }
    //   });
  };

  const possibleToAutoconnect = (connector1, connector1Position, connector2, connector2Position, selectedProfile, connectedProfile, profileToAutoConnect) => {
    if(connector1.x <= connector2.x + 23 && connector1.x >= connector2.x - 23
      && connector1.y <= connector2.y + 23 && connector1.y >= connector2.y - 23) {
      profileConnect(selectedProfile, connector1Position, profileToAutoConnect, connector2Position, connectedProfile);
    }
  };

  const notConnected = (connector1, connector2) => {
    return connector1.connectedProfileId === null && connector2.connectedProfileId === null;
  };

  const profileConnect = (selectedProfile, selectedConnectorPosition, profile, connectorPosition, profileToAutoConnect) => {
    //12 options:
    //8 options (connected angle 90%) with adapter
    //4 options (connected inline) without adapter
    let newCoords = {
      x: 0,
      Y: 0,
    };

    const adapterWidth = 15;
    switch (selectedProfile.orientation) {
      case "horizontal":
        switch (selectedConnectorPosition) {
          case "start":
            switch (profile.orientation) {
              case "horizontal":
                if (connectorPosition === "end") {
                  newCoords = {
                    x: profile.x + profile.width - 1,
                    y: profile.y,
                  };
                  if(profile.type === "wall-mounted" || profile.type === "suspended") {
                    newCoords = {
                      x: profile.x + profile.width - 4,
                      y: profile.y,
                    };
                  }
                }
                break;
                case "vertical":
                  if (connectorPosition === "start") {
                    newCoords = {
                      x: profile.x + adapterWidth,
                      y: profile.y - adapterWidth,
                    };
                  } else if (connectorPosition === "end") {
                    newCoords = {
                      x: profile.x + adapterWidth,
                      y: profile.y + profile.height - profile.width + adapterWidth - 2,
                    };
                  }
                  break;
                default:
              }
              break;
            case "end":
              switch (profile.orientation) {
                case "horizontal":
                  if (connectorPosition === "start") {
                    newCoords = {
                      x: profile.x - selectedProfile.width + 1,
                      y: profile.y,
                    };
                    if(profile.type === "wall-mounted" || profile.type === "suspended") {
                      newCoords = {
                        x: profile.x - selectedProfile.width + 4,
                        y: profile.y,
                      };
                    }
                  }
                  break;
                case "vertical":
                  if (connectorPosition === "start") {
                    if (
                      profile.type === "suspended" ||
                      selectedProfile.type === "suspended" ||
                      profile.type === "wall-mounted" ||
                      selectedProfile.type === "wall-mounted"
                    ) {
                      newCoords = {
                        x: profile.x - selectedProfile.width + profile.width - adapterWidth + 2,
                        y: profile.y - adapterWidth,
                      };
                    } else {
                      newCoords = {
                        x: profile.x - selectedProfile.width + profile.width - adapterWidth + 2,
                        y: profile.y - adapterWidth,
                      };
                    }
                  } else if (connectorPosition === "end") {
                    if(profile.type === "suspended" || profile.type === "wall-mounted") {
                      newCoords = {
                        x: profile.x - selectedProfile.width + profile.width - adapterWidth + 2,
                        y: profile.y + profile.height - selectedProfile.height + adapterWidth - 2,
                      };
                    } else {
                      newCoords = {
                        x: profile.x - selectedProfile.width + profile.width - adapterWidth + 2,
                        y: profile.y + profile.height - selectedProfile.height + adapterWidth - 2,
                      };
                    }
                  }
                  break;
                default:
              }
              break;
            default:
          }
          break;
        case "vertical":
          switch (selectedConnectorPosition) {
            case "start":
              switch (profile.orientation) {
                case "horizontal":
                  if (connectorPosition === "start") {
                    newCoords = {
                      x: profile.x - adapterWidth,
                      y: profile.y + adapterWidth,
                    };
                  } else if (connectorPosition === "end") {
                    if(profile.type === "suspended" || profile.type === "wall-mounted") {
                      newCoords = {
                        x: profile.x + profile.width - selectedProfile.width + adapterWidth - 2,
                        y: profile.y + adapterWidth,
                      };
                    } else {
                      newCoords = {
                        x: profile.x + profile.width - selectedProfile.width + adapterWidth - 2,
                        y: profile.y + adapterWidth,
                      };
                    }
                  }
                  break;
                case "vertical":
                  if (connectorPosition === "end") {
                    newCoords = {
                      x: profile.x,
                      y: profile.y + profile.height - 1,
                    };
                    if(profile.type === "wall-mounted" || profile.type === "suspended") {
                      newCoords = {
                        x: profile.x,
                        y: profile.y + profile.height - 4,
                      };
                    }
                  }
                  break;
                default:
              }
              break;
            case "end":
              switch (profile.orientation) {
                case "horizontal":
                  if (connectorPosition === "start") {
                    newCoords = {
                      x: profile.x - adapterWidth,
                      y: profile.y - selectedProfile.height + profile.height - adapterWidth + 2,
                    };
                  } else if (connectorPosition === "end") {
                    if (
                      selectedProfile.type === "suspended" ||
                      profile.type === "suspended" ||
                      profile.type === "wall-mounted" ||
                      selectedProfile.type === "wall-mounted"
                    ) {
                      newCoords = {
                        x: profile.x + profile.width - selectedProfile.width + adapterWidth - 2,
                        y: profile.y - selectedProfile.height + profile.height - adapterWidth + 2,
                      };
                    } else {
                      newCoords = {
                        x: profile.x + profile.width - selectedProfile.width + adapterWidth - 2,
                        y: profile.y - selectedProfile.height + profile.height - adapterWidth + 2,
                      };
                    }
                  }
                  break;
                case "vertical":
                  if (connectorPosition === "start") {
                    newCoords = {
                      x: profile.x,
                      y: profile.y - selectedProfile.height + 1,
                    };
                    if(profile.type === "wall-mounted" || profile.type === "suspended") {
                      newCoords = {
                        x: profile.x,
                        y: profile.y - selectedProfile.height + 4,
                      };
                    }
                  }
                  break;
                default:
              }
              break;
            default:
          }
          break;
        default:
      }
      addConnectedProfileId(profile, connectorPosition, selectedProfile, selectedConnectorPosition, profileToAutoConnect);
    return newCoords;
  };

  const clearOtherProfilesConnections = (selectedProfile) => {
    project.profiles
      .filter((profil) => profil.connectorEnd.connectedProfileId || profil.connectorStart.connectedProfileId)
      .map((profilMap) => {
        if (profilMap.connectorEnd.connectedProfileId && profilMap.connectorEnd.connectedProfileId === selectedProfile.id) {
          dispatch({
            type: "UPDATE_PROFILE",
            profile: {
              ...profilMap,
              connectorEnd: {
                ...profilMap.connectorEnd,
                connectedProfileId: null,
              },
            },
          });
        }
        if (profilMap.connectorStart.connectedProfileId && profilMap.connectorStart.connectedProfileId === selectedProfile.id) {
          dispatch({
            type: "UPDATE_PROFILE",
            profile: {
              ...profilMap,
              connectorStart: {
                ...profilMap.connectorStart,
                connectedProfileId: null,
              },
            },
          });
        }
      });
  };

  const addConnectedProfileId = (profile, connectorPosition, selectedProfile, selectedConnectorPosition, profileToAutoConnect) => {
    let profileIndex;
    let selectedProfileIndex;

    project.profiles.forEach((element, index) => {
      if (element.id === profile.id) {
        profileIndex = index;
      } else if (element.id === selectedProfile.id) {
        selectedProfileIndex = index;
      }
    });

    //remove all out-of-date connections from all other profiles
    clearOtherProfilesConnections(selectedProfile);

    //adding connections to profile
    if (connectorPosition === "end") {
      dispatch({
        type: "UPDATE_PROFILE",
        profile: {
          ...profile,
          connectorEnd: {
            ...profile.connectorEnd,
            connectedProfileId: selectedProfile.id,
          },
        },
      });
    } else {
      dispatch({
        type: "UPDATE_PROFILE",
        profile: {
          ...profile,
          connectorStart: {
            ...profile.connectorStart,
            connectedProfileId: selectedProfile.id,
          },
        },
      });
    }

    //adding connections to selectedProfile
    if (selectedConnectorPosition === "end") {
      project.profiles[selectedProfileIndex].connectorStart = {
        ...project.profiles[selectedProfileIndex].connectorStart,
        connectedProfileId: profileToAutoConnect ? profileToAutoConnect.id : null,
      };
      project.profiles[selectedProfileIndex].connectorEnd = {
        ...project.profiles[selectedProfileIndex].connectorEnd,
        connectedProfileId: profile.id,
      };

      // dispatch({
      //   type: "UPDATE_PROFILE",
      //   profile: {
      //     ...selectedProfile,
      //     connectorEnd: {
      //       ...selectedProfile.connectorEnd,
      //       connectedProfileId: profile.id,
      //     }
      //   },
      // });
    } else {
      project.profiles[selectedProfileIndex].connectorStart = {
        ...project.profiles[selectedProfileIndex].connectorStart,
        connectedProfileId: profile.id,
      };
      project.profiles[selectedProfileIndex].connectorEnd = {
        ...project.profiles[selectedProfileIndex].connectorEnd,
        connectedProfileId: profileToAutoConnect ? profileToAutoConnect.id : null,
      };

      // dispatch({
      //   type: "UPDATE_PROFILE",
      //   profile: {
      //     ...selectedProfile,
      //     connectorStart: {
      //       ...selectedProfile.connectorStart,
      //       connectedProfileId: profile.id,
      //     }
      //   },
      // });
    }
  };

  const elementIsOutside = (element, boundaryBox) => {
    let point1;
    let point2;
    let point3;
    let point4;

    point1 = { x: element.x, y: element.y };
    point2 = {
      x: element.x + element.width,
      y: element.y,
    };
    point3 = {
      x: element.x + element.width,
      y: element.y + element.height,
    };
    point4 = {
      x: element.x,
      y: element.y + element.height,
    };

    return (
      pointIsOutside(point1, boundaryBox) ||
      pointIsOutside(point2, boundaryBox) ||
      pointIsOutside(point3, boundaryBox) ||
      pointIsOutside(point4, boundaryBox)
    );
  };

  const pointIsOutside = (point, box) => {
    return point.x < box.x || point.x > box.x + box.width || point.y < box.y || point.y > box.y + box.height;
  };

  const getDistance = (p1, p2) => {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
  };

  const getProfileSize = (profile, orientation) => {
    let profileWidth = 0;
    let profileHeight = 0;

    switch (orientation) {
      case "horizontal":
        const connectorsHorizontal = getProfileConnectors(profile, "horizontal");
        profileWidth =
          profile.elements.length * 39 + connectorsHorizontal.connectorStart.width + connectorsHorizontal.connectorEnd.width + 2;
        profileHeight = 13;
        break;
      case "vertical":
        const connectorsVertical = getProfileConnectors(profile, "vertical");
        profileWidth = 13;
        profileHeight =
          profile.elements.length * 39 + connectorsVertical.connectorStart.height + connectorsVertical.connectorEnd.height + 2;
        break;
      default:
    }

    return { width: profileWidth, height: profileHeight };
  };

  useEffect(() => {
    localStorage.setItem("project", JSON.stringify(project));
  }, [project]);

  const contextValue = {
    updateAccessories,
    getProfileById,
    getSystemById,
    getProfileTypeDetails,
    getProfileCode,
    getProfileSize,
    getElementById,
    getProfileLength,
    getTrackElementById,
    profileHasCollision,
    getProfileNumberOfCollisions,
    getProfileConnectors,
    profileConnected,
    clearOtherProfilesConnections,
    elementIsOutside,
    saveProject,
    sortProfilesBySystems,
    updateProfile,
    updateElementInProfile,
    updateElementsInProfile,
    removeProfile,
    getProductMaxLength,
    getProductCode,
    project,
    dispatch,
  };
  return <ProjectContext.Provider value={contextValue}>{children}</ProjectContext.Provider>;
};

export const useProject = () => {
  const context = useContext(ProjectContext);
  if (!context) {
    throw new Error("useProject must be used within a ProjectProvider");
  }
  return context;
};

ProjectProvider.propTypes = {
  children: PropTypes.any,
};

export default ProjectProvider;
