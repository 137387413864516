import React, { useEffect, useState } from "react";
import Konva from "konva";
import { Group, Rect, Image } from "react-konva";
import "konva/lib/shapes/Rect";
import useImage from "use-image";

const Zoom = ({ zoomIn, zoomOut, printCanvaScreen, showRulers }) => {
  return (
    <div className="zoom">
      <div className="icon" onClick={() => zoomIn()}>
        <img src="/img/zoom-in.svg" alt="" />
      </div>
      <div className="icon" onClick={() => zoomOut()}>
        <img src="/img/zoom-out.svg" alt="" />
      </div>
      <div className="icon" onClick={() => printCanvaScreen()}>
        <img src="/img/camera.svg" alt="" />
      </div>
      <div className="icon profileRuler" onClick={() => showRulers()}>
        <img src="/img/straighten_black_24dp (2).svg" alt="" />
      </div>
    </div>
  );
};

export default Zoom;
