import React, { useEffect, useState } from "react";
import Konva from "konva";
import { Rect, Group, Image } from "react-konva";
import "konva/lib/shapes/Rect";
import useImage from "use-image";

const Tools = ({ scale, profile, removeProfile, rotateProfile }) => {
  const getImage = (image) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [img] = useImage(image);
    return img;
  };

  return (
    <>
      {profile && (
        <Group
          x={profile.x + profile.width / 2 - 38 / scale}
          y={profile.y - 45 / scale}
          // zIndex={1000}
        >
          <Rect
            x={0}
            y={0}
            width={76 / scale}
            height={32 / scale}
            fill="#077570"
            strokeWidth={1}
            tension={1}
            cornerRadius={16 / scale}
          />
          <Image
            image={getImage("/img/delete-white.svg")}
            x={43 / scale}
            y={5 / scale}
            width={20 / scale}
            height={20 / scale}
            onClick={() => removeProfile(profile)}
          />
          <Image
            image={getImage("/img/rotate.svg")}
            x={13 / scale}
            y={6 / scale}
            width={20 / scale}
            height={20 / scale}
            onClick={() => rotateProfile(profile)}
          />
        </Group>
      )}
    </>
  );
};

export default Tools;
