import React, { useState } from "react";

const ProfileObjectSimple = (params) => {
  let cells = [];

  if (params.connectors) cells.push(<div className="connector" key={"cStart"}></div>);

  for (let i = 0; i < params.cells; i++) {
    cells.push(<div className="profile-cell" key={i}></div>);
  }

  if (params.connectors) cells.push(<div className="connector" key={"cEnd"}></div>);

  if (params.profile && (params.profile.type === "suspended" || params.profile.type === "wall-mounted")) {
    for (let i = 0; i < params.profile.elements.length; i++) {
      cells.push(<div className="profile-cell" key={i}></div>);
    }
  } else if (params.profile) {
    cells.push(<div className="connector" key={"cStart"}></div>);
    for (let i = 0; i < params.profile.elements.length; i++) {
      cells.push(<div className="profile-cell" key={i}></div>);
    }
    cells.push(<div className="connector" key={"cEnd"}></div>);
  }

  return <div className="profile-object">{cells}</div>;
};
export default ProfileObjectSimple;
