import React, {useState} from "react";
import {Group, Image, Line, Rect, Text} from "react-konva";
import "konva/lib/shapes/Rect";
import useImage from "use-image";
import Tooltip from "./tooltip";

const Element = ({scale, text, element, profile, elementProduct, productCodeY, setProductCodeY, adapterWidth, setAdapterWidth}) => {
  const getImage = (image) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [img] = useImage(image);
    return img;
  };

  const getProductCode = (product) => {
    let code = product.code;
    const productOrder = {
      "length" : null,
      "electronics": null,
      "temperature": null
    }

    const sortedObject = Object.assign(productOrder, product.selectedProperties);
    Object.keys(sortedObject).map((prop) => {
      const propObj = product.selectedProperties[prop];
      if (propObj) {
        code += propObj.isDot ? "." : "";
        code += propObj.code;
      }
    });

    // Object.keys(product.selectedProperties).map((prop) => {
    //   const propObj = product.selectedProperties[prop];
    //   if (propObj) {
    //     code += propObj.isDot ? "." : "";
    //     code += propObj.code;
    //   }
    // });
    return code;
  };

  if(profile && (profile.type === "recessed" || profile.type === "surface")) {
    setAdapterWidth(19);
  } else {
    setAdapterWidth(1);
  }

  const ElementDescriptions = ({product}) => {
    return (
      <>
      {product.properties.map((prop, index) => {
          {setProductCodeY((50 + index * 12) / scale)}
          return (
            <Text
              key={index}
              fontSize={9 / scale}
              fill="#fff"
              text={prop.label + ": " + product.selectedProperties[prop.name].label}
              x={135 / scale}
              y={(50 + index * 12) / scale}
            />
          )
      })}
        <Text
        fontSize={9 / scale}
        fill="#fff"
        text={text + ": " + getProductCode(product)}
        x={135 / scale}
        y={productCodeY ? (productCodeY + 12 / scale) : 0}
        />
    </>)
  }

  return(
    <>
      {profile && element && element.product && !elementProduct && (
        <Group
          x={profile.orientation === "horizontal" ?
            (profile.x + adapterWidth + (element.id - 1) * 39 + ((element.product.selectedProperties['length'].value / 280) * 39) / 2 - 185 / scale)
            :
            profile.x - 185 / scale + profile.width / 2 + 1
          }
          y={profile.orientation === "horizontal" ?
            profile.y + profile.height + 2
            :
            (profile.y + adapterWidth + (element.id - 1) * 39 + ((element.product.selectedProperties['length'].value / 280) * 39) / 2) + 2
          }
        >
          <Tooltip
            image={getImage(element.product.thumbnail.url)}
            orientation={profile.orientation}
            title = {element.product.label}
            scale={scale}
          />
          <ElementDescriptions
            product={element.product}
          />
        </Group>
      )}
      {profile && element && element.product && elementProduct && (
        <Group
          x={profile.orientation === "horizontal" ?
            (profile.x + adapterWidth + (element.id - 1) * 39 + (elementProduct.id - 1) * (26 * element.product.selectedProperties.length.value / element.product.elements.length /187) + 13 - 185 / scale)
            :
            profile.x - 185 / scale + profile.width / 2 + 1
          }
          y={profile.orientation === "horizontal" ?
            profile.y + profile.height + 2
            :
            (profile.y + adapterWidth + (element.id - 1) * 39 + (elementProduct.id - 1) * (26 * element.product.selectedProperties.length.value / element.product.elements.length /187) + 13 + 1)
          }
        >
          <Tooltip
            image={getImage(elementProduct.product.thumbnail.url)}
            orientation={profile.orientation}
            title = {elementProduct.product.label}
            scale={scale}
          />
          <ElementDescriptions
            product={elementProduct.product}
            />
        </Group>
      )}
    </>
  )
}

export default Element;