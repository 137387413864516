import { Alert, Container, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation, initReactI18next } from "react-i18next";

const Warning = () => {
  const navigate = useNavigate();
  const [t] = useTranslation("translation");

  return (
    <>
      <Container fluid className="main-container px-0 configurator-start">
        <div className="plane">
          <div className="plane-options warning">
            <p>
              {t("translation:project_arrow:warning")}
            </p>

          </div>
          <div className="start-footer">
            <Button
              className="btn btn-text img-left btn-next"
              onClick={() => navigate("/dimensions")}
            >
              <img src="img/arrow-circle-left.svg" alt="" />
              {t("translation:project_arrow:prev_page")}
            </Button>
            <Button
              className="btn btn-text img-right btn-next"
              onClick={() => navigate("/chooseType")}
            >
              {t("translation:project_arrow:next_page")}
              <img src="img/arrow-circle-right.svg" alt="" />
            </Button>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Warning;
