import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import useUser from "../components/useUser";
import axios from "axios";
import i18n from "i18next";
import {useTranslation} from "react-i18next";

const Page = () => {
  const navigate = useNavigate();
  const { user, getUser } = useUser();
  const { t, i18n} = useTranslation("translation");

  useEffect(() => {
    if(!getUser()) {
      navigate('/login');
    } else {
      axios
        .get(`https://api.konfigurator.jellydev.pl/api/projects?lang=${i18n.language}`)
        .then((response) => {
          if(response.data && response.data.length > 0) {
            navigate("/list");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);


  return (
    <>
      <Container
        fluid
        className="quinary-bg main-container d-flex align-items-center justify-content-center"
      >
        <div className="first-project">
          <Link to="/create-project" className="btn btn-big">
            <img src="img/add-circle.svg" alt="" />{t("translation:create_project:new_project")}
          </Link>
          <img src="img/white-shape.svg" alt="" />
        </div>
      </Container>
    </>
  );
};
export default Page;
