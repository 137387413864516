import React, { useState, useEffect } from "react";
import { PDFDownloadLink, Font, pdf } from "@react-pdf/renderer";
import { Button } from "react-bootstrap";
import Template from "./pdfDocument";
import Template2 from "./pdfDocument2";
import useCurrentProjectCode from "../components/useCurrentProjectCode";
import {useTranslation} from "react-i18next";

Font.register({
  family: "roboto",
  fonts: [
    {
      src: "https://use.typekit.net/af/7f1b26/00000000000000007735a0ac/30/a?primer=66b6f45d0ef17dc090abd1a7c3c0c73ff39663a6235120922f51f9866883c799&fvd=n4&v=3",
      fontWeight: 400,
    },
    {
      src: "https://use.typekit.net/af/9d5bd3/00000000000000007735a09f/30/d?primer=66b6f45d0ef17dc090abd1a7c3c0c73ff39663a6235120922f51f9866883c799&fvd=n7&v=3",
      fontStyle: "normal",
      format: "WebOpenFontFormat",
      fontWeight: "700",
    },
  ],
});
Font.register({
  family: "termina",
  fonts: [
    {
      src: "https://use.typekit.net/af/7f1b26/00000000000000007735a0ac/30/a?primer=66b6f45d0ef17dc090abd1a7c3c0c73ff39663a6235120922f51f9866883c799&fvd=n4&v=3",
      fontWeight: 400,
    },
    {
      src: "https://use.typekit.net/af/3e72b6/00000000000000007735b21e/30/d?primer=f1a820c30740dc6492ec51f34d8da727435cd9e66a2845f8f7f2c58da176b1fd&fvd=n5&v=3",
      fontStyle: "normal",
      format: "WebOpenFontFormat",
      fontWeight: "500",
    },
    {
      src: "https://use.typekit.net/af/574117/00000000000000007735b222/30/d?primer=f1a820c30740dc6492ec51f34d8da727435cd9e66a2845f8f7f2c58da176b1fd&fvd=n7&v=3",
      fontStyle: "normal",
      format: "WebOpenFontFormat",
      fontWeight: "700",
    },
  ],
});

const PDFFile = (props) => {
  const { getCurrentProjectCode } = useCurrentProjectCode();
  const { t, i18n} = useTranslation("translation");

  return (
    <>
      {getCurrentProjectCode() ? (
        <>
          <PDFDownloadLink
            className="btn-text btn-next img-right"
            document={<Template project={props.project} systems={props.systems} />}
            fileName="Projekt.pdf"
          >
            {<Button className="btn-text btn-next img-right">{t("translation:pdfs:customer_pdf")}</Button>}
          </PDFDownloadLink>
          <PDFDownloadLink
            className="btn-text btn-next img-right"
            document={<Template2 project={props.project} systems={props.systems} />}
            fileName={`${getCurrentProjectCode()}.pdf`}
          >
            {<Button className="btn-text btn-next img-right">{t("translation:pdfs:dealer_pdf")}</Button>}
          </PDFDownloadLink>
        </>
      ) : (
        <PDFDownloadLink
          className="btn-text btn-next img-right"
          document={<Template project={props.project} systems={props.systems} />}
          fileName="Projekt.pdf"
        >
          <Button className="btn-text btn-next img-right">{t("translation:pdfs:pdf")}</Button>
        </PDFDownloadLink>
      )}
    </>
  );
};

export default PDFFile;
