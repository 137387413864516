import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import CustomScroll from "react-custom-scroll";
import ConfiguratorNav from "../components/configuratorNav";
import ProfileObject from "../components/profile-object";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useProject } from "./../store/projectProvider";
import axios from "axios";
import Loader from "./../components/loader";
import useProjectLanguage from "../components/useProjectLanguage";
import i18n from "i18next";
import {useTranslation} from "react-i18next";

const Modules = () => {
  const navigate = useNavigate();
  let { profileId, elementId } = useParams();
  const { project, getProfileById, getProductMaxLength } = useProject();
  const [profile, setProfile] = useState({});
  const [products, setProducts] = useState();
  const [maxLength, setMaxLength] = useState();
  const {projectLanguage} = useProjectLanguage();
  const { t, i18n} = useTranslation("translation");

  useEffect(() => {
    (async () => {
      const profileTmp = await getProfileById(profileId);
      setProfile(profileTmp);
      setProductMaxLength(profileTmp);
      loadProducts();
    })();
  }, []);

  const loadProducts = () => {
    axios.get(`https://api.konfigurator.jellydev.pl/api/category/2?lang=${project.language}`).then((response) => {
      setProducts(response.data.products);
    });
  };

  useEffect(() => {
    setProductMaxLength(profile);
    loadProducts();
  }, [elementId]);

  const setProductMaxLength = (profile) => {
    if (profile.length) {
      setMaxLength(getProductMaxLength(profile, elementId));
    }
  };

  const selectProduct = (product) => {
    navigate("/product/" + profileId + "/" + elementId + "/" + product.id);
  };

  const returnProduct = (product, productMinLength) => {
    if(!product.isDelete) {
      return(
        <Col
          xs={6}
          className={`zoom type-item ${productMinLength > maxLength ? `disabled` : ``}`}
          onClick={() => {
            selectProduct(product);
          }}
          key={product.id}
        >
          <div className="position-relative overflow-hidden">
            <img className="img-fluid" src={product.thumbnail.url} alt="" />
            <div className="small-shape"></div>
          </div>
          <p className="item-name">{product.label}</p>
        </Col>
      )
    }
  }

  return (
    <>
      <ConfiguratorNav />
      <CustomScroll>
        <div className="configurator-wrapper">
          <div className="configurator-title">
            <h1>
              {t("translation:profile:config")}
            </h1>
            <p>{t("translation:profile:modules_header")}</p>
            <div>{profile && <ProfileObject profile={profile} selectedElementId={elementId} />}</div>
          </div>

          <div className="choose-module">
            {products ? (
              <>
                <p>{t("translation:profile:choose_modules")}</p>
                <Row>
                  {products &&
                    products
                      .filter((prod) => prod.properties[0].name === "length")
                      .map((product, i) => {
                        const lengthProp = product.properties.filter((prop) => prop.name === "length")[0];
                        const productMinLength = lengthProp.options[0].value;
                        return (
                          <>
                            {project.surface === "wall" ? (
                              <>
                                {product.isOnWall === true ? (
                                  returnProduct(product, productMinLength)
                                ):(
                                  <></>
                                )}
                              </>
                            ):(
                              returnProduct(product, productMinLength)
                            )}
                          </>
                        )
                      })}
                </Row>
              </>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </CustomScroll>
    </>
  );
};

export default Modules;
