import {useState} from "react";
import axios from "axios";

export  default function useProjectLanguage() {
  const getLanguage = () => {
    const languageString = localStorage.getItem("language");
    const language = JSON.parse(languageString);
    return language;
  };

  const [projectLanguage, setProjectLanguage] = useState(getLanguage());

  const setLanguage = (language) => {
    localStorage.setItem("language", JSON.stringify(language));
    setProjectLanguage(language);
  };

  const removeProjectLanguage = () => {
    localStorage.removeItem("language");
  };

  return {
    getLanguage: getLanguage,
    removeProjectLanguage: removeProjectLanguage,
    setProjectLanguage: setLanguage,
    projectLanguage: projectLanguage,
  }
}