import { Alert, Container, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useProject } from "./../store/projectProvider";
import react, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Surface = () => {
  const navigate = useNavigate();
  const { project, dispatch, saveProject } = useProject();
  const { t, i18n} = useTranslation("translation");

  const [surface, setSurface] = useState();
  const [alert, showAlert] = useState();

  const toDimensions = () => {
    saveProject();
    navigate("/dimensions");
  };

  useEffect(() => {
    showAlert(false);
    dispatch({
      type: "UPDATE",
      project: { ...project, surface: surface },
    });
  }, [surface]);

  const toDimensionRoute = () => {
    if (surface) {
      toDimensions();
    } else {
      showAlert(true);
    }
  };

  return (
    <>
      <Container fluid className="main-container px-0 configurator-start">
        <div className="plane">
          <p>2/4</p>
          <p>{t("translation:surface:header")}</p>
          <div className="plane-options">
            {alert && <Alert variant="danger">{t("translation:surface:header")}</Alert>}
            <Button
              className={`btn btn-white ${surface === "ceiling" ? "selected" : ""}`}
              value="ceiling"
              onClick={(e) => {
                setSurface(e.target.value);
              }}
            >
              {t("translation:surface:ceiling")}
            </Button>
            <Button
              className={`btn btn-white ${surface === "wall" ? "selected" : ""}`}
              value="wall"
              onClick={(e) => {
                setSurface(e.target.value);
              }}
            >
              {t("translation:surface:wall")}
            </Button>
          </div>
          <div className="start-footer">
            <Link to="/create-project" className="btn btn-text img-left btn-back">
              <img src="img/arrow-circle-left.svg" alt="" />
              {t("translation:project_arrow:prev_page")}
            </Link>
            <Button
              className="btn btn-text img-right btn-next"
              onClick={() => {
                toDimensionRoute();
              }}
            >
              {t("translation:project_arrow:next_page")}
              <img src="img/arrow-circle-right.svg" alt="" />
            </Button>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Surface;
