import React, {useState} from "react";
import {Group, Image, Line, Rect, Text} from "react-konva";
import "konva/lib/shapes/Rect";
import useImage from "use-image";

const Tooltip = ({image, orientation, title, scale}) => {
  return (
    <Group>
      <Rect
        x={0 / scale}
        y={16 / scale}
        width={120 / scale}
        height={120 / scale}
        fill="000000"
        strokeWidth={1}
        tension={1}
      />
      <Image
        x={15 / scale}
        y={31 / scale}
        width={90 / scale}
        height={90 / scale}
        image={image}
      />
      <Line
        x={orientation === "horizontal" ? 177 / scale : 177 / scale - 1}
        y={orientation === "horizontal" ? 16 / scale : 16 / scale + 1}
        points={[0, 0, 16 / scale, 0, 8 / scale, -16 / scale]}
        closed
        stroke="black"
        fill="#000000"
      />
      <Rect
        x={119 / scale}
        y={16 / scale}
        width={250 / scale}
        height={120 / scale}
        fill="000000"
        strokeWidth={1}
        tension={1}
      />
      <Text
        text={title}
        fontSize={11 / scale}
        fontStyle={"bold"}
        fontFamily={"termina"}
        fill="#fff"
        x={135 / scale}
        y={30 / scale}
      />
    </Group>
  )
}

export default Tooltip;