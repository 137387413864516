import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Navigation from "../components/navbar";
import Area from "../components/area";
import { Container } from "react-bootstrap";

const LayoutCanvas = () => {
  const [newProfile, setNewProfile] = useState(false);
  const [canvaScreen, setCanvaScreen] = useState();
  const [ruler, setRuler] = useState(false);

  return (
    <>
      <Navigation />
      <Container fluid className="main-container px-0 configurator-container profile-configuration">
        <div className="configurator-left">
          <Outlet context={[setNewProfile, canvaScreen, setRuler]} canvaScreen={canvaScreen} setRuler={setRuler}/>
        </div>
        <div className="configurator-right">
          <div className="canva-blur d-none"></div>
          <Area newProfile={newProfile} setNewProfile={setNewProfile} setCanvaScreen={setCanvaScreen} ruler={ruler} setRuler={setRuler}/>
        </div>
      </Container>
    </>
  );
};

export default LayoutCanvas;
