import React, { useState, useEffect } from "react";
import { pdf } from "@react-pdf/renderer";
import { Button, Modal, Stack } from "react-bootstrap";
import {useNavigate, useOutletContext, useOutlet, Link} from "react-router-dom";
import axios from "axios";
import useUser from "../components/useUser";
import { useProject } from "./../store/projectProvider";
import TextareaAutosize from "react-textarea-autosize";
import useCurrentProjectId from "../components/useCurrentProjectId";
import Loader from "./../components/loader";
import ProfileObjectSimple from "../components/profile-object-simple";
import CustomScroll from "react-custom-scroll";
import PDFFile from "./../pdf/pdfFile";
import Template from "./../pdf/pdfDocument";
import Template2 from "./../pdf/pdfDocument2";
import Template3 from "./../pdf/pdfDocument3";
import useCurrentProjectCode from "../components/useCurrentProjectCode";
import ProductRow from "../components/product-row";
import i18n from "i18next";
import {useTranslation} from "react-i18next";

const Summary = () => {
  const { saveProject, dispatch } = useProject();
  const navigate = useNavigate();
  const [comment, setComment] = useState();
  const { user, getUser } = useUser();
  const { project, getProfileCode, getProfileLength } = useProject();
  const { getCurrentProjectId } = useCurrentProjectId();
  const { getCurrentProjectCode } = useCurrentProjectCode();
  const [send, setSend] = useState();
  const [closeLoader, setCloseLoader] = useState(false);
  const [replayModal, setReplayModal] = useState();
  const [canvaScreen, setCanvaScreen, setRuler] = useOutletContext();
  const [blockSend, setBlockSend] = useState();
  const { t, i18n} = useTranslation("translation");

  const editComment = () => {
    dispatch({
      type: "UPDATE",
      project: {
        ...project,
        comment: comment,
      },
    });
    setSend(false);
    setBlockSend(false);
    setReplayModal(true);
  };

  const renderAccesories = (accessory) => (
    <div key={accessory.id} className="position-relative configurator-data parameters-selected only-display">
      <div className="position-relative configurator-data w-100">
        <div className="configurator-data-img">
          <img className="img-fluid" src={accessory.thumbnail.url} alt="" />
        </div>
        <div className="configurator-data-content max-w">
          <p>{accessory.label}</p>
          {/* <p>
            Kolor: <span>biały</span>
          </p> */}
          <p>
            {t("translation:profile:product_code")}: <span>{accessory.code}</span>
          </p>
        </div>
        <div className="circle">{accessory.count} {t("translation:summary:count")}.</div>
      </div>
    </div>
  );

  const sendFile = async () => {
    if (getCurrentProjectCode() && !send && !blockSend) {
      const konva = document.querySelector(".konvajs-content");
      if (konva) {
        if ([...konva.childNodes].length > 0) {
          setSend(true)
          const blobFirst = await pdf(<Template project={project} systems={project.systems} />).toBlob();
          const blobSecond = await pdf(<Template2 project={project} systems={project.systems} />).toBlob();
          const blobThird = await pdf(<Template3 project={project} systems={project.systems} />).toBlob();

          const formData = new FormData();

          formData.append("pdf1", blobFirst);
          formData.append("pdf2", blobSecond);
          formData.append("pdf3", blobThird);

          if(user || getUser()) {
            axios
              .post(`https://api.konfigurator.jellydev.pl/api/project/${getCurrentProjectId()}/pdf?lang=${i18n.language}`, formData, {
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                },
              })
              .then((response) => {
                setCloseLoader(true);
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            const decodedCookies = decodeURIComponent(document.cookie);
            let ca = decodedCookies.split(';');
            let name = "code", code;
            for(let i = 0; i <ca.length; i++) {
              let c = ca[i];
              while (c.charAt(0) == ' ') {
                c = c.substring(1);
              }
              if (c.indexOf(name) == 0) {
                code = c.substring(name.length, c.length);
                code = code.replace("=", "");
              }
            }
            axios
              .post(`https://api.konfigurator.jellydev.pl/api/project/${getCurrentProjectId()}/pdf?lang=${i18n.language}`, formData, {
                headers: {
                  "accesskey": `${code}`,
                },
              })
              .then((response) => {
                setCloseLoader(true);
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }
      }
    }
  };

  useEffect(() => {
    saveProject();
    sendFile();
  }, [comment]);

  useEffect(() => {
    if(send && closeLoader) {
      setReplayModal(true);
    }
  }, [send]);

  useEffect(() => {
    document.querySelector('.canva-blur').classList.remove('d-none');
    if(!comment) {
      if(project.comment != '') {
        setComment(project.comment);
      }
    }
    setRuler(true);

    if(window && window.event && window.event.path && window.event.path.length === 0) {
      setBlockSend(true);
      setCloseLoader(true);
    } else  {
      setBlockSend(false);
    }
  }, [])

  return (
    <>
      <div className="configurator-nav">
        {" "}
        <Link to="/accessories" className="btn btn-text text-start btn-back img-left">
          <img src="/img/arrow-circle-left.svg" alt="" />
          {t("translation:summary:back_to_systems")}
        </Link>
        <PDFFile project={project} systems={project.systems} />
      </div>
      <CustomScroll>
        <div className="configurator-wrapper">
          <div className="configurator-title">
            <h1>{t("translation:summary:header")}</h1>
          </div>
          {project.systems.map((system, id) => {
            return(
              <div
                key={system.id}
              >
                <h3 className="pb-3 pt-3 configurator-subtitle">{t("translation:accessories:system")} {id + 1}</h3>
                {/* profiles */}
                {system.profiles.map((profile, id) => {
                  return (
                      <div key={id} className="item-configurator pt-4">
                        <ProfileObjectSimple profile={profile} />
                        <div className="position-relative configurator-data parameters-selected">
                          <div className="configurator-data-img">
                            <img className="img-fluid" src={profile.product.thumbnail.url} alt="" />
                          </div>
                          <div className="configurator-data-content">
                            <p>{profile.product.label}</p>
                            <p>
                              {t("translation:summary:length")}: <span>{profile.length && profile.length.label}</span>
                              {profile.type !== "suspended" ? (
                                  profile.type !== "wall-mounted" ? <span> (+280 mm)</span>:<></>
                                )
                                :<></>}
                            </p>
                            {getProfileLength(profile) ?
                              (
                                <p>{t("translation:profile:connectors_length")}: <span>{getProfileLength(profile)} mm</span></p>
                              ) : (<></>)}
                            <p>
                              {t("translation:summary:finishing")}:{" "}
                              <span>
                                      {profile.product?.selectedProperties && profile.product.selectedProperties.finishing.label}
                                    </span>
                            </p>
                            <p>
                              {t("translation:profile:product_code")}: <span>{profile.length && getProfileCode(profile)}</span>
                            </p>
                          </div>
                        </div>
                        {profile && profile.elements
                          .filter((elem) => elem.product)
                          .map((element) => {
                            {!send ? sendFile() : (<></>)}
                            return (
                              <div key={element.id}>
                                <ProductRow profile={profile} element={element} icons={false} />
                                {element.product.elements.length > 0 && element.product.elements
                                  .map(productElement => {
                                    if(productElement.product !== null) {
                                      return(
                                        <ProductRow
                                          profile={profile}
                                          element={element}
                                          trackElement={productElement}
                                          icons={false}
                                          key={element.id + "_" + productElement.id}
                                        />
                                      )
                                    }
                                  })}
                              </div>
                            )
                          })}
                      </div>
                  );
                })}
                {/* accessories */}
                {system.accessories.connectors && system.accessories.connectors.length > 0 ? (
                  <div>
                    <div className="item-configurator accessory pb-1">
                      <h3 className="pb-3 pt-3 configurator-subtitle">
                        {t("translation:summary:connectors")}:
                      </h3>
                      {system.accessories.connectors.map((connectors, index) => renderAccesories(connectors))}
                    </div>
                  </div>
                ) : (<></>)}
                {system.accessories.pendants && system.accessories.pendants.length > 0 ? (
                  <div>
                    <div className="item-configurator accessory pb-1">
                      <h3 className="pb-3 pt-3 configurator-subtitle">
                        {t("translation:summary:pendants")}:
                      </h3>
                      {system.accessories.pendants.map((pendants, index) => renderAccesories(pendants))}
                    </div>
                  </div>
                ) : (<></>)}
                {system.accessories.plugs && system.accessories.plugs.length > 0 ? (
                  <div>
                    <div className="item-configurator accessory pb-1">
                      <h3 className="pb-3 pt-3 configurator-subtitle">
                        {t("translation:summary:plugs")}:
                      </h3>
                      {system.accessories.plugs.map((plug, index) => renderAccesories(plug))}
                    </div>
                  </div>
                ) : (<></>)}
              </div>
            )
          })}
          <div>
            <TextareaAutosize
              value={comment}
              minRows={5}
              placeholder={comment}
              className="w-100 mt-4"
              onChange={(changeEvent) => setComment(changeEvent.target.value)}
            />
            <Stack direction="horizontal" gap={3}>
              <Button
                className="btn-black mb-3"
                key="zatwierdź"
                onClick={() => {
                  editComment();
                }}
              >
                {t("translation:summary:save_comment")}
              </Button>
            </Stack>
          </div>
        </div>
      </CustomScroll>
      {closeLoader ? (
        <>
        </>
      ) : (
        <Modal show={true}>
          {/*{sendFile()}*/}
          <Loader />
        </Modal>
      )}
      <Modal show={replayModal} className="delete-modal list-modal">
        <Modal.Header className="p-0 pb-3">
          {t("translation:summary:actualization")}
        </Modal.Header>
        <Button className="w-100" onClick={() => (setReplayModal(false))
        }>ok</Button>
      </Modal>
    </>
  );
};

export default Summary;
