let projectTemplate = {
  name: "Projekt 1",
  designer: "Projektant 1",
  surface: "ceiling",
  length: 3000,
  width: 4000,
  profileId: 1,
  profiles: [],
  accessories: [],
  systems: [],
  comment: '',
};

export default projectTemplate;
