import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import CustomScroll from "react-custom-scroll";
import ConfiguratorNav from "../components/configuratorNav";
import ProfileObject from "../components/profile-object";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useProject } from "./../store/projectProvider";
import axios from "axios";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Loader from "./../components/loader";
import elementTemplate from "../jsonTemplates/elementTemplate";
import ProductRow from "../components/product-row";
import i18n from "i18next";
import {useTranslation} from "react-i18next";

const Product = () => {
  const navigate = useNavigate();
  let { profileId, elementId, productId, trackElementId } = useParams();

  const { getProfileById, project, updateElementsInProfile, getElementById, getProductMaxLength, getProductCode } = useProject();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    unregister,
    formState: { errors },
  } = useForm();
  const watchAllFields = watch();
  const [profile, setProfile] = useState({});
  const [product, setProduct] = useState();
  const [element, setElement] = useState();
  const [editMode, setEditMode] = useState(false);
  const [maxLength, setMaxLength] = useState();
  const [productCode, setProductCode] = useState();
  const [projectors, setProjectors] = useState();
  const { t, i18n} = useTranslation("translation");

  useEffect(() => {}, [watchAllFields]);

  useEffect(() => {
    //set init value
    if (element?.product && editMode) {
      Object.keys(element.product.selectedProperties).map((prop) => {
        setValue(prop, element.product.selectedProperties[prop].id);
      });
    }
  }, [product]);

  useEffect(() => {
    (async () => {
      const profileTmp = await getProfileById(profileId);
      const elementTmp = await getElementById(profileTmp, elementId);

      setProfile(profileTmp);
      setElement(elementTmp);
      setProductMaxLength(profileTmp);

      const getProductId = elementTmp.product === null ? productId : elementTmp.product.id;

      setEditMode(productId === undefined);

      axios.get("https://api.konfigurator.jellydev.pl/api/product/" + getProductId + `?lang=${project.language}`).then((response) => {
        Object.keys(watchAllFields).map((prop) => {
          unregister(prop);
        });
        setProduct(response.data);
      });
    })();
  }, [elementId]);

  useEffect(() => {
    //set product code
    (async () => {
      const productTmp = await prepareProductToUpdate(watchAllFields);
      const productCode = await getProductCode(productTmp);
      setProductCode(productCode);
    })();
  }, [watchAllFields]);

  const addToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast(t("translation:profile:product_code") + " " + productCode + t("translation:profile:added_product"), {
      type: toast.TYPE.INFO,
    });
  };

  const setProductMaxLength = (profile) => {
    if (profile.length) {
      setMaxLength(getProductMaxLength(profile, elementId));
    }
  };

  const setNextElementsInCurrentElement = (elementsToUpdate, value) => {
    //update parentId on other elements
    if (element.product && element.product.selectedProperties.length !== undefined) {
      const numOfElemToUpdate = (parseInt(element.product.selectedProperties["length"].value) - 280) / 280;
      for (let i = element.id + 1; i <= element.id + numOfElemToUpdate; i++) {
        elementsToUpdate = elementsToUpdate.map((e) => (e.id === i ? { ...e, parentId: value } : e));
      }
    }
    return elementsToUpdate;
  };

  const prepareProductToUpdate = (data) => {
    Object.keys(data).map((dataElement) => {
      data[dataElement] = getProductPropertiesOption(data, dataElement);
    });

    //track/szynoprzewód
    let elements = [];

    if (editMode) {
      elements = element.product.elements;
      if (product?.code === "P111" && data.length && element.product.elements.length) {
        const length = data.length.value;
        const trackElementsCounter = Math.floor(parseInt(length) / 170);
        if(trackElementsCounter > element.product.elements.length) {
          for (let i = element.product.elements.length + 1; i <= trackElementsCounter; i++) {
            elements.push({
              ...elementTemplate,
              id: i,
            });
          }
        } else {
          for (let i = element.product.elements.length; i > trackElementsCounter; i--) {
            elements.pop();
          }
        }

      }
    } else {
      if (product?.code === "P111" && data.length) {
        const length = data.length.value;
        const trackElementsCounter = Math.floor(parseInt(length) / 170);

        for (let i = 1; i <= trackElementsCounter; i++) {
          elements.push({
            ...elementTemplate,
            id: i,
          });
        }

      }
    }
    return { ...product, selectedProperties: data, elements: elements };
  };

  const getProductPropertiesOption = (data, properties) => {
    const prop = product.properties.filter((prop) => prop.name === properties)[0];
    return prop.options.filter((o) => o.id === parseInt(data[properties]))[0];
  };

  const remove = () => {
    let elementsToUpdate = profile.elements.map((e) => (e.id === element.id ? { ...e, product: null } : e));
    elementsToUpdate = setNextElementsInCurrentElement(elementsToUpdate, null);
    updateElementsInProfile(profile, elementsToUpdate);
    navigate("/profile/" + profile.id);
  };

  const onSubmit = (data) => {
    //prepare new selectedData
    const productToUpdate = prepareProductToUpdate(data);

    let elementsToUpdate = profile.elements;
    elementsToUpdate = setNextElementsInCurrentElement(elementsToUpdate, null);
    elementsToUpdate = elementsToUpdate.map((e) => (e.id === element.id ? { ...e, product: productToUpdate } : e));

    //update parentId on other elements
    if (data.length !== undefined) {
      const numOfElemToUpdate = (parseInt(data.length.value) - 280) / 280;
      for (let i = element.id + 1; i <= element.id + numOfElemToUpdate; i++) {
        elementsToUpdate = elementsToUpdate.map((e) => (e.id === i ? { ...e, parentId: parseInt(elementId) } : e));
      }
    }

    updateElementsInProfile(profile, elementsToUpdate);
    navigate("/profile/" + profile.id);
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ConfiguratorNav />
        <CustomScroll>
          {product ? (
            <div className="configurator-wrapper lower">
              {/*{console.log(product)}*/}
              <div className="configurator-title">
                <h1>
                  {t("translation:profile:config")}
                </h1>
                <p>{t("translation:profile:edit")}</p>
                <div>{profile && <ProfileObject profile={profile} selectedElementId={elementId} />}</div>
              </div>
              <div className="item-configurator py-3">
                <p className="title-margin">{product?.label}</p>
                <Row>
                  <Col xs={6} className="profile-setup">
                    {product?.properties.map((prop) => {
                      return (
                        <div className="configurator-select" key={prop.name}>
                          <p>{prop.label}:</p>
                          <Form.Group controlId={prop.name}>
                            <Form.Select aria-label={prop.label} {...register(prop.name)}>
                              {prop.options.map((option) => {
                                if (prop.name === "length" && option.value > maxLength) {
                                  return "";
                                } else
                                  return (
                                    <option value={option.id} key={option.id}>
                                      {option.label}
                                    </option>
                                  );
                              })}
                            </Form.Select>
                          </Form.Group>
                        </div>
                      );
                    })}

                    {element && (
                      <div className="configurator-select">
                        <p>{t("translation:profile:product_code")}:</p>
                        <div className="clipboard" onClick={() => addToClipboard(productCode)}>
                          {productCode}
                          <img src="/img/content_copy.svg" alt="" />
                        </div>
                      </div>
                    )}
                  </Col>
                  <Col xs={6} className="item-image">
                    <div>
                      <img className="img-fluid" src={product?.thumbnail.url} alt="" />
                    </div>
                    {editMode && (
                      <Button variant="danger" className="remove-btn-mq d-none" onClick={() => remove()}>
                        {t("translation:profile:product:delete")}
                      </Button>
                    )}
                  </Col>
                </Row>
              </div>
              {product?.code === "P111" && (
                <div className="projectors">
                  {editMode && <h3>{t("translation:profile:product:projectors")}:</h3>}

                  {element.product?.elements.filter((e) => e.product !== null).length === 0 && (
                    <p className="mt-4">{t("translation:profile:product:busbar_edit")}</p>
                  )}

                  {element.product?.elements
                    .filter((e) => e.product !== null)
                    .map((trackElement) => {
                      return (
                        <ProductRow
                          profile={profile}
                          element={element}
                          trackElement={trackElement}
                          key={element.id + "_" + trackElement.id}
                          margins={false}
                        />
                      );
                    })}
                </div>
              )}
              <Row>
                <Col>
                  {editMode && (
                    <Button variant="danger" className={`mt-5 remove-btn`} onClick={() => remove()}>
                      {t("translation:profile:product:delete")}
                    </Button>
                  )}
                </Col>
              </Row>
            </div>
          ) : (
            <Loader />
          )}
        </CustomScroll>
        <div className="item-accept">
          <Link to={editMode ? "/profile/" + profileId : "/modules/" + profileId + "/" + elementId} className="btn btn-primary">
            {t("translation:profile:dont_save")}
          </Link>
          <button className="btn btn-primary" type="submit">
            {t("translation:profile:save_data")}
          </button>
        </div>
      </Form>
    </>
  );
};

export default Product;
