import React, { useEffect } from "react";
import { Container, Button, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useProject } from "./../store/projectProvider";
import { useTranslation } from "react-i18next";

const Dimensions = () => {
  const navigate = useNavigate();
  const { project, dispatch, saveProject } = useProject();
  const { t, i18n} = useTranslation("translation");

  const [length, setLength] = React.useState(3000);
  const [width, setWidth] = React.useState(4000);
  const [approve, setApprove] = React.useState(false);

  const setDimensions = () => {
    saveProject();
    navigate("/warning");
  };

  const minMaxControl = () => {
    if(length < 1000) {
      setLength(1000);
    } else if( length > 25000) {
      setLength(25000);
    }
    if(width < 1000) {
      setWidth(1000);
    } else if(width > 25000) {
      setWidth(25000);
    }
    setApprove(true);
  }

  useEffect(() => {
    if(approve) {
      setDimensions();
    }
  }, [approve])

  useEffect(() => {
    dispatch({
      type: "UPDATE",
      project: { ...project, width: width, length: length },
    });
    // eslint-disable-next-line
  }, [length, width]);

  return (
    <>
      <Container fluid className="main-container px-0 configurator-start">
        <div className="dimensions">
          <p>3/4</p>
          <div className="dimensions-length">
            <p>{project.surface === "wall" ? t("translation:dimensions:wall_height") : t("translation:dimensions:ceiling_length")}</p>
            <p className="d-flex justify-content-center">
              <label className="w-100 d-flex flex-nowrap align-items-center justify-content-center">
                <Form.Control
                    className="text-end me-1 short-inputs"
                    type="number"
                    value={length / 10}
                    onChange={(changeEvent) => setLength(changeEvent.target.value * 10)}
                    min={1000}
                    max={25000}
                />CM
              </label>
            </p>

            <Form className="dimensions-form">
              <Form.Range
                value={length}
                style={{
                  background: `linear-gradient(to right, black 0%, black ${(length / 25000) * 100 - 2}%, #ddd ${
                    (length / 25000) * 100 - 2
                  }%, #ddd 100%)`,
                }}
                onChange={(changeEvent) => setLength(parseInt(changeEvent.target.value))}
                min={1000}
                max={25000}
              />
            </Form>
          </div>
          <div className="dimensions-width">
            <p>{project.surface === "wall" ? t("translation:dimensions:wall_width") : t("translation:dimensions:ceiling_width")}</p>
            <p className="d-flex justify-content-center">
              <label className="w-100 d-flex flex-nowrap align-items-center justify-content-center">
                <Form.Control
                    className="text-end me-1 short-inputs"
                    type="number"
                    value={width / 10}
                    onChange={(changeEvent) => setWidth(changeEvent.target.value * 10)}
                    min={1000}
                    max={25000}
                />CM
              </label>
            </p>

            <Form className="dimensions-form">
              <Form.Range
                value={width}
                style={{
                  background: `linear-gradient(to right, black 0%, black ${(width / 25000) * 100 - 2}%, #ddd ${
                    (width / 25000) * 100 - 2
                  }%, #ddd 100%)`,
                }}
                onChange={(changeEvent) => {
                  setWidth(parseInt(changeEvent.target.value));
                }}
                min={1000}
                max={25000}
              />
            </Form>
          </div>
          <div className="start-footer">
            {" "}
            <Link to="/surface" className="btn btn-text img-left btn-back">
              <img src="img/arrow-circle-left.svg" alt="" />
              {t("translation:project_arrow:prev_page")}
            </Link>
            <div onClick={() => minMaxControl()} className="btn btn-text img-right btn-next">
              {t("translation:project_arrow:next_page")}
              <img src="img/arrow-circle-right.svg" alt="" />
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Dimensions;
