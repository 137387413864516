import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import CustomScroll from "react-custom-scroll";
import ConfiguratorNav from "../components/configuratorNav";
import ProfileObject from "../components/profile-object";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useProject } from "../store/projectProvider";
import axios from "axios";
import Loader from "../components/loader";
import i18n from "i18next";
import {useTranslation} from "react-i18next";

const Projectors = () => {
  const navigate = useNavigate();
  let { profileId, elementId, trackElementId } = useParams();
  const { getProfileById, project, getProductMaxLength } = useProject();
  const [profile, setProfile] = useState({});
  const [products, setProducts] = useState();
  const [maxLength, setMaxLength] = useState();
  const { t, i18n} = useTranslation("translation");

  useEffect(() => {
    (async () => {
      const profileTmp = await getProfileById(profileId);
      setProfile(profileTmp);
      loadProducts();
    })();
  }, []);

  const loadProducts = () => {
    axios.get(`https://api.konfigurator.jellydev.pl/api/category/6?lang=${project.language}`).then((response) => {
      setProducts(response.data.products);
    });
  };

  useEffect(() => {
    loadProducts();
  }, [elementId]);

  const selectProduct = (product) => {
    navigate("/product-projector/" + profileId + "/" + elementId + "/" + trackElementId + "/" + product.id);
  };

  return (
    <>
      <ConfiguratorNav />
      <CustomScroll>
        <div className="configurator-wrapper">
          <div className="configurator-title">
            <h1>{t("translation:profile:product:busbar_config")}</h1>
            <p>{t("translation:profile:product:busbar_choose_projector")}</p>
            <div>
              {profile && (
                <ProfileObject profile={profile} selectedElementId={elementId} selectedTrackElementId={trackElementId} />
              )}
            </div>
          </div>

          <div className="choose-module">
            {products ? (
              <>
                <p>{t("translation:profile:product:choose_projector")}:</p>
                <Row>
                  {products?.map((product, i) => {
                    return (
                      <Col
                        xs={6}
                        className={`zoom type-item`}
                        onClick={() => {
                          selectProduct(product);
                        }}
                        key={product.id}
                      >
                        <div className="position-relative overflow-hidden">
                          <img className="img-fluid" src={product.thumbnail.url} alt="" />
                          <div className="small-shape"></div>
                        </div>
                        <p className="item-name">{product.label}</p>
                      </Col>
                    );
                  })}
                </Row>
              </>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </CustomScroll>
    </>
  );
};

export default Projectors;
