import React, {useState, useEffect} from "react";
import { useForm } from "react-hook-form";
import { Alert, Container, Form, Button, Row, Col, Modal } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useUser from "../components/useUser";
import i18n from "i18next";
import {useTranslation} from "react-i18next";

const Data = () => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [address, setAddress] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [nipNumber, setNipNumber] = useState('');
  const [pass, setPass] = useState('');

  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [showAlert, setShowAlert] = useState(false);
  const [passAlert, setPassAlert] = useState();
  const [emailAlert, setEmailAlert] = useState();
  const [showEmailAlert, setShowEmailAlert] = useState(false);
  const [replayModal, setReplayModal] = useState(false);
  const [message, setMessage] = useState('');
  const [emailMessage, setEmailMessage] = useState('');
  const [showInfo, setShowInfo] = useState('');
  const [passInfo, setPassInfo] = useState('');
  const { t, i18n} = useTranslation("translation");


  const { user, removeUser } = useUser();
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dataUser = () => {
    const userDataToChange = {
      firstName: name,
      lastName: surname,
      phoneNumber,
      companyName,
      address: address,
      postCode: zipCode,
      city,
      country,
      vatId: nipNumber,
    }

    axios
      .post(
        `https://api.konfigurator.jellydev.pl/api/user/data?lang=${i18n.language}`,
        JSON.stringify(
          userDataToChange
        ),
        )
      .then((response) => {
        setMessage(response.data.message.content);
        setReplayModal(true);
      })
      .catch((err) => {
        console.log(err)
      });
  }
  useEffect(() => {
    if(!user) {
      navigate("/login")
    }
  }, []);

  const dataPassword = () => {
    const userPasswordToChange = {
      currentPassword: password,
      password: newPassword,
      confirmPassword: confirmPassword,
    }
      setShowInfo(false);
      setShowAlert(false);
      axios
        .post(
          `https://api.konfigurator.jellydev.pl/api/user/change-password?lang=${i18n.language}`,
          JSON.stringify(
            userPasswordToChange
          ),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
          )
        .then((response) => {
          if(response.data) {
            setMessage(response.data.message.content);
            setReplayModal(true);
            setPassword();
            setNewPassword();
            setConfirmPassword();
          }
          if(response.message) {
            setMessage(response.message);
            setShowInfo(true);
            setPassAlert(response.response.data.message.content);
            setShowAlert(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });

  }

  const dataEmail = () => {
    const body = {
      oldUsername: email,
      password: pass,
      newUsername: newEmail,
    }

    axios
      .post(
        `https://api.konfigurator.jellydev.pl/api/user/change-email?lang=${i18n.language}`, body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
        )
      .then(response => {
        if(response.response) {
          if(response.response.status === 400) {
            setEmailAlert(response.response.data.message.content);
            setShowEmailAlert(true);
          }
        } else {
          setShowEmailAlert(false);
          setReplayModal(true);
          setEmailMessage(response.data.message.content);
          // setMessage(response.data.message.content);
        }
      })
      .catch(err=> {
        console.log(err);
      });
  }

  const currentData = () => {
    axios
      .get(`https://api.konfigurator.jellydev.pl/api/user/data?lang=${i18n.language}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(response => {
        setAddress(response.data.userDetails.address);
        setCity(response.data.userDetails.city);
        setCompanyName(response.data.userDetails.companyName);
        setCountry(response.data.userDetails.country);
        setName(response.data.userDetails.firstName);
        setNipNumber(response.data.userDetails.vatId);
        setSurname(response.data.userDetails.lastName)
        setPhoneNumber(response.data.userDetails.phoneNumber);
        setZipCode(response.data.userDetails.postCode);
      })
      .catch(error => {
        console.log(error);
      })
  }

  const getPasswordRequirements = () => {
    axios
      .get(`https://api.konfigurator.jellydev.pl/api/user/password-requirements?lang=${i18n.language}`, {
        headers: {
          "Content-Type": "application/json",
        },
      }
      )
      .then(response => {
        setPassInfo(response.data.message.content)
      })
      .catch(err => {
        console.log(err);
      })
  }

  useEffect(() => {
    (async () => {
      currentData();
      getPasswordRequirements();
    })();
  }, []);

  const removeAlerts = () => {
    setReplayModal(false);
    setShowInfo(false);
  }

  const logOut = () => {
    removeUser();
    navigate('/login');
  }

  return (
    <>
      <Container className="main-container px-0 forms">
        <div className="h1-wrapper">
          <h1>{t("translation:data:header")}</h1>
          <img src="img/white-shape.svg" alt="" />
        </div>
        <Form onSubmit={handleSubmit(dataUser)}>
          <p>{t("translation:data:user_data")}</p>
          <Row>
            <Form.Group controlId="userName" className=" col-3">
              <Form.Control type="text" placeholder={t("translation:data:name")} value={name ? name : ""}
                onChange={(changeEvent) => setName(changeEvent.target.value)} required
              />
            </Form.Group>
            <Form.Group controlId="userSurname" className=" col-3">
              <Form.Control type="text" placeholder={t("translation:data:surname")} value={surname ? surname : ""}
                onChange={(changeEvent) => setSurname(changeEvent.target.value)} required
              />
            </Form.Group>
            <Form.Group controlId="userTelephone" className=" col-3">
              <Form.Control type="text" placeholder={t("translation:data:telephone")} value={phoneNumber ? phoneNumber : ""} onChange={(changeEvent) => setPhoneNumber(changeEvent.target.value)} required
              />
            </Form.Group>
          </Row>
          <p>{t("translation:data:company_data")}</p>
          <Row>
            <Form.Group controlId="companyName" className=" col-3">
              <Form.Control type="text" placeholder={t("translation:data:company_name")} value={companyName ? companyName : ""} onChange={(changeEvent) => setCompanyName(changeEvent.target.value)} required/>
            </Form.Group>
            <Form.Group controlId="companyStreet" className=" col-3">
              <Form.Control type="text" placeholder={t("translation:data:street")} value={address ? address : ""} onChange={(changeEvent) => setAddress(changeEvent.target.value)} required/>
            </Form.Group>
            <Form.Group controlId="companyZipCode" className=" col-3">
              <Form.Control type="text" placeholder={t("translation:data:zip_code")} value={zipCode ? zipCode : ""} onChange={(changeEvent) => setZipCode(changeEvent.target.value)} required/>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group controlId="companyCity" className=" col-3">
              <Form.Control type="text" placeholder={t("translation:data:city")} value={city ? city : ""}
              onChange={(changeEvent) => setCity(changeEvent.target.value)} required />
            </Form.Group>
            <Form.Group controlId="companyCountry" className=" col-3">
              <Form.Control type="text" placeholder={t("translation:data:country")} value={country ? country : ""} onChange={(changeEvent) => setCountry(changeEvent.target.value)} required/>
            </Form.Group>
            <Form.Group controlId="companyNip" className=" col-3">
              <Form.Control type="text" placeholder={t("translation:data:vat_id")} value={nipNumber ? nipNumber : ""} onChange={(changeEvent) => setNipNumber(changeEvent.target.value)} required/>
            </Form.Group>
          </Row>
          <Row>
            <div className="col-3">
              <Button type="submit" className="btn-black">{t("translation:data:save_data")}</Button>
            </div>
          </Row>
        </Form>
        <Row>
            <Form onSubmit={handleSubmit(dataPassword)}className="col-6">
              <p>{t("translation:data:password_change")}</p>
              {showAlert && (
                <Alert variant="danger">{passAlert}</Alert>
              )}
              {showInfo && (
                <Alert variant="info">{passInfo}</Alert>
              )}
              <Row>
                <Form.Group controlId="oldPassword" className=" col-6">
                  <Form.Control type="password" placeholder={t("translation:data:current_password")}
                    onChange={(changeEvent) => setPassword(changeEvent.target.value)} required
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group controlId="newPassword" className=" col-6">
                  <Form.Control type="password" placeholder={t("translation:data:new_password")}
                    onChange={(changeEvent) => setNewPassword(changeEvent.target.value)} required
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group controlId="againNewPassword" className=" col-6">
                  <Form.Control type="password" placeholder={t("translation:data:confirm_password")}
                    onChange={(changeEvent) => setConfirmPassword(changeEvent.target.value)} required
                  />
                </Form.Group>
              </Row>
              <Row>
                <div className="col-6">
                  <Button type="submit" className="btn-black">{t("translation:data:save_data")}</Button>
                </div>
              </Row>
            </Form>
            <Form onSubmit={handleSubmit(dataEmail)} className="col-6">
              <p>{t("translation:data:change_email")}</p>
              {showEmailAlert && (
                <Alert variant="danger">{emailAlert}</Alert>
              )}
              <Row>
                <Form.Group controlId="oldEmail" className=" col-6">
                  <Form.Control type="email" placeholder={t("translation:data:current_email")} onChange={(changeEvent) => setEmail(changeEvent.target.value)} required
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group controlId="newEmail" className=" col-6">
                      <Form.Control type="email" placeholder={t("translation:data:new_email")}
                        onChange={(changeEvent) => setNewEmail(changeEvent.target.value)}
                        required
                      />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group controlId="emailPassword" className=" col-6">
                  <Form.Control type="password" placeholder={t("translation:data:password")}
                    onChange={(changeEvent) => setPass(changeEvent.target.value)} 
                    required
                  />
                </Form.Group>
              </Row>
              <Row>
                <div className="col-6">
                  <Button type="submit" className="btn-black">{t("translation:data:save_data")}</Button>
                </div>
              </Row>
            </Form>
        </Row>
      </Container>
      <Modal show={replayModal} className="delete-modal list-modal">
        <Modal.Header className="p-0 pb-3">{emailMessage ? emailMessage : message}</Modal.Header>
        <Button className="w-100" onClick={() => {emailMessage ? logOut() : setReplayModal(false)}}>ok</Button>
      </Modal>
    </>
  );
};
export default Data;
