import React, { useEffect, useState } from "react";
import Konva from "konva";
import { Line, Text } from "react-konva";
import "konva/lib/shapes/Rect";

const Rulers = ({ box, project, scale }) => {
  return (
    <>
      <Line
        points={[0, box.height + 39, box.width, box.height + 39]}
        stroke="#BABABA"
        strokeWidth={1}
        tension={1}
      />
      {/* small lines */}
      <Line
        points={[0, box.height + 25, 0, box.height + 39 + 14]}
        stroke="#BABABA"
        strokeWidth={1}
        tension={1}
      />
      <Line
        points={[box.width, box.height + 25, box.width, box.height + 39 + 14]}
        stroke="#BABABA"
        strokeWidth={1}
        tension={1}
      />
      {/* end small lines */}
      <Text
        x={0}
        y={box.height + 55}
        width={box.width}
        height={39}
        align="center"
        text={project.width/10 + ` cm`}
        fontSize={14 / scale}
        fill="#bababa"
        fontFamily="Roboto"
      />

      <Line
        points={[box.width + 39, 0, box.width + 39, box.height]}
        stroke="#BABABA"
        strokeWidth={1}
        tension={1}
      />
      {/* small lines */}
      <Line
        points={[box.width + 25, 0, box.width + 39 + 14, 0]}
        stroke="#BABABA"
        strokeWidth={1}
        tension={1}
      />
      <Line
        points={[box.width + 25, box.height, box.width + 39 + 14, box.height]}
        stroke="#BABABA"
        strokeWidth={1}
        tension={1}
      />
      {/* end small lines */}
      <Text
        x={box.width + 55}
        y={box.height}
        width={box.height}
        height={39}
        align="center"
        text={project.length/10 + ` cm`}
        fontSize={14 / scale}
        fill="#bababa"
        fontFamily="Roboto"
        rotation={270}
      />
    </>
  );
};

export default Rulers;
