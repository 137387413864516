import React from "react";
import { Page, Text, Image, Document, View } from "@react-pdf/renderer";
import profileCeilingTypes from "../store/profileCeilingTypes";
import styles from "./pdfStyle";
import element from "../components/canvas/element";
import {useTranslation} from "react-i18next";

const Template = (props) => {
  let konva;
  if(document.querySelector(".konvajs-content")) {
    konva = document.querySelector(".konvajs-content").childNodes[0];
  }
  const { t, i18n} = useTranslation("translation");
  let dataURL;
  if (konva) {
    dataURL = konva.toDataURL();
  }

  const renderAccesories = (accessory) => (
    <View key={accessory.id} style={styles.container}>
      <View wrap={false}  style={styles.profile}>
        <View style={styles.image}>
          <Image style={styles.image} src={accessory.thumbnail.url} />
        </View>
        <View style={styles.profileText}>
          <Text style={styles.profileTitle}>{accessory.label}</Text>
          <Text style={styles.profileDescribe}>{t("translation:pdfs:chosen_count")}: <Text style={styles.profileTextBolder}>{accessory.count}</Text></Text>
        </View>
      </View>
    </View>
  );

  const renderElements = (elem) => {
    return (
      <View wrap={false} key={elem.id} style={styles.profile}>
        <Image style={styles.image} src={elem.product.thumbnail.url} />
        <View style={styles.profileText}>
          <Text style={styles.profileTitle}>{elem.product.label}</Text>
        </View>
      </View>
    );
  };

  return (
    <Document wrap>
      <Page size="A4" style={styles.body}>
          {/*<Text style={styles.header}>{props.project.width /10 }cm  x  {props.project.length /10}cm </Text>*/}
        <Text style={styles.header}>{props.project.name}</Text>
        <Text style={styles.projectDescribe}>{t("translation:pdfs:project_designer")}: {props.project.designer}</Text>
        <Text style={styles.projectDescribe}>{t("translation:pdfs:ceiling_width")}: {props.project.width /10} cm</Text>
        <Text style={styles.projectDescribe}>{t("translation:pdfs:ceiling_length")}: {props.project.length / 10} cm</Text>
        <View style={styles.container}>
          <Image style={styles.canvaImage} src={dataURL} />
        </View>
      </Page>
      <Page size="A4" wrap style={styles.body}>
        <View style={styles.container}>
          {props.systems.map((system, id) => {
            return (
              <View key={id}>
                <View style={styles.container}>
                  <Text style={styles.title} key={id}>
                    {t("translation:pdfs:system")} {id + 1}
                  </Text>
                  {system.profiles && system.profiles.length > 0 ? (
                    <View style={styles.container}>
                      {system.profiles.map((profile, id) => {
                        return (
                          <View key={profile.id} style={styles.container}>
                            <View wrap={false} style={styles.profile}>
                              {/*//ceiling.colors.value === profile.product.selectedProperties.finishing.label*/}
                              {profileCeilingTypes
                                .filter((ceiling) => ceiling.type === profile.type )
                                .map((type) => (
                                  type.colors.map((color) => {
                                    if(color.labelEn === profile.product.selectedProperties.finishing.label
                                      || color.labelPl === profile.product.selectedProperties.finishing.label) {
                                      return(
                                        <Image key={profile.id} style={styles.image} src={`img/` + color.image} />
                                      )
                                    }
                                  })
                                ))}
                              <View style={styles.profileText}>
                                <Text style={styles.profileTitle}> {profile.product.label}</Text>
                              </View>
                            </View>
                            {profile.elements
                              .filter((elem) => elem.product)
                              .map((element) => {
                                return (
                                  <View key={element.id} style={styles.container}>
                                    <View wrap={false} style={styles.profile}>
                                      {/* {<Image style={styles.image} crossOrigin="Anonymous" src={createImage(element.product.thumbnail.url)}/>} */}
                                      <Image style={styles.image} src={element.product.thumbnail.url} />
                                      <View style={styles.profileText}>
                                        <Text style={styles.profileTitle}>{element.product.label}</Text>
                                      </View>
                                    </View>
                                    {element.product.elements.filter((elem) => elem.product).map((elem) => renderElements(elem))}
                                  </View>
                                );
                              })}
                          </View>
                        );
                      })}
                    </View>
                  ) : (
                    <></>
                  )}
                  {system.accessories.connectors && system.accessories.connectors.length > 0 ? (
                    <View style={styles.container}>
                      <Text style={styles.accessoryTitle}>
                        {t("translation:pdfs:connectors")}
                      </Text>
                      {system.accessories.connectors.map((connector, id) => renderAccesories(connector))}
                    </View>
                  ) : (<></>)}
                  {system.accessories.pendants && system.accessories.pendants.length > 0 ? (
                    <View style={styles.container}>
                      <Text style={styles.accessoryTitle}>
                        {t("translation:pdfs:pendants")}
                      </Text>
                      {system.accessories.pendants.map((pendant, id) => renderAccesories(pendant))}
                    </View>
                  ) : (<></>)}
                  {system.accessories.plugs && system.accessories.plugs.length > 0 ? (
                    <View style={styles.container}>
                      <Text style={styles.accessoryTitle}>
                        {t("translation:pdfs:plugs")}
                      </Text>
                      {system.accessories.plugs.map((plugs, id) => renderAccesories(plugs))}
                    </View>
                  ) : (<></>)}
                </View>
              </View>
            );
          })}

          {props.project.comment && props.project.comment.length > 0 ? (
            <>
              <Text style={styles.title} break>{t("translation:pdfs:comment")}</Text>
              <Text style={styles.comment}>
                {props.project.comment}
              </Text>
            </>
          ) : (
            <></>
          )}
        </View>
      </Page>
    </Document>
  );
};

export default Template;
