import React, { useEffect, useState } from "react";
import { Container, Table, Button } from "react-bootstrap";
import Comment from "../components/comment";
import Delete from "../components/delete";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useProject } from "./../store/projectProvider";
import useUser from "../components/useUser";
import useCurrentProjectId from "../components/useCurrentProjectId";
import useCurrentProjectCode from "../components/useCurrentProjectCode";
import i18n from "i18next";
import {useTranslation} from "react-i18next";

const Page = () => {
  const [projects, setProjects] = useState();
  const { setProjectEditId } = useCurrentProjectId();
  const { user, removeUser } = useUser();
  const navigate = useNavigate();
  const { saveProject, project, dispatch } = useProject();
  const [projectToEdit, setProjectToEdit] = useState();
  const [comment, setComment] = useState();
  const { setProjectCode } = useCurrentProjectCode();
  const { t, i18n} = useTranslation("translation");

  const loadProjects = () => {
    axios
      .get(`https://api.konfigurator.jellydev.pl/api/projects?lang=${i18n.language}`)
      .then((response) => {
        setProjects(response.data);
        if(!user) {
          if(response.response.status === 401) {
            navigate("/");
          }
        } else {
          if(response.data.length === 0) {
            navigate("/");
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const editProject = (id) => {
    const body = {
      id: id,
    }
    axios
      .get(`https://api.konfigurator.jellydev.pl/api/project/${id}?lang=${i18n.language}`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      }
      )
      .then((response) => {
        setProjectToEdit(response.data.project.content);
        setProjectEditId(response.data.project.id);
        setProjectCode(response.data.project.code);
      })
      .catch((err) => {
        console.log(err);
      });
  
  }

  useEffect(() => {
    if(projectToEdit) {
      (async () => { 
        dispatch({
          type: "UPDATE",
          project: {
            ...projectToEdit,
          }
        });
        if(projectToEdit) {  
          navigate("/chooseType");
        }
      })();
    }
  }, [projectToEdit]);

  useEffect(() => {
    (async () => {
      loadProjects();
    })();
  }, [i18n.language]);

  return (
    <>
      <Container fluid="md" className="main-container list-container px-0">
        <h1>{t("translation:project_list:header")}</h1>
        <Table>
          <img src="img/white-shape.svg" alt=" " />
          <img src="img/white-shape.svg" alt=" " />
          <thead>
            <tr>
              <th>{t("translation:project_list:project_name")}</th>
              <th>{t("translation:project_list:project_code")}</th>
              <th>{t("translation:project_list:create_date")}</th>
              <th>{t("translation:project_list:designer")}</th>
              <th>{t("translation:project_list:action")}</th>
            </tr>
          </thead>
          <tbody>
            {projects && projects.map(obj => {
                  return (
                    <tr key={obj.project.id}>
                      <td>
                        {/* <p>
                          <Link to="/chooseType" onClick={() => editProject(obj.project.id)}>{obj.project.content.name}</Link>
                        </p> */}
                        <p className="list-icon" onClick={() => editProject(obj.project.id)}>
                          {obj.project.content.name}
                        </p>
                      </td>
                      <td>
                        <p>
                          {obj.project.code}
                        </p>
                      </td>
                      <td>
                        <p>
                          {obj.project.createdAt}
                        </p>
                      </td>
                      <td>
                        <p>
                          {obj.project.content.designer}
                        </p>
                      </td>
                      <td>
                        <Comment project={obj.project}/>
                        <Button className="list-icon" onClick={() => editProject(obj.project.id)}>
                          <img src="img/edit.svg" alt="" />
                        </Button>
                        <Delete
                          project={obj.project}
                          reload={loadProjects}
                          header={t("translation:project_list:delete_project")}/>
                      </td>
                    </tr>
                  );
                }) 
            }
          </tbody>
        </Table>
      </Container>
    </>
  );
};
export default Page;
