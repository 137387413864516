import React, { useState, useEffect } from "react";
import { Container, Form, Alert } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import useUser from "../components/useUser";
import axios from "axios";
import ForgotPassword from "../components/resetPassword";
import useProjectLanguage  from "../components/useProjectLanguage";
import '../i18n';
import i18n from "i18next";
import { useTranslation } from "react-i18next";


const Login = () => {
  const { user, removeUser, setUser } = useUser();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const {projectLanguage, setProjectLanguage} = useProjectLanguage();
  const [t] = useTranslation("translation");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  // const [showPassword, setShowPassword] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate()

  const login = () => {
    let params = {
        username,
        password,
    };
    axios.defaults.headers.common['Authorization'] = '';
    if(params.username) {
      axios
        .post(`https://api.konfigurator.jellydev.pl/api/user/login?lang=${i18n.language}`, params, {
            headers: {
              "Content-Type": "application/json",
            },
          }
          )
        .then((response) => {
          const user = {
            token: response.data.token,
            id: response.data.user_id,
          };
          setUser(user);
          navigate("/list")
        })
        .catch((err) => {
          console.log(err);
          setShowAlert(true)
        });
    }
  }

  const changeLanguage = () => {
    if(i18n.language === "en") {
      setProjectLanguage('pl');
      i18n.changeLanguage('pl');
    } else {
      setProjectLanguage('en');
      i18n.changeLanguage('en');
    }
  }

  const setLanguageAfterRefresh = () => {
    if(projectLanguage === "en") {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage("pl");
    }
  }

  useEffect(() => {
    if(user) {
      navigate("/list")
    }
    if(projectLanguage) {
      setLanguageAfterRefresh();
    }
    if(i18n.language && !projectLanguage) {
      setProjectLanguage(i18n.language);
    }
  }, []);


  return (
    <>
      <Container fluid className="login">
        <video autoPlay muted loop playsInline>
          <source src="/img/zaho-wide.mp4" type="video/mp4" />
        </video>
        <div className="login-wrapper">
          <div className="login-nav">
            <img className="login-logo" src="/img/zaho_logo.png" alt="" />
            <div>
              {" "}
              <img className="pe-2" src="img/web.svg" alt="" />
              {i18n.language ? (
                <span className="language-change" onClick={() => changeLanguage()}>{i18n.language}</span>
              ) : (<span className="language-change" onClick={() => changeLanguage()}>pl</span>)}
            </div>
          </div>
          <div className="login-header">
            <h2>{t("translation:login:login_header")}</h2>
          </div>
          {showAlert && (
            <Alert variant="danger">{t("translation:login:date_fault")}</Alert>
          )}
          <Form className="login-inputs" onSubmit={handleSubmit(login)}>
            <Form.Group className="mb-3 login-mail" controlId="formBasicEmail">
              <Form.Control type="email" placeholder={t("translation:login:login_placeholder")} {...register("login")} onChange={(changeEvent) => setUsername(changeEvent.target.value)} required/>
            </Form.Group>
            <Form.Group
              className="mb-3 login-password"
              controlId="formBasicPassword"
            >
              <Form.Control type="password" placeholder={t("translation:login:password_placeholder")} {...register("password")} onChange={(changeEvent) => setPassword(changeEvent.target.value)} required/>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="formBasicCheckbox"
              ></Form.Group>
            <ForgotPassword/>
            <div className="login-buttons">
              <button className="btn btn-black mb-3" type="submit">
                {t("translation:login:login_btn")}
              </button>
              <Link to="/create-project" className="btn btn-white" role="button">
                {t("translation:login:without_login_btn")}
              </Link>
            </div>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default Login;
