import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import ConfiguratorNav from "../components/configuratorNav";
import ProfileObject from "../components/profile-object-simple";
import { useParams, useOutletContext } from "react-router-dom";
import { useProject } from "./../store/projectProvider";
import CustomScroll from "react-custom-scroll";
import {useTranslation} from "react-i18next";
import {type} from "@testing-library/user-event/dist/type";

const Profiles = () => {
  let { type: surfaceType, apiId } = useParams();
  const { getProfileTypeDetails } = useProject();
  const [profileTypeDetails, setProfileTypeDetails] = useState();
  const [setNewProfile] = useOutletContext();
  const { t, i18n} = useTranslation("translation");

  useEffect(() => {
    setProfileTypeDetails(getProfileTypeDetails(surfaceType, ));
  }, []);

  const addProfile = (profile, type) => {
    setNewProfile({ ...profile, surfaceType: surfaceType, apiId });
  };

  return (
    <>
      <ConfiguratorNav />
      <CustomScroll>
        <div className="configurator-wrapper">
          <div className="configurator-title">
            <h1>{t("translation:profiles:length")}</h1>
            <p>
              {t("translation:profiles:profile_drag")}
            </p>
          </div>
          <Row className="pt-4 mt-2">
            {profileTypeDetails &&
              profileTypeDetails.profiles.map((profile, i) => (
                <Col xs={12} className="map-item" key={i}>
                  <div>
                    <p className="item-length">
                      {profile.value} {surfaceType === "surface" || surfaceType === "recessed" ? "(+280)" : ""} mm
                    </p>
                    <div
                      style={{ display: "inline-block", cursor: "pointer" }}
                      onMouseDown={() => {}}
                      onDragEnd={(e) => addProfile(profile)}
                      draggable
                    >
                      <ProfileObject
                        cells={profile.value / 280}
                        connectors={surfaceType === "surface" || surfaceType === "recessed"}
                      />
                    </div>
                  </div>
                </Col>
              ))}
          </Row>
        </div>
      </CustomScroll>
    </>
  );
};

export default Profiles;
