import React, { useState, useEffect } from "react";
import { Container, Form, Alert, Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useLocation, Navigate} from "react-router-dom";
import useUser from "../components/useUser";
import axios from "axios";
import i18n from "i18next";
import {useTranslation} from "react-i18next";
import useProjectLanguage from "../components/useProjectLanguage";

const Reset = () => {
  const [passwordError, setPasswordError] = useState(false);
  const [showResetAlert, setShowResetAlert] = useState(false);
  const navigate = useNavigate();
  const [replayModal, setReplayModal] = useState(false);
  const [message, setMessage] = useState('');
  const [showInfo, setShowInfo] = useState('');
  const [passInfo, setPassInfo] = useState('');
  const {projectLanguage, setProjectLanguage} = useProjectLanguage();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const { t, i18n} = useTranslation("translation");

  const { user, removeUser } = useUser();
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  const SetPassword = () => {
    const body = {
      token,
      password,
      confirmPassword,
    }

    axios
      .post(`https://api.konfigurator.jellydev.pl/api/user/set-password?lang=${i18n.language}`,
      JSON.stringify(
        body
      ),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
      )
      .then(response => {
        if(response.response) {
          if(response.response.status === 400) {
            setShowResetAlert(true);
            setPasswordError(response.response.data.message.content);
            setShowInfo(true);
          }
        } else {
          setReplayModal(true);
          if(response.data) {
            setMessage(response.data.message.content);
          }
        }
      })
      .catch(err => {
        console.log(err);
      })
  }

  const getPasswordRequirements = () => {
    axios
      .get(`https://api.konfigurator.jellydev.pl/api/user/password-requirements?lang=${i18n.language}`, {
        headers: {
          "Content-Type": "application/json",
        },
      }
      )
      .then(response => {
        setPassInfo(response.data.message.content)
      })
      .catch(err => {
        console.log(err);
      })
  }

  useEffect(() => {
    (async () => {
      getPasswordRequirements();
    })();
  }, []);

  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }


  let query = useQuery();
  const [token, setToken] = useState(query.get("token"));

  const changeLanguage = () => {
    if(i18n.language === "en") {
      setProjectLanguage('pl');
      i18n.changeLanguage('pl');
    } else {
      setProjectLanguage('en');
      i18n.changeLanguage('en');
    }
  }

  const setLanguageAfterRefresh = () => {
    if(projectLanguage === "en") {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage("pl");
    }
  }

  useEffect(() => {
    if(projectLanguage) {
      setLanguageAfterRefresh();
    }
    if(i18n.language && !projectLanguage) {
      setProjectLanguage(i18n.language);
    }
  }, []);

  return (
    <>
      <Container fluid className="login">
        <video autoPlay muted loop playsInline>
          <source src="/img/zaho-wide.mp4" type="video/mp4" />
        </video>
        <div className="login-wrapper">
          <div className="login-nav">
            {" "}
            <img className="login-logo" src="/img/zaho_logo.png" alt="" />
            <div>
              {" "}
              {i18n.language ? (
                <span className="language-change" onClick={() => changeLanguage()}>{i18n.language}</span>
              ) : (<span className="language-change" onClick={() => changeLanguage()}>pl</span>)}
            </div>
          </div>
          <div className="login-header">
            <h2>{t("translation:data:password_reset")}</h2>
          </div>
          {showResetAlert && (
            <Alert variant="danger">{passwordError}</Alert>
          )}
          {showInfo && (
            <Alert variant="info">{passInfo}</Alert>
          )}
          <Form className="reset-inputs" onSubmit={handleSubmit(SetPassword)}>
            <Form.Group
              className="mb-3 login-password"
              controlId="formBasicPassword"
            >
              <Form.Control type="password" placeholder={t("translation:data:password")}
              onChange={(changeEvent) => setPassword(changeEvent.target.value)} required/>
            </Form.Group>
            <Form.Group
              className="mb-3 login-password"
              controlId="formBasicConfirmPassword"
            >
              <Form.Control type="password" placeholder={t("translation:data:confirm_password")} onChange={(changeEvent) => setConfirmPassword(changeEvent.target.value)} required/>
            </Form.Group>
            <div className="login-buttons">
              <button className="btn btn-black mb-3" type="submit">
                {t("translation:data:set_password")}
              </button>
            </div>
          </Form>
        </div>
      </Container>
      <Modal show={replayModal} className="delete-modal list-modal">
        <Modal.Header className="p-0 pb-3">{message}</Modal.Header>
        <Button className="w-100" onClick={() => (setReplayModal(false), navigate('/login'))
        }>ok</Button>
      </Modal>
    </>
  );
};

export default Reset;