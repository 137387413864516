import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import CustomScroll from "react-custom-scroll";
import ConfiguratorNav from "../components/configuratorNav";
import ProfileObject from "../components/profile-object";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useProject } from "../store/projectProvider";
import axios from "axios";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Loader from "../components/loader";
import i18n from "i18next";
import {useTranslation} from "react-i18next";

const ProductProjector = () => {
  const navigate = useNavigate();
  let { profileId, elementId, productId, trackElementId } = useParams();

  const { getProfileById, updateElementsInProfile, project, getElementById, getTrackElementById, getProductCode } = useProject();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    unregister,
    formState: { errors },
  } = useForm();
  const watchAllFields = watch();
  const [profile, setProfile] = useState({});
  const [product, setProduct] = useState();
  const [element, setElement] = useState();
  const [trackElement, setTrackElement] = useState();
  const [editMode, setEditMode] = useState(false);
  const [productCode, setProductCode] = useState();
  const { t, i18n} = useTranslation("translation");

  useEffect(() => {}, [watchAllFields]);

  useEffect(() => {
    //set init value
    if (trackElement?.product && editMode) {
      Object.keys(trackElement.product.selectedProperties).map((prop) => {
        setValue(prop, trackElement.product.selectedProperties[prop].id);
      });
    }
  }, [product]);

  useEffect(() => {
    (async () => {
      const profileTmp = await getProfileById(profileId);
      const elementTmp = await getElementById(profileTmp, elementId);
      const trackElementTmp = await getTrackElementById(profileTmp, elementId, trackElementId);

      setProfile(profileTmp);
      setElement(elementTmp);
      setTrackElement(trackElementTmp);

      const getProductId = trackElementTmp.product === null ? productId : trackElementTmp.product.id;

      setEditMode(productId === undefined);

      axios.get("https://api.konfigurator.jellydev.pl/api/product/" + getProductId + `?lang=${project.language}`).then((response) => {
        Object.keys(watchAllFields).map((prop) => {
          unregister(prop);
        });
        setProduct(response.data);
      });
    })();
  }, [trackElementId, i18n.language]);

  useEffect(() => {
    //set product code
    (async () => {
      const productTmp = await prepareProductToUpdate(watchAllFields);
      const productCode = await getProductCode(productTmp);
      setProductCode(productCode);
    })();
  }, [watchAllFields]);

  const addToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast(t("translation:profile:product_code") + " " + productCode + t("translation:profile:added_product"), {
      type: toast.TYPE.INFO,
    });
  };

  const prepareProductToUpdate = (data) => {
    Object.keys(data).map((dataElement) => {
      data[dataElement] = getProductPropertiesOption(data, dataElement);
    });

    return { ...product, selectedProperties: data };
  };

  const getProductPropertiesOption = (data, properties) => {
    const prop = product.properties.filter((prop) => prop.name === properties)[0];
    return prop.options.filter((o) => o.id === parseInt(data[properties]))[0];
  };

  const remove = () => {
    let trackElementsToUpdate = element.product.elements.map((e) => (e.id === trackElement.id ? { ...e, product: null } : e));
    let elementsToUpdate = profile.elements.map((e) =>
      e.id === element.id ? { ...e, product: { ...e.product, elements: trackElementsToUpdate } } : e
    );

    updateElementsInProfile(profile, elementsToUpdate);
    navigate("/product/" + profile.id + "/" + element.id);
  };

  const onSubmit = (data) => {
    //prepare new selectedData
    const productToUpdate = prepareProductToUpdate(data);
    const trackElementsToUpdate = element.product.elements.map((e) =>
      e.id === trackElement.id ? { ...e, product: productToUpdate } : e
    );
    const elementsToUpdate = profile.elements.map((e) =>
      e.id === element.id ? { ...e, product: { ...e.product, elements: trackElementsToUpdate } } : e
    );
    updateElementsInProfile(profile, elementsToUpdate);

    navigate("/product/" + profile.id + "/" + element.id);
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ConfiguratorNav />
        <CustomScroll>
          {product ? (
            <div className="configurator-wrapper lower">
              <div className="configurator-title">
                <h1>
                  {t("translation:profile:config")}
                </h1>
                <p>{t("translation:profile:edit")}</p>
                <div>
                  {profile && (
                    <ProfileObject profile={profile} selectedElementId={elementId} selectedTrackElementId={trackElementId} />
                  )}
                </div>
              </div>

              <div className="item-configurator py-3">
                <p>{product?.label}</p>
                <Row>
                  <Col xs={6} className="profile-setup">
                    {product?.properties.map((prop) => {
                      return (
                        <div className="configurator-select" key={prop.name}>
                          <p>{prop.label}:</p>
                          <Form.Group controlId={prop.name}>
                            <Form.Select aria-label={prop.label} {...register(prop.name)}>
                              {prop.options.map((option) => {
                                return (
                                  <option value={option.id} key={option.id}>
                                    {option.label}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Form.Group>
                        </div>
                      );
                    })}

                    {element && (
                      <div className="configurator-select">
                        <p>{t("translation:profile:product_code")}:</p>
                        <div className="clipboard" onClick={() => addToClipboard(productCode)}>
                          {productCode}
                          <img src="/img/content_copy.svg" alt="" />
                        </div>
                      </div>
                    )}
                  </Col>
                  <Col xs={6} className="item-image">
                    <div>
                      <img className="img-fluid" src={product?.thumbnail.url} alt="" />
                    </div>
                    {editMode && (
                      <Button variant="danger" className="remove-btn-mq d-none" onClick={() => remove()}>
                        {t("translation:profile:product:delete")}
                      </Button>
                    )}
                  </Col>
                </Row>
              </div>
              <Row>
                <Col>
                  {editMode && (
                    // <Button variant="danger" className={`mt-5`} onClick={() => remove()}>
                    //   {t("translation:modules:delete")}
                    // </Button>
                    <Button variant="danger" className={`mt-5 remove-btn`} onClick={() => remove()}>
                      {t("translation:profile:product:delete")}
                    </Button>
                  )}
                </Col>
              </Row>
            </div>
          ) : (
            <Loader />
          )}
        </CustomScroll>
        <div className="item-accept">
          <Link
            to={
              editMode
                ? "/product/" + profileId + "/" + elementId
                : "/projectors/" + profileId + "/" + elementId + "/" + trackElementId
            }
            className="btn btn-primary"
          >
            {t("translation:profile:dont_save")}
          </Link>
          <button className="btn btn-primary" type="submit">
            {t("translation:profile:save_data")}
          </button>
        </div>
      </Form>
    </>
  );
};

export default ProductProjector;
