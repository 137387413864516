let profileTemplate = {
  x: 0,
  y: 0,
  type: "",
  color: "",
  code: "",
  length: "",
  orientation: "horizontal",
  connectorStart: [],
  connectorEnd: [],
  product: [],
  elements: [],
};

export default profileTemplate;
