import React from "react";
import { useNavigate } from "react-router-dom";

const ProfileObject = ({ profile, selectedElementId, selectedTrackElementId }) => {
  const navigate = useNavigate();

  let cells = [];

  const elementClick = (element) => {
    //check if element is not already loaded
    if (parseInt(selectedElementId) !== element.id) {
      const newModule = element.product === null && element.parentId === null;
      if (newModule) {
        navigate("/modules/" + profile.id + "/" + element.id);
      } else {
        const elementId = element.parentId === null ? element.id : element.parentId;
        navigate("/product/" + profile.id + "/" + elementId);
      }
    }
  };

  const trackElementClick = (element, trackElement) => {
    //check if track is already selected
    if (parseInt(selectedElementId) === element.id) {
      if (trackElement.product === null) {
        navigate("/projectors/" + profile.id + "/" + element.id + "/" + trackElement.id);
      } else {
        navigate("/product-projector/" + profile.id + "/" + element.id + "/" + trackElement.id);
      }
    }
  };

  const renderTrack = (element) => {
    let elementsCounter;
    if(element.product.elements.length >= 18) {
      elementsCounter = Math.floor(parseInt(element.product.elements.length) / 1.55)
    } else {
      elementsCounter = Math.floor(parseInt(element.product.elements.length) / 1.5);
    }
    const length = elementsCounter * 280;
    const elementsWidth = 28 * elementsCounter + 1;
    const trackElementsCounter = Math.floor(parseInt(length) / 170);
    const trackElementWidth = elementsWidth / trackElementsCounter;

    let trackElements = [];

    element.product.elements.map((trackElement, i) => {
        trackElements.push(
          <div
            className={`track-element ${trackElement.product === null ? `` : `highlighted`} ${
              parseInt(selectedTrackElementId) === i + 1 ? `selected` : ``
            }`}
            style={{ width: trackElementWidth }}
            onClick={() => trackElementClick(element, trackElement)}
            key={i}
          ></div>
        );
      });
      return (
        <div className={`track track${length}`} style={{ width: elementsWidth }}>
          {trackElements}
        </div>
      );
  };

  return (
    <div className="profile-object">
      {profile.type === "surface" || profile.type === "recessed" ? <div className="connector" key={"cStart"}></div> : ""}

      {profile.elements?.map((element, i) => {
        return (
          <div
            className={`profile-cell ${element.parentId === null && element.product == null ? `` : `highlighted`} ${
              element.id === parseInt(selectedElementId) || element.parentId === parseInt(selectedElementId) ? `selected` : ``
            }`}
            key={i}
            onClick={() => elementClick(element)}
          >
            {element.product?.elements.length > 0 ? renderTrack(element) : ""}
          </div>
        );
      })}

      {profile.type === "surface" || profile.type === "recessed" ? <div className="connector" key={"cEnd"}></div> : ""}
    </div>
  );
};
export default ProfileObject;
