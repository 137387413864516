import React from 'react';
import { Page, Font, Text, Image, Document, View } from '@react-pdf/renderer';
import profileCeilingTypes from "../store/profileCeilingTypes";
import styles from './pdfStyle';
import useCurrentProjectCode from "../components/useCurrentProjectCode";
import {useTranslation} from "react-i18next";

Font.register({
  family: "roboto",
  fonts: [
    {src: "https://use.typekit.net/af/7f1b26/00000000000000007735a0ac/30/a?primer=66b6f45d0ef17dc090abd1a7c3c0c73ff39663a6235120922f51f9866883c799&fvd=n4&v=3", fontWeight: 400},
    {src: "https://use.typekit.net/af/9d5bd3/00000000000000007735a09f/30/d?primer=66b6f45d0ef17dc090abd1a7c3c0c73ff39663a6235120922f51f9866883c799&fvd=n7&v=3", fontStyle: 'normal', format:"WebOpenFontFormat", fontWeight: "700"},
  ]
});
Font.register({
  family: "termina",
  fonts: [
    {src: "https://use.typekit.net/af/7f1b26/00000000000000007735a0ac/30/a?primer=66b6f45d0ef17dc090abd1a7c3c0c73ff39663a6235120922f51f9866883c799&fvd=n4&v=3", fontWeight: 400},
    {src: "https://use.typekit.net/af/3e72b6/00000000000000007735b21e/30/d?primer=f1a820c30740dc6492ec51f34d8da727435cd9e66a2845f8f7f2c58da176b1fd&fvd=n5&v=3", fontStyle: 'normal', format:"WebOpenFontFormat", fontWeight: "500"},
    {src: "https://use.typekit.net/af/574117/00000000000000007735b222/30/d?primer=f1a820c30740dc6492ec51f34d8da727435cd9e66a2845f8f7f2c58da176b1fd&fvd=n7&v=3", fontStyle: 'normal', format:"WebOpenFontFormat", fontWeight: "700"},
  ]
});

const Template2 = (props) => {
  //PDF DLA DEALERA
  const { getCurrentProjectCode } = useCurrentProjectCode();
  // const konva = document.querySelector('.konvajs-content').childNodes[0];
  // const { t, i18n} = useTranslation("translation");
  // let dataURL;
  // if(konva) {
  //   dataURL = konva.toDataURL();
  // }
  let konva;
  if(document.querySelector(".konvajs-content")) {
    konva = document.querySelector(".konvajs-content").childNodes[0];
  }
  const { t, i18n} = useTranslation("translation");
  let dataURL;
  if (konva) {
    dataURL = konva.toDataURL();
  }


  const renderAccesories = (accessory) => (
    <View key={accessory.id} style={styles.container}>
      <View wrap={false}  style={styles.profile}>
        <View style={styles.image}>
          <Image style={styles.image} src={accessory.thumbnail.url} />
        </View>
        <View style={styles.profileText}>
          <Text style={styles.profileTitle}>{accessory.label}</Text>
          <Text style={styles.profileDescribe}>{t("translation:pdfs:chosen_count")}: <Text style={styles.profileTextBolder}>{accessory.count}</Text></Text>
          <Text style={styles.profileDescribe}>{t("translation:profile:product_code")}: <Text style={styles.profileTextBolder}> {accessory.code}
          </Text>
          </Text>
        </View>
      </View>
    </View>
  );

  const renderElements = (elem) => {
    return (
      <View wrap={false} key={elem.id} style={styles.profile}>
        <Image style={styles.image} src={elem.product.thumbnail.url} />
        <View style={styles.profileText}>
          <Text style={styles.profileTitle}>{elem.product.label}</Text>
          {elem.product.properties.map((prop) => {
            return (
              <Text key={prop.id} style={styles.profileDescribe}>
                {prop.label + ": "}
                <Text style={styles.profileTextBolder}>
                  {elem.product.selectedProperties[prop.name].label}
                </Text>
              </Text>
            );
          })}
          <Text style={styles.profileDescribe}>
            {t("translation:profile:product_code")}:<Text style={styles.profileTextBolder}> {getProductCode(elem.product)}
          </Text>
          </Text>
        </View>
      </View>
    );
  };

  const getProductCode = (product) => {
    let code = product.code;
    // Object.keys(product.selectedProperties).map((prop) => {
    //   const propObj = product.selectedProperties[prop];
    //   code += propObj.isDot ? "." : "";
    //   code += propObj.code;
    // });
    const productOrder = {
      "length" : null,
      "electronics": null,
      "temperature": null
    }

    const sortedObject = Object.assign(productOrder, product.selectedProperties);
    Object.keys(sortedObject).map((prop) => {
      const propObj = product.selectedProperties[prop];
      if (propObj) {
        code += propObj.isDot ? "." : "";
        code += propObj.code;
      }
    });
    return code;
  };

  const getProfileCode = (profile) => {
    let code = profile.length.code;
    if (profile.product.selectedProperties) {
      Object.keys(profile.product.selectedProperties).map((prop) => {
        const propObj = profile.product.selectedProperties[prop];
        if (propObj) {
          code += propObj.isDot ? "." : "";
          code += propObj.code;
        }
      });
    }

    return code;
  };

  return (
    <Document wrap>
      <Page size="A4" style={styles.body}>
        <Text style={styles.header}>{props.project.name}</Text>
        <Text style={styles.projectDescribe}>{t("translation:pdfs:project_designer")}: {props.project.designer}</Text>
        <Text style={styles.projectDescribe}>{t("translation:pdfs:ceiling_width")}: {props.project.width / 10} cm</Text>
        <Text style={styles.projectDescribe}>{t("translation:pdfs:ceiling_length")}: {props.project.length / 10} cm</Text>
        <Text style={styles.projectDescribe}>{t("translation:pdfs:project_code")}: {getCurrentProjectCode()}</Text>
        <View style={styles.container}>
          <Image style={styles.canvaImage} src={dataURL}/>
        </View>
      </Page>
      <Page size="A4" wrap style={styles.body}>
        <View style={styles.container}>
          {props.systems.map((system, id) => {
            return (
              <View key={id}>
                <View style={styles.container}>
                  <Text style={styles.title} key={id}>
                    {t("translation:pdfs:system")} {id + 1}
                  </Text>
                  {system.profiles && system.profiles.length > 0 ? (
                    <View style={styles.container}>
                      {system.profiles.map((profile, id) => {
                        return (
                          <View key={profile.id} style={styles.container}>
                            <View wrap={false} style={styles.profile}>
                              {profileCeilingTypes
                                .filter((ceiling) => ceiling.type === profile.type)
                                .map((type) => (
                                  type.colors.map((color) => {
                                    if(color.labelEn === profile.product.selectedProperties.finishing.label
                                      || color.labelPl === profile.product.selectedProperties.finishing.label) {
                                      return(
                                        <Image key={profile.id} style={styles.image} src={`img/` + color.image} />
                                      )
                                    }
                                  })
                                ))}
                              <View style={styles.profileText}>
                                <Text style={styles.profileTitle}> {profile.product.label}</Text>
                                <Text style={styles.profileDescribe}>
                                  {t("translation:summary:length")}:
                                  {profile.type === "wall-mounted" || profile.type === "suspended" ? (
                                    <Text style={styles.profileTextBolder}> {profile.length.label}</Text>
                                  ) :(
                                    <Text style={styles.profileTextBolder}> {profile.length.value} + 280 mm</Text>
                                  )}
                                </Text>
                                <Text style={styles.profileDescribe}>
                                  {t("translation:summary:finishing")}:<Text style={styles.profileTextBolder}> {profile.product.selectedProperties.finishing.label}</Text>
                                </Text>
                                <Text style={styles.profileDescribe}>
                                  {t("translation:profile:product_code")}: <Text wrap={false} style={styles.profileTextBolder}>{getProfileCode(profile)}</Text>
                                </Text>
                              </View>
                            </View>
                            {profile.elements
                              .filter((elem) => elem.product)
                              .map((element) => {
                                return (
                                  <View key={element.id} style={styles.container}>
                                    <View wrap={false} style={styles.profile}>
                                      {/* {<Image style={styles.image} crossOrigin="Anonymous" src={createImage(element.product.thumbnail.url)}/>} */}
                                      <Image style={styles.image} src={element.product.thumbnail.url} />
                                      <View style={styles.profileText}>
                                        <Text style={styles.profileTitle}>{element.product.label}</Text>
                                        {element.product.properties.map((prop) => {
                                          return (
                                            <Text key={prop.id} style={styles.profileDescribe}>
                                              {prop.label + ": "}
                                              <Text style={styles.profileTextBolder}>
                                                {element.product.selectedProperties[prop.name].label}
                                              </Text>
                                            </Text>
                                          );
                                        })}
                                        <Text style={styles.profileDescribe}>
                                          {t("translation:profile:product_code")}:
                                          <Text style={styles.profileTextBolder}> {getProductCode(element.product)}
                                          </Text>
                                        </Text>
                                      </View>
                                    </View>
                                    {element.product.elements.filter((elem) => elem.product).map((elem) => renderElements(elem))}
                                  </View>
                                );
                              })}
                          </View>
                        );
                      })}
                    </View>
                  ) : (
                    <></>
                  )}
                  {system.accessories.connectors && system.accessories.connectors.length > 0 ? (
                    <View style={styles.container}>
                      <Text style={styles.accessoryTitle}>
                        {t("translation:pdfs:connectors")}
                      </Text>
                      {system.accessories.connectors.map((connector, id) => renderAccesories(connector))}
                    </View>
                  ) : (<></>)}
                  {system.accessories.pendants && system.accessories.pendants.length > 0 ? (
                    <View style={styles.container}>
                      <Text style={styles.accessoryTitle}>
                        {t("translation:pdfs:pendants")}
                      </Text>
                      {system.accessories.pendants.map((pendant, id) => renderAccesories(pendant))}
                    </View>
                  ) : (<></>)}
                  {system.accessories.plugs && system.accessories.plugs.length > 0 ? (
                    <View style={styles.container}>
                      <Text style={styles.accessoryTitle}>
                        {t("translation:pdfs:plugs")}
                      </Text>
                      {system.accessories.plugs.map((plugs, id) => renderAccesories(plugs))}
                    </View>
                  ) : (<></>)}
                </View>
              </View>
            );
          })}
          {props.project.comment && props.project.comment.length > 0 ? (
            <>
              <Text style={styles.title} break>{t("translation:pdfs:comment")}</Text>
              <Text style={styles.comment}>
                {props.project.comment}
              </Text>
            </>
          ) : (<></>)}
        </View>
      </Page>
    </Document>
  )
}

export default Template2;