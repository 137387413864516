import React, { useEffect, useState } from "react";
import Konva from "konva";
import { Line } from "react-konva";
import "konva/lib/shapes/Rect";

const Grid = ({box}) => {
  return (
    <>
      {[...Array(parseInt(box.height / 39))].map((x, i) => (
        <Line
          points={[0, (i + 1) * 39, parseInt(box.width), (i + 1) * 39]}
          stroke="#f2f2f2"
          strokeWidth={1}
          tension={1}
          key={i}
        />
      ))}
      {[...Array(parseInt(box.width / 39))].map((x, i) => (
        <Line
          points={[(i + 1) * 39, 0, (i + 1) * 39, parseInt(box.height)]}
          stroke="#f2f2f2"
          strokeWidth={1}
          tension={1}
          key={i}
        />
      ))}
    </>
  );
};

export default Grid;
