import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import {useTranslation} from "react-i18next";
import i18n from "i18next";


import {
  Alert,
  Button,
  Form,
  Modal,
} from "react-bootstrap";



const ForgotPassword = () => {
    const [resetModal, setResetModal] = useState(false);
    const [replayModal, setReplayModal] = useState(false);
    const [showResetAlert, setShowResetAlert] = useState(false);
    const [message, setMessage] = useState('');
    const [t] = useTranslation("translation");

    const {
        handleSubmit,
        formState: { errors },
    } = useForm();

    const reset = () => {
        const email = {
          username: document.getElementById('formResetEmail').value,
        }
        axios
        .post(
            `https://api.konfigurator.jellydev.pl/api/user/forgot-password?lang=${i18n.language}`,
            JSON.stringify(
              email
            ),
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
            )
          .then((response) => {
            setShowResetAlert(false);
            if(response.data) {
              setMessage(response.data.message.content);
            }
            if(response.response) {
              setMessage(response.response.data.message.content);
            }
            setResetModal(false);
            setReplayModal(true);
          })
          .catch((err) => {
            console.log(err);
            setShowResetAlert(true)
          });
    }

    return (
        <>
            <div className="lost-password" onClick={() => setResetModal(true)}>
                <p>{t("translation:reset_password:remind_btn")}</p>
            </div>

            <Modal show={resetModal} className="delete-modal list-modal">
                <Modal.Header className="p-0 pb-3">{t("translation:reset_password:reset_question")}</Modal.Header>
                {showResetAlert && (
                  <Alert variant="danger">{t("translation:reset_password:invalid_user")}</Alert>
                )}
                <Form className="reset-inputs" onSubmit={handleSubmit(reset)}>
                    <Form.Group className="mb-3" controlId="formResetEmail">
                    <Form.Control type="email" placeholder={t("translation:reset_password:login_placeholder")} required/>
                    </Form.Group>
                    <Button className="w-100 btn-black" type="submit">{t("translation:reset_password:confirm")}</Button>
                    <Button className="w-100" onClick={() => {setResetModal(false); setShowResetAlert(false)}}>{t("translation:reset_password:deny")}</Button>
                </Form>
            </Modal>

            <Modal show={replayModal} className="delete-modal list-modal">
              <Modal.Header className="p-0 pb-3">{message}</Modal.Header>
              <Button className="w-100" onClick={() => (setReplayModal(false))
              }>ok</Button>
            </Modal>
        </>
    );
}

export default ForgotPassword;