let profileCeilingTypes = [
  {
    type: "surface",
    labelPl: "NASTROPOWY",
    labelEn: "NASTROPOWY EN",
    image: "surface-black.jpg",
    colors: [
      {
        value: "black",
        labelEn: "black",
        labelPl: "czarny",
        image: "surface-black.jpg",
        code: "01",
        apiId: "5",
      },
      {
        value: "white",
        labelEn: "white",
        labelPl: "biały",
        image: "surface-white.jpg",
        code: "02",
        apiId: "90",
      },
      {
        value: "gold",
        labelEn: "gold",
        labelPl: "złoty",
        image: "surface-gold.jpg",
        code: "04",
        apiId: "91",
      },
      {
        value: "gray",
        labelEn: "gray",
        labelPl: "szary",
        image: "surface-grey.jpg",
        code: "03",
        apiId: "92",
      },
    ],
    profiles: [
      {
        label: "560 mm",
        value: 560,
        code: "P140.02",
      },
      {
        label: "840 mm",
        value: 840,
        code: "P140.03",
      },
      {
        label: "1120 mm",
        value: 1120,
        code: "P140.04",
      },
      {
        label: "1400 mm",
        value: 1400,
        code: "P140.05",
      },
      {
        label: "1680 mm",
        value: 1680,
        code: "P140.06",
      },
      {
        label: "1960 mm",
        value: 1960,
        code: "P140.07",
      },
      {
        label: "2240 mm",
        value: 2240,
        code: "P140.08",
      },
      {
        label: "2520 mm",
        value: 2520,
        code: "P140.09",
      },
      {
        label: "2800 mm",
        value: 2800,
        code: "P140.10",
      },
      {
        label: "3080 mm",
        value: 3080,
        code: "P140.11",
      },
      {
        label: "3360 mm",
        value: 3360,
        code: "P140.12",
      },
    ],
  },
  {
    type: "recessed",
    labelPl: "WPUSZCZANY",
    labelEn: "WPUSZCZANY EN",
    image: "recessed-black.jpg",
    colors: [
      {
        value: "black",
        labelEn: "black",
        labelPl: "czarny",
        image: "recessed-black.jpg",
        code: "01",
        apiId: "7",
      },
      {
        value: "white",
        labelEn: "white",
        labelPl: "biały",
        image: "recessed-white.jpg",
        code: "02",
        apiId: "96",
      },
      {
        value: "gold",
        labelEn: "gold",
        labelPl: "złoty",
        image: "recessed-gold.jpg",
        code: "04",
        apiId: "97",
      },
      {
        value: "gray",
        labelEn: "gray",
        labelPl: "szary",
        image: "recessed-grey.jpg",
        code: "03",
        apiId: "98",
      },
    ],
    profiles: [
      {
        label: "560 mm",
        value: 560,
        code: "P160.02",
      },
      {
        label: "840 mm",
        value: 840,
        code: "P160.03",
      },
      {
        label: "1120 mm",
        value: 1120,
        code: "P160.04",
      },
      {
        label: "1400 mm",
        value: 1400,
        code: "P160.05",
      },
      {
        label: "1680 mm",
        value: 1680,
        code: "P160.06",
      },
      {
        label: "1960 mm",
        value: 1960,
        code: "P160.07",
      },
      {
        label: "2240 mm",
        value: 2240,
        code: "P160.08",
      },
      {
        label: "2520 mm",
        value: 2520,
        code: "P160.09",
      },
      {
        label: "2800 mm",
        value: 2800,
        code: "P160.10",
      },
      {
        label: "3080 mm",
        value: 3080,
        code: "P160.11",
      },
      {
        label: "3360 mm",
        value: 3360,
        code: "P160.12",
      },
      {
        label: "3640 mm",
        value: 3640,
        code: "P160.13",
      },
    ],
  },
  {
    type: "suspended",
    labelPl: "ZWIESZANY",
    labelEn: "ZWIESZANY EN",
    image: "suspended-black.jpg",
    colors: [
      {
        value: "black",
        labelEn: "black",
        labelPl: "czarny",
        image: "suspended-black.jpg",
        code: "01",
        apiId: "4",
      },
      {
        value: "white",
        labelEn: "white",
        labelPl: "biały",
        image: "suspended-white.jpg",
        code: "02",
        apiId: "87",
      },
      {
        value: "gold",
        labelEn: "gold",
        labelPl: "złoty",
        image: "suspended-gold.jpg",
        code: "04",
        apiId: "88",
      },
      {
        value: "gray",
        labelEn: "gray",
        labelPl: "szary",
        image: "suspended-grey.jpg",
        code: "03",
        apiId: "89",
      },
    ],
    profiles: [
      {
        label: "560 mm",
        value: 560,
        code: "P150.02",
      },
      {
        label: "840 mm",
        value: 840,
        code: "P150.03",
      },
      {
        label: "1120 mm",
        value: 1120,
        code: "P150.04",
      },
      {
        label: "1400 mm",
        value: 1400,
        code: "P150.05",
      },
      {
        label: "1680 mm",
        value: 1680,
        code: "P150.06",
      },
      {
        label: "1960 mm",
        value: 1960,
        code: "P150.07",
      },
      {
        label: "2240 mm",
        value: 2240,
        code: "P150.08",
      },
      {
        label: "2520 mm",
        value: 2520,
        code: "P150.09",
      },
      {
        label: "2800 mm",
        value: 2800,
        code: "P150.10",
      },
      {
        label: "3080 mm",
        value: 3080,
        code: "P150.11",
      },
      {
        label: "3360 mm",
        value: 3360,
        code: "P150.12",
      },
      {
        label: "3640 mm",
        value: 3640,
        code: "P150.13",
      },
      {
        label: "3640 mm",
        value: 3920,
        code: "P150.14",
      },
    ],
  },
  {
    type: "wall-mounted",
    labelPl: "NAŚCIENNY",
    labelEn: "NAŚCIENNY EN",
    image: "wall-mounted-black.jpg",
    colors: [
      {
        value: "black",
        labelEn: "black",
        labelPl: "czarny",
        image: "wall-mounted-black.jpg",
        code: "01",
        apiId: "6",
      },
      {
        value: "white",
        labelEn: "white",
        labelPl: "biały",
        image: "wall-mounted-white.jpg",
        code: "02",
        apiId: "93",
      },
      {
        value: "gold",
        labelEn: "gold",
        labelPl: "złoty",
        image: "wall-mounted-gold.jpg",
        code: "04",
        apiId: "94",
      },
      {
        value: "gray",
        labelEn: "gray",
        labelPl: "szary",
        image: "wall-mounted-grey.jpg",
        code: "03",
        apiId: "95",
      },
    ],
    profiles: [
      {
        label: "280 mm",
        value: 280,
        code: "P140.01",
      },
      {
        label: "560 mm",
        value: 560,
        code: "P140.02",
      },
      {
        label: "840 mm",
        value: 840,
        code: "P140.03",
      },
      {
        label: "1120 mm",
        value: 1120,
        code: "P140.04",
      },
      {
        label: "1400 mm",
        value: 1400,
        code: "P140.05",
      },
      {
        label: "1680 mm",
        value: 1680,
        code: "P140.06",
      },
      {
        label: "1960 mm",
        value: 1960,
        code: "P140.07",
      },
      {
        label: "2240 mm",
        value: 2240,
        code: "P140.08",
      },
      {
        label: "2520 mm",
        value: 2520,
        code: "P140.09",
      },
      {
        label: "2800 mm",
        value: 2800,
        code: "P140.10",
      },
      {
        label: "3080 mm",
        value: 3080,
        code: "P140.11",
      },
      {
        label: "3360 mm",
        value: 3360,
        code: "P140.12",
      },
      {
        label: "3640 mm",
        value: 3640,
        code: "P140.13",
      },
      {
        label: "3640 mm",
        value: 3920,
        code: "P140.14",
      },
    ],
  }
];

export default profileCeilingTypes;
