import React, { useEffect, useState } from "react";
import Konva from "konva";
import { Rect, Group, Line } from "react-konva";
import "konva/lib/shapes/Rect";
import Connector from "./connector";
import Adapter from "./adapter";
import SvgImage from "./../svgImage";
import product from "../../pages/product";

const Profile = ({
  profile,
  profileClick,
  elementClick,
  elementProductClick,
  profileDragEnd,
  profileDragStart,
  selectedProfileId,
  nextProfile,
  prevProfile,
}) => {
  const [elements, setElements] = useState();

  const [group, setGroup] = useState({
    x: profile.x,
    y: profile.y,
    elements: profile.elements,
    orientation: profile.orientation,
  });
  const profileRef = React.useRef();

  useEffect(() => {
    let elementsTmp = [];
    const connectorsWidth = profile.type === "suspended" || profile.type === "wall-mounted" ? 1 : 19;

    profile.elements.map((element, i) => {
      switch (profile.orientation) {
        case "horizontal":
          //element
          elementsTmp.push(
            <Rect
              x={i * 39 + connectorsWidth}
              y={0}
              width={39}
              height={13}
              fill="white"
              stroke={profile.id === parseInt(selectedProfileId) ? "#077570" : "black"}
              strokeWidth={1}
              onClick={() => elementClick(element)}
              name="test"
              key={i}
            />
          );

          //connectors
          elementsTmp.push(
            <Connector profile={profile} position="start" prevProfile={prevProfile} nextProfile={nextProfile} key={i + `cs`} />
          );
          elementsTmp.push(
            <Connector profile={profile} position="end" prevProfile={prevProfile} nextProfile={nextProfile} key={i + `ce`} />
          );

          //adapters
          if (profile.connectorStart.connectedProfileId) {
            elementsTmp.push(<Adapter profile={profile} position="start" connectedProfile={prevProfile} key={i + `as`} />);
          }

          if (profile.connectorEnd.connectedProfileId) {
            elementsTmp.push(<Adapter profile={profile} position="end" connectedProfile={nextProfile} key={i + `ae`} />);
          }

          break;
        case "vertical":
          //element
          elementsTmp.push(
            <Rect
              x={0}
              y={i * 39 + connectorsWidth}
              width={13}
              height={39}
              fill="white"
              stroke={profile.id === parseInt(selectedProfileId) ? "#077570" : "black"}
              strokeWidth={1}
              onClick={() => elementClick(element)}
              name="test"
              key={i}
            />
          );

          //connectors
          elementsTmp.push(
            <Connector profile={profile} position="start" prevProfile={prevProfile} nextProfile={nextProfile} key={i + `cs`} />
          );
          elementsTmp.push(
            <Connector profile={profile} position="end" prevProfile={prevProfile} nextProfile={nextProfile} key={i + `ce`} />
          );

          //adapters
          if (profile.connectorStart.connectedProfileId) {
            elementsTmp.push(<Adapter profile={profile} position="start" connectedProfile={prevProfile} key={i + `as`} />);
          }

          if (profile.connectorEnd.connectedProfileId) {
            elementsTmp.push(<Adapter profile={profile} position="end" connectedProfile={nextProfile} key={i + `ae`} />);
          }

          break;
        default:
      }
    });

    //products icons
    switch (profile.orientation) {
      case "horizontal":
        profile.elements.map((element, i) => {
          if (element.product) {
            const productLength = element.product.selectedProperties.length.value;
            const propLengthId = element.product.selectedProperties.length.id;
            const elements = parseInt(productLength) / 280;
            const iconUrl = element.product.properties
              .filter((p) => p.name === "length")[0]
              .options.filter((o) => o.id === propLengthId)[0].icon.url;

            const x = i * 39 + connectorsWidth;
            const y = 0;
            const height = 14;
            const width = elements * 39;

            //white rectangle
            elementsTmp.push(
              <Rect x={x} y={y} height={height} width={width} fill="white" key={"rect_" + profile.id + "-" + element.id} />
            );

            //icon
            elementsTmp.push(
              <SvgImage
                src={iconUrl}
                x={x}
                y={y - 0.5}
                height={height}
                width={width}
                onClick={() => elementClick(element)}
                orientation={profile.orientation}
                key={"icon_" + profile.id + "-" + element.id}
              />
            );

            //black line
            elementsTmp.push(<Line points={[x, y, x + width, y]} stroke="black" strokeWidth={1} />);
            elementsTmp.push(<Line points={[x, y + height - 1, x + width, y + height - 1]} stroke="black" strokeWidth={1} />);

            //track/projector icons
            if (element.product.id === 30) {
              const trackElementWidth = (elements * 39) / element.product.elements.length;
              const trackProductWidth = 20;
              const trackProductSpace = (trackElementWidth - trackProductWidth) / 2;

              element.product.elements.map((trackElement, j) => {
                if (trackElement.product) {
                  const zm = element.product.selectedProperties.length.value / element.product.elements.length;
                  let changedWidth = (26 * zm) / 187;
                  elementsTmp.push(
                    <SvgImage
                      src={trackElement.product.icon.url}
                      x={i * 39 + j * changedWidth + connectorsWidth + trackProductSpace}
                      y={y - 0.5}
                      height={height}
                      // onClick={() => elementClick(element)}
                      // onClick={() => elementClick(trackElement)}
                      onClick={() => elementProductClick(element, trackElement)}
                      width={trackProductWidth}
                      orientation={profile.orientation}
                      key={"icon_" + profile.id + "-" + element.id + "-" + trackElement.id}
                    />
                  );
                }
              });
            }
          }
        });
        break;
      case "vertical":
        profile.elements.map((element, i) => {
          if (element.product) {
            const productLength = element.product.selectedProperties.length.value;
            const elements = parseInt(productLength) / 280;
            const iconUrl = element.product.properties
              .filter((p) => p.name === "length")[0]
              .options.filter((o) => o.value === productLength)[0].icon.url;

            const x = 0;
            const y = i * 39 + connectorsWidth;
            const height = 14;
            const width = elements * 39;

            //white rectangle
            elementsTmp.push(
              <Rect x={x} y={y} height={width} width={height} fill="white" key={"rect_" + profile.id + "-" + element.id} />
            );

            //icon
            elementsTmp.push(
              <SvgImage
                src={iconUrl}
                x={13.5}
                y={y}
                height={height}
                width={width}
                onClick={() => elementClick(element)}
                orientation={profile.orientation}
                key={"icon_" + profile.id + "-" + element.id}
              />
            );

            //black line
            elementsTmp.push(<Line points={[0, y, x, y + width]} stroke="black" strokeWidth={1} />);
            elementsTmp.push(<Line points={[13, y, 13, y + width]} stroke="black" strokeWidth={1} />);

            //track/projector icons
            if (element.product.id === 30) {
              const trackElementWidth = (width) / element.product.elements.length;
              const trackProductWidth = 20;
              const trackProductSpace = (trackElementWidth - trackProductWidth) / 2;

              element.product.elements.map((trackElement, j) => {
                if (trackElement.product) {
                  //icon
                  // x={i * 39 + j * 26 + connectorsWidth + trackProductSpace}
                  const zm = element.product.selectedProperties.length.value / element.product.elements.length;
                  let changedHeight = (26 * zm) / 187;
                  elementsTmp.push(
                    <SvgImage
                      src={trackElement.product.icon.url}
                      x={13.5}
                      y={i * 39 + j * changedHeight + trackProductSpace + connectorsWidth}
                      height={14}
                      // onClick={() => elementClick(element)}
                      // onClick={() => elementClick(trackElement)}
                      onClick={() => elementProductClick(element, trackElement)}
                      width={trackProductWidth}
                      orientation={profile.orientation}
                      key={"icon_" + profile.id + "-" + element.id + "-" + trackElement.id}
                    />
                  );
                }
              });
            }
          }
        });
        break;
      default:
    }

    setGroup({ ...group, x: profile.x, y: profile.y });
    setElements(elementsTmp);
  }, [profile, selectedProfileId]);

  const smooth = (val) => {
    if (val % 1 === 0) return val + 0.5;
    return val;
  };

  const dragSupport = (profile, x, y) => {
    if(profileDragEnd(profile, x, y)) {
      setGroup({
        x: x,
        y: y,
        elements: profile.elements,
        orientation: profile.orientation,
      })
    }
  }

  return (
    <Group
      ref={profileRef}
      draggable="true"
      x={smooth(group.x)}
      y={smooth(group.y)}
      fill="black"
      onClick={() => profileClick(profile, profileRef)}
      onDragStart={(res) => {
        profileDragStart(profile);
      }}
      onDragMove={(res) => {
        //const profile = res.target;
        //console.log(profile.attrs);
      }}
      onDragEnd={(res) => {
        dragSupport(profile, res.target.attrs.x, res.target.attrs.y)
      }}
    >
      {elements}
    </Group>
  );
};

export default Profile;
