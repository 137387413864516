import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Navigation from "../components/navbar";
import { useProject } from "../store/projectProvider";
import projectTemplate from "../jsonTemplates/projectTemplate";

const Layout = () => {
  const { dispatch } = useProject();

  useEffect(() => {
    dispatch({ type: "LOAD", loadedProject: projectTemplate });
  }, [dispatch]);
  return (
    <>
      <Navigation />
      <Outlet />
    </>
  );
};

export default Layout;
