import React from "react";
import { Container, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";

const ConfiguratorStart4 = () => {
  const { t, i18n} = useTranslation("translation");

  return (
    <>
      <Container fluid className="main-container px-0 configurator-start">
        <div className="system">
          <p>4/4</p>
          <p>{t("translation:choose_system")}</p>
          <div className="system-types">
            <Link to="/chooseType">
              <div className="zoom">
                <div>
                  <img src="img/projektor.png" alt="" />
                </div>
                <p>system z60</p>{" "}
              </div>
            </Link>
            <Link to="/chooseType">
              <div className="zoom">
                <div>
                  <img src="img/projektor.png" alt="" />
                </div>
                <p>system z60</p>{" "}
              </div>
            </Link>
          </div>
          <div className="start-footer">
            {" "}
            <Link
              to="/dimensions"
              className="btn btn-text img-left btn-back"
            >
              <img src="img/arrow-circle-left.svg" alt="" />
              {t("translation:back")}
            </Link>
            <Link
              to="/chooseType"
              className="btn btn-text img-right btn-next"
            >
              {t("translation:go_to_configurator")}
              <img src="img/arrow-circle-right.svg" alt="" />
            </Link>
          </div>
        </div>
      </Container>
    </>
  );
};

export default ConfiguratorStart4;
