import React, { Component } from "react";
import { Image } from "react-konva";

class SvgImage extends React.Component {
  state = {
    image: null,
  };
  componentDidMount() {
    fetch(this.props.src)
      .then((r) => r.text())
      .then((svg) => {
        var xmlSerializer = new XMLSerializer();

        var parser = new DOMParser();
        var result = parser.parseFromString(svg, "text/xml");

        var attrs = {
          width: this.props.width + 1,
          height: this.props.height + 1,
        };
        Object.keys(attrs).forEach(function (key) {
          return result.documentElement.setAttribute(key, attrs[key]);
        });
        var customSVG = xmlSerializer.serializeToString(result);

        var blob = new Blob([customSVG], { type: "image/svg+xml" });
        var url = URL.createObjectURL(blob);

        this.image = new window.Image();
        this.image.src = url;
        this.image.crossOrigin = "Anonymous";
        this.image.addEventListener("load", this.handleLoad);
      });
  }

  handleLoad = () => {
    // after setState react-konva will update canvas and redraw the layer
    // because "image" property is changed
    this.setState({
      image: this.image,
    });
    // if you keep same image object during source updates
    // you will have to update layer manually:
    //this.imageNode.getLayer().batchDraw();
  };
  render() {
    return (
      <>
        <Image
          x={this.props.x}
          y={this.props.y}
          height={this.props.height}
          width={this.props.width}
          image={this.state.image}
          ref={(node) => {
            this.imageNode = node;
          }}
          onClick={() => this.props.onClick()}
          rotation={this.props.orientation === "horizontal" ? 0 : 90}
        />
      </>
    );
  }
}

export default SvgImage;
