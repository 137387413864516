import React from "react";
import { useProject } from "../store/projectProvider";
import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";

const ProductRow = ({ profile, element, trackElement, margins = true, icons = true }) => {
  const { getProductCode } = useProject();
  const { t, i18n} = useTranslation("translation");

  let url = "/product/" + profile.id + "/" + element.id;
  let product = element.product;
  let marginLeft = 20;
  //projectors
  if (trackElement) {
    url = "/product-projector/" + profile.id + "/" + element.id + "/" + trackElement.id;
    product = trackElement.product;
    marginLeft = 40;
  }

  if (!margins) {
    marginLeft = 0;
  }

  return (
    <div
      className="position-relative configurator-data parameters-selected"
      key={element.id}
      style={{ marginLeft: marginLeft + "px" }}
    >
      <div className="configurator-data-img">
        <img className="img-fluid" src={product.thumbnail.url} alt="" />
        {icons && (
          <div className="icon-container">
            <Link to={url} className="btn btn-icon">
              <img src="/img/edit.svg" alt="edit" />
            </Link>
          </div>
        )}
      </div>
      <div className="configurator-data-content">
        <p>{product.label}</p>

        {product.properties.map((prop) => {
          return (
            <p key={prop.name}>
              {prop.label + ": "}
              <span>{product.selectedProperties[prop.name].label}</span>
            </p>
          );
        })}
        <p>
          {t("translation:profile:product_code")}: <span>{getProductCode(product)}</span>
        </p>
      </div>
    </div>
  );
};

export default ProductRow;
