import React, {useEffect, useState} from "react";
import "konva/lib/shapes/Rect";
import { Rect, Text, Group, Image, Line } from "react-konva";

const ProfileRuler = ({prevProfile, profile, nextProfile, params}) => {
  let x = profile.x;
  let y = profile.y;
  if(prevProfile && prevProfile.orientation !== profile.orientation) {
    if(profile.orientation === "horizontal") {
      x = profile.x - 15;
    } else {
      y = profile.y - 15;
    }
  } else if(prevProfile && prevProfile.orientation === profile.orientation) {
    if(profile.type === "wall-mounted" || profile.type === "suspended") {
      if(profile.orientation === "horizontal") {
        x = profile.x + 1;
      } else {
        y = profile.y + 1;
      }
    } else {
      if(profile.orientation === "horizontal") {
        x = profile.x - 1;
      } else {
        y = profile.y - 1;
      }
    }
  }

  let text, textShift = 28;

  if(prevProfile && nextProfile) {
    if(prevProfile.orientation !== profile.orientation &&
      nextProfile.orientation !== profile.orientation) {
      if(profile.type === "wall-mounted" || profile.type === "suspended") {
        text = profile.length.value + 140 + " mm";
      } else {
        text = profile.length.value + 420 + " mm";
      }
    } else if(prevProfile.orientation !== profile.orientation ||
      nextProfile.orientation !== profile.orientation) {
      if(profile.type === "wall-mounted" || profile.type === "suspended") {
        text = profile.length.value + 70 + " mm";
      } else  {
        text = profile.length.value + 350 + " mm";
      }
    } else {
      if(profile.type === "wall-mounted" || profile.type === "suspended") {
        text = profile.length.value + " mm";
      } else  {
        text = profile.length.value + 280 + " mm";
      }
    }
  } else if(nextProfile && nextProfile.orientation !== profile.orientation ||
    prevProfile && prevProfile.orientation !== profile.orientation) {
    if(profile.type === "wall-mounted" || profile.type === "suspended") {
      text = profile.length.value + 70 + " mm";
    } else  {
      text = profile.length.value + 350 + " mm";
    }
  } else {
    if(profile.type === "wall-mounted" || profile.type === "suspended") {
      text = profile.length.value + " mm";
    } else  {
      text = profile.length.value + 280 + " mm";
    }
  }

  return (
    <Group
      x={profile.orientation === "horizontal" ? x - 0.5 : x - 15}
      y={profile.orientation === "horizontal" ? y - 15 : y - 0.5}
    >
      <Line
        x={profile.orientation === "horizontal" ? 1 : 37}
        y={profile.orientation === "horizontal" ? 37 : 1}
        points={profile.orientation === "horizontal" ? [5, 0.5, 0, 4.5, 5, 8.5] : [-0.5, 5, 4.5, 0, 9.5, 5]}
        strokeWidth={1}
        fill="#077570"
        stroke="#077570"
      />
      <Rect
        x={profile.orientation === "horizontal" ? 1 : 41}
        y={profile.orientation === "horizontal" ? 41 : 1}
        width={profile.orientation === "horizontal" ? params.length - 1: 1}
        height={profile.orientation === "horizontal" ? 1 : params.length -1}
        fill="#077570"
      />
      <Text
        text={text}
        x={profile.orientation === "horizontal" ? params.length/2 - textShift : 45}
        y={profile.orientation === "horizontal" ? 45 : params.length/2 + textShift}
        rotation={profile.orientation === "horizontal" ? 0 : 270}
        fontSize={14}
        fontStyle={"bold"}
        fontFamily={"roboto"}
        fill="#077570"
      />
      <Line
        x={profile.orientation === "horizontal" ? params.length : 37}
        y={profile.orientation === "horizontal" ? 37 : params.length}
        points={profile.orientation === "horizontal" ? [-5, 0.5, 0, 4.5, -5, 8.5] : [-0.5, -5, 4.5, 0, 9.5, -5]}
        fill="#077570"
        stroke="#077570"
        strokeWidth={1}
      />
    </Group>
  )
};

export default ProfileRuler;