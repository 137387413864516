import React from "react";
import { Container, Spinner } from "react-bootstrap";
import {useTranslation} from "react-i18next";

const ConfiguratorStart = () => {
  const { t, i18n} = useTranslation("translation");
  return (
    <>
      <Container fluid className="main-container px-0 configurator-start">
        <div className="start-spinner">
          <div>{(t("translation:create_project:configurator_start"))}</div>
          <Spinner animation="border" size="sm" />
        </div>
      </Container>
    </>
  );
};

export default ConfiguratorStart;
