import React, { useState, useEffect } from "react";
import { Button, Modal, Stack, Form } from "react-bootstrap";
import TextareaAutosize from "react-textarea-autosize";
import axios from "axios";
import { useProject } from "./../store/projectProvider";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

const Comment = (obj) => {
  const { project, dispatch } = useProject();
  const [showModal, setShowModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [comment, setComment] = useState(obj.project.content.comment);
  const [prevComment, setPrevComment] = useState(obj.project.content.comment);
  const { t, i18n} = useTranslation("translation");
  const editComment = () => {
    if (comment.length === 0) {
      obj.project.content.comment = "";
    } else {
      obj.project.content.comment = comment;
    }
    const projectData = {
      content: obj.project.content,
    };

    axios
      .post(`https://api.konfigurator.jellydev.pl/api/project/${obj.project.id}?lang=${i18n.language}`, JSON.stringify(projectData))
      .then((response) => {
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Button className="list-icon" onClick={() => setShowModal((prevState) => !prevState)}>
        <img src="img/comment.svg" alt="" />
      </Button>
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
          setEdit(false);
          setComment(prevComment);
        }}
        className="comment-modal list-modal edit-modal"
      >
        <Modal.Title>{t("translation:project_list_comment:comment_project")} {obj.project.content.name}</Modal.Title>
        <Modal.Header closeButton>
          {t("translation:project_list_comment:comment_remarks")}
          {edit ? (
            <></>
          ) : (
            <>
              {" "}
              <Button className="" onClick={() => setEdit((prevState) => !prevState)}>
                <img src="img/edit.svg" alt="" />
              </Button>
            </>
          )}
        </Modal.Header>
        <Modal.Body>
          {edit ? (
            <>
              <TextareaAutosize
                placeholder={t("translation:project_list_comment:comment_placeholder")}
                className="w-100 mt-4"
                minRows={10}
                onChange={(changeEvent) => setComment(changeEvent.target.value)}
              />
              <Stack direction="horizontal" gap={3}>
                <Button
                  className="btn-white"
                  key="back"
                  onClick={() => {
                    setEdit(false);
                    setComment(prevComment);
                  }}
                >
                  {t("translation:project_list_comment:dont_save")}
                </Button>
                <Button
                  className="btn-black"
                  key="zatwierdź"
                  onClick={() => {
                    editComment();
                    setEdit(false);
                    setPrevComment(comment);
                  }}
                >
                  {t("translation:project_list_comment:save_data")}
                </Button>
              </Stack>
            </>
          ) : (
            <p>{comment && <>{comment}</>}</p>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Comment;
