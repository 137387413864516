import React, {useEffect, useState} from "react";
import { Navbar, Nav, Button, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import useUser from "../components/useUser";
import Logout from "../pages/logout";
import useProjectLanguage from "./useProjectLanguage";
import '../i18n';
import { useTranslation } from "react-i18next";
import {useProject} from "../store/projectProvider";

const Navigation = () => {
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState();
  const [modalMessage, setModalMessage] = useState();
  const [path, setPath] = useState();
  const { user, removeUser } = useUser();
  const [account, setAccount] = useState(false);
  const {projectLanguage, setProjectLanguage} = useProjectLanguage();
  const { t, i18n} = useTranslation("translation");
  const {project, dispatch} = useProject();
  const accFunction = () => {
    if(user) {
      setAccount((prevState) => !prevState)
    } else {
      showModalFunction(`${t("translation:navbar:data_lose")}`, "/login");
    }
  }
  const showModalFunction = (message, path) => {
    setShowModal(true);
    setModalMessage(message);
    setPath(path);
  }
  const moveTo = () => {
    navigate(path);
    setShowModal(false);
  }

  // const changeLanguage = () => {
  //   if(projectLanguage === "en") {
  //     setProjectLanguage('pl');
  //     i18n.changeLanguage('pl');
  //   } else {
  //     setProjectLanguage('en');
  //     i18n.changeLanguage('en');
  //   }
  // }

  // const setLanguageAfterRefresh = () => {
  //   if(projectLanguage === "en") {
  //     i18n.changeLanguage("en");
  //   } else {
  //     i18n.changeLanguage("pl");
  //   }
  // }

  useEffect(() => {
    if(project.language) {
      i18n.changeLanguage(project.language)
      setProjectLanguage(project.language);
    } else {
      i18n.changeLanguage(projectLanguage);
      dispatch({
        type: "UPDATE",
        project: { ...project, language: i18n.language},
      });
    }
  }, []);

  return (
    <Navbar bg="black" expand="md">
    {user? (
      <Navbar.Brand as={Link} to="/list">
        {" "}
        {/*<img src="/img/logo.svg" alt="zaho" /> /!* <Zaho /> *!/{" "}*/}
        <img className="navbar-brand-logo" src="/img/zaho_logo.png" alt="zaho" /> {/* <Zaho /> */}{" "}
      </Navbar.Brand>
    ) : (
      <Navbar.Brand as={Button} onClick={() => showModalFunction(`${t("translation:navbar:data_lose")}`, "/login")}>
        {" "}
        <img className="navbar-brand-logo" src="/img/zaho_logo.png" alt="zaho" /> {/* <Zaho /> */}{" "}
      </Navbar.Brand>
    )}
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      {account ? (
        <Nav className="ms-auto nav-links account">
          <Nav.Link as={Link} to="/data">
            <img src="/img/data.svg" alt=" " />
            {t("translation:navbar:data")}
          </Nav.Link>
          <Nav.Link onClick={() => removeUser()} as={Link} to="/login">
            <img src="/img/logout.svg" alt=" " />
            {t("translation:navbar:logout_btn")}
          </Nav.Link>
          <Button onClick={() => accFunction()}>
            <img src="/img/account.svg" alt="login" />
          </Button>
        </Nav>
      ) : (
        <Nav className="ms-auto nav-links">
          {user ? (
            <>
              <Nav.Link as={Link} to="/create-project">
                <img src="/img/add-project.svg" alt="add-project" />
                {t("translation:navbar:add_project")}
              </Nav.Link>
              <Nav.Link as={Link} to="/list">
                <img src="/img/list-projects.svg" alt="projects-list" />
                {t("translation:navbar:project_list")}
              </Nav.Link>
            </>
          ):(
            <>            
              <Nav.Link as={Button} to="/create-project" onClick={() => showModalFunction(`${t("translation:navbar:data_lose")}`, "/create-project")}>
                <img src="/img/add-project.svg" alt="add-project" />
                {t("translation:navbar:add_project")}
              </Nav.Link>
            </>
          )}
          <Button onClick={() => accFunction()}>
            <img src="/img/account.svg" alt="login" />
          </Button>
        </Nav>
      )}
    </Navbar.Collapse>

      <Modal show={showModal} className="delete-modal delete list-modal">
        <Modal.Header>{modalMessage}</Modal.Header>
        <Button onClick={() => moveTo()}>{t("translation:navbar:confirm")}</Button>
        <Button onClick={() => setShowModal(false)}>{t("translation:navbar:deny")}</Button>
      </Modal>
    </Navbar>

  );
};
export default Navigation;
