import { useState } from 'react';

export default function useCurrentProjectCode() {
    const getCode = () => {
        const codeString = localStorage.getItem("projectCode");
        const projectCode = JSON.parse(codeString);
        return projectCode;
    };

    const [projectCode, setProjectCode] = useState(getCode());

    const setCode = (projectCode) => {
        localStorage.setItem("projectCode", JSON.stringify(projectCode));
        setProjectCode(projectCode);
    };

    const removeCode = () => {
        localStorage.removeItem("projectCode");
    };

    return {
        getCurrentProjectCode: getCode,
        removeProjectCode: removeCode,
        setProjectCode: setCode,
        projectCode: projectCode,
    }
}