import { Route, Routes, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./scss/index.scss";
import Layout from "./pages/layout";
import LayoutCanvas from "./pages/layout-canvas";
import Login from "./pages/login";
import Page from "./pages/page";
import List from "./pages/list";
import Data from "./pages/data";
import ConfiguratorStart from "./pages/configuratorStart";
import System from "./pages/system";
import CreateProject from "./pages/createProject";
import Surface from "./pages/surface";
import Dimensions from "./pages/dimensions";
import Type from "./pages/chooseType";
import Color from "./pages/chooseColor";
import Profiles from "./pages/profiles";
import Modules from "./pages/modules";
import Projectors from "./pages/projectors";
import Product from "./pages/product";
import ProductProfile from "./pages/product-profile";
import ProductProjector from "./pages/product-projector";
import Profile from "./pages/profile";
import Summary from "./pages/summary";
import Accessories from "./pages/accessories";
import { ProjectProvider } from "./store/projectProvider";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useUser from "./components/useUser";
import axios from "axios";
import Reset from "./pages/reset";
import Warning from "./pages/warning";
import SystemAccessories from "./pages/system-accessories";
import {useTranslation} from "react-i18next";

function App() {
  const { user, removeUser } = useUser();
  const [t] = useTranslation("translation");
  const navigate = useNavigate();

  if (user) {
    const zm = JSON.parse(localStorage.getItem("user")) ?? [];
    if (zm.token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + zm.token;
    }
  }
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        removeUser();
        navigate("/login");
      }
      return error;
    }
  );

  return (
    <div className="App">
      <ProjectProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/reset" element={<Reset />} />
          <Route element={<Layout />}>
            <Route path="/" exact element={<Page />} />
            <Route path="/page" element={<Page />} />
            <Route path="/list" element={<List />} />
            <Route path="/data" element={<Data />} />
            <Route path="/configuratorStart" element={<ConfiguratorStart />} />
            <Route path="/system" element={<System />} />
            <Route path="/create-project" element={<CreateProject />} />
            <Route path="/surface" element={<Surface />} />
            <Route path="/dimensions" element={<Dimensions />} />
            <Route path="/warning" element={<Warning/>} />
          </Route>
          <Route element={<LayoutCanvas />}>
            <Route path="/chooseType" element={<Type />} />
            <Route path="/chooseColor/:type" element={<Color />} />
            <Route path="/profiles/:type/:apiId" element={<Profiles />} />
            <Route path="/profile/:profileId" element={<Profile />} />

            {/* products lists */}
            <Route path="/modules/:profileId/:elementId" element={<Modules />} />
            <Route path="/projectors/:profileId/:elementId/:trackElementId" element={<Projectors />} />

            {/* product view */}
            <Route path="/product-profile/:profileId" element={<ProductProfile />} />

            <Route path="/product/:profileId/:elementId" element={<Product />} />
            <Route path="/product/:profileId/:elementId/:productId" element={<Product />} />
            
            <Route path="/product-projector/:profileId/:elementId/:trackElementId" element={<ProductProjector />} />
            <Route path="/product-projector/:profileId/:elementId/:trackElementId/:productId" element={<ProductProjector />} />

            {/* system accesories choose */}
            <Route path="/system-accessories/:systemId" element={<SystemAccessories />} />

            {/* accessories */}
            <Route path="/accessories" element={<Accessories />} />

            {/* summary */}
            <Route path="/summary" element={<Summary />} />
          </Route>
        </Routes>
      </ProjectProvider>
      <ToastContainer position="bottom-right" theme="dark" toastStyle={{ backgroundColor: "#000000" }} autoClose={6000} />
    </div>
  );
}

export default App;
