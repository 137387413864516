import React from "react";
import "konva/lib/shapes/Rect";

const Legend = () => {
  return (
    <div className="legend">
      <img src="/img/legend.jpg" alt="legenda"/>
      <div className="position-relative">
        <p className="bottom-legend">280 mm</p>
        <p className="right-legend">280 mm</p>
      </div>
    </div>
  );
};

export default Legend;