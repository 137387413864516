import React, { useState } from "react";
import { Container, Form, Button } from "react-bootstrap";
import useUser from "../components/useUser";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useProject } from "./../store/projectProvider";
import useCurrentProjectId from "../components/useCurrentProjectId";
import useCurrentProjectCode from "../components/useCurrentProjectCode";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import useProjectLanguage from "../components/useProjectLanguage";

const CreateProject = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const { project, dispatch, saveProject } = useProject();
  const { removeProjectEditId, getCurrentProjectId } = useCurrentProjectId();
  const { removeProjectCode, getCurrentProjectCode } = useCurrentProjectCode();
  const { t, i18n} = useTranslation("translation");
  const {projectLanguage, setProjectLanguage} = useProjectLanguage();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const submit = (data) => {
    dispatch({
      type: "UPDATE",
      project: { ...project, designer: data.designer, name: data.projectName, language: i18n.language},
    });

    saveProject(user, data.designer);
    navigate("/surface");
  };

  const changeLanguage = () => {
    if(i18n.language === "en") {
      setProjectLanguage('pl');
      i18n.changeLanguage('pl');
    } else {
      setProjectLanguage('en');
      i18n.changeLanguage('en');
    }
  }

  if (getCurrentProjectId()) {
    removeProjectEditId();
  }
  if(getCurrentProjectCode())
    removeProjectCode()

  if(!user) {
    document.cookie = "code" + "=;" + ";path=/";
    document.cookie = "id" + "=;" + ";path=/";
  }

  return (
    <>
      <Container fluid className="main-container px-0 configurator-start">
        <div className="project-name">
          <div className="language-change">
            {" "}
            <img className="pe-2" src="img/web.svg" alt="" />
            {i18n.language ? (
              <span onClick={() => changeLanguage()}>{i18n.language}</span>
            ) : (<span onClick={() => changeLanguage()}>pl</span>)}
          </div>
          <p>
            1/4
          </p>
          <p>{t("translation:create_project:header")}</p>
          <Form className="create-project" onSubmit={handleSubmit(submit)}>
            <Form.Group>
              <Form.Control type="text" placeholder={t("translation:create_project:project_name") + "*"} {...register("projectName")} required />
            </Form.Group>
            <Form.Group>
              <Form.Control type="text" placeholder={t("translation:create_project:project_designer") + "*"} {...register("designer")} required />
            </Form.Group>
            <Button className="btn btn-black mt-auto" type="submit">
              {t("translation:create_project:next_page")}
            </Button>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default CreateProject;
