import {Alert, Button, Modal} from "react-bootstrap";
import {Link, useNavigate, useOutletContext} from "react-router-dom";
import { useProject } from "./../store/projectProvider";
import React, {useState, useEffect, useContext} from "react";
import CustomScroll from "react-custom-scroll";
import ProfileObjectSimple from "../components/profile-object-simple";
import ProductRow from "../components/product-row";
import useUser from "../components/useUser";
import {useTranslation} from "react-i18next";

const Accessories = () => {
  const { project, getProfileCode, sortProfilesBySystems, getProfileLength } = useProject();
  const navigate = useNavigate();
  const [alertModal, setAlertModal] = useState(false);
  const { user } = useUser();
  const { t, i18n} = useTranslation("translation");
  const [canvaScreen, setCanvaScreen, setRuler] = useOutletContext();

  useEffect(() => {
    if (project.systems.length === 0) {
      sortProfilesBySystems();
    }
    document.querySelector('.canva-blur').classList.remove('d-none');
    setRuler(true);
  }, []);

  const renderAccesories = (accessory) => (
    <div key={accessory.id} className="position-relative configurator-data parameters-selected only-display">
      <div className="position-relative configurator-data w-100">
        <div className="configurator-data-img">
          <img className="img-fluid" src={accessory.thumbnail.url} alt="" />
        </div>
        <div className="configurator-data-content max-w">
          <p>{accessory.label}</p>
          {accessory.properties.map(prop => {
            return(
              prop.options[0] ? (
                prop.label == "Attention" ? (
                  <p className="red">
                    {prop.label}: <span>{prop.options[0].label}</span>
                  </p>
                ):( <p>
                    {prop.label}: <span>{prop.options[0].label}</span>
                  </p>
                )
              ): <></>
            )
          })}
          <p>
            {t("translation:profile.product_code")}: <span>{accessory.code}</span>
          </p>
        </div>
        <div className="circle">{accessory.count} szt.</div>
      </div>
    </div>
  );


  return (
    <>
      <div className="configurator-nav">
        {" "}
        <Button className="btn-text btn-next img-left" onClick={() => setAlertModal(true) }>
          <img src="/img/arrow-circle-left.svg" alt="" />{" "}
          {t("translation:accessories:back_to_project")}
        </Button>
        <Link to="/summary" className="btn btn-text img-right">
          {t("translation:accessories:summary")}
          <img src="/img/arrow-circle-right.svg" alt="" />{" "}
        </Link>
      </div>

      <CustomScroll>
        <div className="configurator-wrapper choose-accessories">
          {project.systems.map((system, id) => {
            return(
              <div
                key={id}
              >
                <h3 className="pb-3 pt-3 configurator-subtitle">{t("translation:accessories:system")} {id + 1}</h3>
                <Link to={"/system-accessories/" + id} className="btn mt-3">
                  {t("translation:accessories:add_accessory")}
                </Link>
                {/* profiles */}
                {system.profiles.map((profile, id) => {
                  return (
                    <div key={id} className="item-configurator pt-4">
                      <ProfileObjectSimple profile={profile} />
                      <div className="position-relative configurator-data parameters-selected">
                        <div className="configurator-data-img">
                          <img className="img-fluid" src={profile.product.thumbnail.url} alt="" />
                        </div>
                        <div className="configurator-data-content">
                          <p>{profile.product.label}</p>
                          <p>
                            {t("translation:profile:length")}: <span>{profile.length && profile.length.label}</span>
                            {profile.type !== "suspended" ? (
                                profile.type !== "wall-mounted" ? <span> (+280 mm)</span>:<></>
                              )
                              :<></>}
                          </p>
                          {getProfileLength(profile) ?
                            (
                              <p>{t("translation:profile:connectors_length")}: <span>{getProfileLength(profile)} mm</span></p>
                            ) : (<></>)}
                          <p>
                            {t("translation:profile:finishing")}:{" "}
                            <span>
                              {profile.product?.selectedProperties && profile.product.selectedProperties.finishing.label}
                            </span>
                          </p>
                          <p>
                            {t("translation:profile:product_code")}: <span>{profile.length && getProfileCode(profile)}</span>
                          </p>
                        </div>
                      </div>
                      {/* products */}
                      {profile && profile.elements
                        .filter((elem) => elem.product)
                        .map((element) => {
                          return (
                            <div key={element.id}>
                              <ProductRow profile={profile} element={element} icons={false} />
                              {element.product.elements.length > 0 && element.product.elements
                                .map(productElement => {
                                  if(productElement.product !== null) {
                                    return(
                                      <ProductRow
                                        profile={profile}
                                        element={element}
                                        trackElement={productElement}
                                        icons={false}
                                        key={element.id + "_" + productElement.id}
                                      />
                                    )
                                  }
                                })}
                            </div>
                          )
                        })}
                    </div>
                  )
                })}
                {/* accessories */}
                {system.accessories.connectors && system.accessories.connectors.length > 0 ? (
                  <div>
                    <div className="item-configurator pb-1">
                      <h3 className="pb-3 pt-3 configurator-subtitle">{t("translation:accessories:connectors")}:</h3>
                      {system.accessories.connectors.map((connectors, index) => renderAccesories(connectors))}
                    </div>
                  </div>
                ) : (<></>)}
                {system.accessories.pendants && system.accessories.pendants.length > 0 ? (
                  <div>
                    <div className="item-configurator pb-1">
                      <h3 className="pb-3 pt-3 configurator-subtitle">{t("translation:accessories:pendants")}:</h3>
                      {system.accessories.pendants.map((pendants, index) => renderAccesories(pendants))}
                    </div>
                  </div>
                ) : (<></>)}
                {system.accessories.plugs && system.accessories.plugs.length > 0 ? (
                  <div>
                    <div className="item-configurator pb-1">
                      <h3 className="pb-3 pt-3 configurator-subtitle">{t("translation:accessories:plugs")}:</h3>
                      {system.accessories.plugs.map((plug, index) => renderAccesories(plug))}
                    </div>
                  </div>
                ) : (<></>)}
                {system.accessories.electronics && system.accessories.electronics.length > 0 ? (
                  <div>
                    <div className="item-configurator pb-1">
                      <h3 className="pb-3 pt-3 configurator-subtitle">{t("translation:accessories:electronics")}:</h3>
                      {system.accessories.electronics.map((electronic, index) => renderAccesories(electronic))}
                    </div>
                  </div>
                ) : (<></>)}
              </div>
            )
          })}
        </div>
      </CustomScroll>

      <Modal show={alertModal} className="delete-modal delete list-modal">
        <Modal.Header className="p-0 pb-3">
          {t("translation:accessories:return_warning")}
        </Modal.Header>
        <Button onClick={() => navigate("/chooseType")}>{t("translation:accessories:confirm")}</Button>
        <Button onClick={() => setAlertModal(false)}>{t("translation:accessories:deny")}</Button>
      </Modal>
    </>
  );
};

export default Accessories;
