import React, { useEffect, useState } from "react";

const Loader = () => {
  return (
    <>
      <div className="text-center mt-5 mb-5">
        <img src="/img/loader.svg" alt="" />
      </div>
    </>
  );
};

export default Loader;
