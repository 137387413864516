import React, { useEffect, useState } from "react";
import Konva from "konva";
import { Stage, Layer, Circle, Rect, Text, Group } from "react-konva";
import "konva/lib/shapes/Rect";

const Connector = ({ profile, position, prevProfile, nextProfile }) => {
  if(profile.type === "suspended" || profile.type === "wall-mounted") {
    if(profile.orientation === "horizontal") {
      if(profile.connectorEnd.connectedProfileId && nextProfile.orientation === profile.orientation) {
        profile.connectorEnd.width = 0;
      } else {
        profile.connectorEnd.width = 1;
      }
      if(profile.connectorStart.connectedProfileId && prevProfile.orientation === profile.orientation) {
        profile.connectorStart.width = 0;
        profile.connectorStart.x = 1;
      } else {
        profile.connectorStart.x = 0;
        profile.connectorStart.width = 1;
      }
    } else {
      if(profile.connectorEnd.connectedProfileId && nextProfile.orientation === profile.orientation) {
        profile.connectorEnd.height = 0;
      } else {
        profile.connectorEnd.height = 1;
      }
      if(profile.connectorStart.connectedProfileId && prevProfile.orientation === profile.orientation) {
        profile.connectorStart.height = 0;
        profile.connectorStart.y = 1;
      } else {
        profile.connectorStart.y = 0;
        profile.connectorStart.height = 1;
      }
    }
  }

  return (
    <Rect
      x={position === "start" ? profile.connectorStart.x : profile.connectorEnd.x}
      y={position === "start" ? profile.connectorStart.y : profile.connectorEnd.y}
      width={position === "start" ? profile.connectorStart.width : profile.connectorEnd.width}
      height={position === "start" ? profile.connectorStart.height : profile.connectorEnd.height}
      fill="black"
      stroke="black"
      strokeWidth={1}
      name=""
    />
  );
};

export default Connector;
