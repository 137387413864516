import { StyleSheet } from '@react-pdf/renderer'

export default StyleSheet.create({
    body: {
      display: 'flex',
      width: '100%',
      position: 'relative',
      paddingLeft: '30px',
      paddingRight: '30px',
      paddingBottom: '20px',
      paddingTop: '20px',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    container: {
      maxWidth: '100%',
      maxHeight: '100%',
    },
    header: {
      textTransform: 'uppercase',
      fontWeight: 700,
      paddingBottom: '25px',
      marginBottom: '8px',
      fontFamily: "termina",
      width: '100%',
      textAlign: 'center',
      fontSize: '20px',
    },
    secondHeader: {
      fontFamily: "termina",
      paddingBottom: '10px',
      marginTop: '10px',
      width: '100%',
      textAlign: 'center',
      fontSize: '18px',
      textTransform: 'uppercase',
      borderBottom: '1px solid black',
    },
    projectDescribe: {
      textTransform: 'uppercase',
      fontFamily: "termina",
      paddingBottom: '5px',
      fontWeight: 'bold',
      fontSize: '9px',
      width: '100%',
    },
    projectCode: {
      textTransform: 'uppercase',
      fontFamily: "termina",
      paddingBottom: '5px',
      marginTop: '30px',
      fontWeight: 'bold',
      fontSize: '9px',
      width: '100%',
    },
    title: {
      fontSize: '18px',
      display: 'block',
      width: '540px',
      fontWeight: 400,
      borderBottom: '2px solid black',
      borderTop: '1.5px solid black',
      textTransform: 'uppercase',
      textAlign: 'center',
      paddingTop: '5px',
      fontFamily: "termina",
      paddingBottom: '5px',
    },
    accessoryTitle: {
      fontSize: '14px',
      display: 'block',
      width: '540px',
      fontWeight: 400,
      borderBottom: '1px solid black',
      textTransform: 'uppercase',
      textAlign: 'center',
      paddingTop: '2px',
      fontFamily: "termina",
      paddingBottom: '2px',
    },
    comment: {
      fontFamily: "termina",
      paddingTop: '5px',
      fontSize: '12px',
    },
    profile: {
      borderBottom: '0.8px solid black',
      height: '125px',
      width: '540px',
      paddingTop: '20px',
      flexDirection: 'row',
      display: 'flex',
      flexWrap: 'nowrap',
    },
    element: {
      borderBottom: '0.4px solid black',
      height: '125px',
      width: '540px',
      paddingTop: '20px',
      flexDirection: 'row',
      display: 'flex',
      flexWrap: 'nowrap',
    },
    profileText: {
      marginLeft: '10px',
      height: '83px',
      width: '250px',
    },
    profileTextX2: {
      marginLeft: '10px',
      height: '83px',
      width: '445px',
    },
    profileDescribe: {
      fontSize: '9px',
      fontFamily: "roboto",
    },
    profileDescribeX2: {
      fontSize: '9px',
      maxWidth: '445px',
      wordWrap: 'wrap',
      fontFamily: "roboto",
      flexDirection: 'row',
    },
    profileTextBolder: {
      fontWeight: 'bold',
      fontSize: '9px',
    },
    profileTextBolderX2: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      wordWrap: 'wrap',
      maxWidth: '400px',
      fontWeight: 'bold',
      fontSize: '9px',
    },
    profileTitle: {
      textTransform: 'uppercase',
      fontFamily: "termina",
      width: '440px',
      fontWeight: 'bold',
      fontSize: '9px',
    },
    circle: {
      width: '40px',
      height: '40px',
      fontFamily: "termina",
      fontWeight: 'bold',
      display: 'flex',
      fontSize: '10px',
      justifyContent: 'center',
      alignContent: 'center',
      backgroundColor: '#F2F2F2',
      borderRadius: '50%',
      aspectRatio: '1',
      textAlign: 'center',
      marginTop: '20px',
      marginBottom: '0px',
    },
    image: {
      width: '85px',
      height: '85px',
    },
    marginTop: {
      width: '540px',
      height: '20px',
    },
    marginBottom: {
      width: '540px',
      height: '20px',
      position: 'absolute',
      bottom: '0px',
    },
    canvaImage: {
      // maxHeight: '690px',
      maxHeight: '620px',
    }
});