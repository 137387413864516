const actionTypes = {
  load: "LOAD",
  update: "UPDATE",
  remove: "REMOVE",
  add_profile: "ADD_PROFILE",
  update_profile: "UPDATE_PROFILE",
  remove_profile: "REMOVE_PROFILE",
};
const projectReducer = (project, action) => {
  const { profile } = action;
  switch (action.type) {
    case actionTypes.load:
      const { loadedProject } = action;
      return loadedProject;
    case actionTypes.update:
      return action.project;
    case actionTypes.add_profile:
      return {
        ...project,
        profileId: project.profileId + 1,
        profiles: [
          ...project.profiles,
          { ...profile, id: project.profileId + 1 },
        ],
      };
    case actionTypes.update_profile:
      return {
        ...project,
        profiles: project.profiles.map((i) =>
          i.id === profile.id ? profile : i
        ),
      };
    case actionTypes.remove_profile:
      return {
        ...project,
        profiles: project.profiles.filter((i) => i.id !== profile.id),
      };
    //case actionTypes.remove:
    //  return project.profiles.filter((i) => i.id !== id);
    default:
      console.log("no action");
      return;
  }
};

export default projectReducer;
