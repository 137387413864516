import React, { useState, useEffect } from "react";
import Konva from "konva";
import { Stage, Layer, Rect, Group, useStrictMode } from "react-konva";
import Profile from "./canvas/profile";
import ProfileRuler from "./canvas/profileRuler";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useProject } from "./../store/projectProvider";
import Grid from "./canvas/grid";
import Rulers from "./canvas/rulers";
import Zoom from "./canvas/zoom";
import Title from "./canvas/title";
import Tools from "./canvas/tools";
import { toast } from "react-toastify";
import useImage from "use-image";
import profileTemplate from "../jsonTemplates/profileTemplate";
import elementTemplate from "../jsonTemplates/elementTemplate";
import axios from "axios";
import Legend from "./canvas/legend";
import Element from "./canvas/element";
import i18n from "i18next";
import {useTranslation} from "react-i18next";

const Area = (props) => {
  useStrictMode(true);
  const navigate = useNavigate();
  const {
    project,
    updateProfile,
    removeProfile,
    profileHasCollision,
    elementIsOutside,
    getProfileSize,
    getProfileById,
    profileConnected,
    getProfileNumberOfCollisions,
    getProfileConnectors,
    clearOtherProfilesConnections,
    dispatch,
    getProfileTypeDetails,
    saveProject,
  } = useProject();
  let { profileId } = useParams();

  const stageRef = React.useRef();
  const layerRef = React.useRef();

  const topMenuHeight = 100;
  let sideMenuWidth = 0;
  if (window.innerWidth > 2100) sideMenuWidth = 630;
  else if (window.innerWidth < 1570) sideMenuWidth = 470;
  else sideMenuWidth = window.innerWidth * 0.3;
  const [canvaWidth, setCanvaWidth] = useState(window.innerWidth - sideMenuWidth);
  const [canvaHeight, setCanvaHeight] = useState(window.innerHeight - topMenuHeight);

  const [selectedProfile, setSelectedProfile] = useState();
  const [selectedElement, setSelectedElement] = useState();
  const [selectedElementProduct, setSelectedElementProduct] = useState();
  const [productCodeY, setProductCodeY] = useState();
  const [adapterWidth, setAdapterWidth] = useState();
  const { t, i18n} = useTranslation("translation");


  const [stage, setStage] = useState({
    scale: 1,
    x: 0,
    y: 0,
    width: canvaWidth,
    height: canvaHeight,
  });

  const [boundingBox, setBoundingBox] = useState({
    x: 0,
    y: 0,
    width: project.width * 0.139,
    height: project.length * 0.139,
  });

  const zoomIn = () => {
    const newScale = stage.scale + 0.1;
    setStage({
      ...stage,
      scale: newScale,
    });
  };

  const zoomOut = () => {
    const newScale = stage.scale - 0.1;
    setStage({
      ...stage,
      scale: newScale,
    });
  };

  const printCanvaScreen = () => {
    setSelectedElement(null);

    if(!selectedElement) {
      const dataURL = stageRef.current.toDataURL();

      let link = document.createElement("a");
      link.download = "stage.png";
      link.href = dataURL;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const showRulers = () => {
    props.setRuler(!props.ruler);
    setSelectedElement(false);
    setSelectedProfile(false);
  }

  useEffect(() => {
    if (props.newProfile) {
      const position = layerRef.current.getRelativePointerPosition();
      if (props.newProfile && position) addProfile(position.x, position.y);
    }
  }, [props.newProfile]);

  useEffect(() => {
    if (profileId === undefined) {
      setSelectedProfile(null);
    }
  }, [profileId]);

  useEffect(() => {
    //initial autoscale
    let newScale = 1;

    if (boundingBox.width > boundingBox.height) {
      newScale = stage.width / boundingBox.width;
    } else {
      newScale = stage.height / boundingBox.height;
    }

    newScale = newScale > 1 ? 1 : newScale * 0.72;

    const rulersSize = 30;
    const titleSize = 20;

    //initial scale

    setStage({
      ...stage,
      scale: newScale,
      x: (stage.width - rulersSize - boundingBox.width * newScale) / 2,
      y: (stage.height - rulersSize + titleSize - boundingBox.height * newScale) / 2,
    });
  }, []);

  /* scroll/zoom canvas */
  const handleWheel = (e) => {
    e.evt.preventDefault();

    const scaleBy = 1.02;
    const stage = e.target.getStage();

    const oldScale = stage.scaleX();
    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
    };

    const newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;
    setStage({
      ...stage,
      scale: newScale,
      x: (stage.getPointerPosition().x / newScale - mousePointTo.x) * newScale,
      y: (stage.getPointerPosition().y / newScale - mousePointTo.y) * newScale,
    });
  };

  const addProfile = async (x, y) => {
    const elementsNum = props.newProfile.value / 280;
    let elements = [];
    for (let i = 1; i <= elementsNum; i++) {
      elements.push({
        ...elementTemplate,
        id: i,
      });
    }

    let url = `https://api.konfigurator.jellydev.pl/api/product/`;
    url = url + props.newProfile.apiId + "?lang=" + project.language;

    const res = await axios.get(url);
    const product = res.data;

    if (product.properties.length > 1) {
      const finishing = product.properties[1].options[0];
      const color = { finishing };
      product.selectedProperties = color;
    }

    let connectorsWidth = 40;
    if (props.newProfile.surfaceType === "suspended" || props.newProfile.surfaceType === "wall-mounted") {
      connectorsWidth = 4;
    }

    const profileTmp = {
      ...profileTemplate,
      x: x,
      y: y,
      type: props.newProfile.surfaceType,
      width: elementsNum * 39 + connectorsWidth,
      height: 13,
      elements: elements,
      length: props.newProfile,
      product: product,
    };

    const connectors = getProfileConnectors(profileTmp);
    dispatch({
      type: "ADD_PROFILE",
      profile: {
        ...profileTmp,
        connectorStart: connectors.connectorStart,
        connectorEnd: connectors.connectorEnd,
      },
    });

    props.setNewProfile(null);
    saveProject();
  };

  const profileClick = (profile) => {
    props.setRuler(false);
    setSelectedProfile(profile);
    navigate("/profile/" + profile.id);
  };

  const elementClick = (element) => {
    props.setRuler(false);
    setSelectedElement(element);
    setSelectedElementProduct(null)
  }

  const elementProductClick = (element, trackElement) => {
    setSelectedElement(element);
    setSelectedElementProduct(trackElement);
  }

  const profileChangedPosition = (profile, newX, newY) => {
    let profileTmp = { ...profile, x: newX, y: newY };
    const numOfCollisions = getProfileNumberOfCollisions(profileTmp);
    if (elementIsOutside(profileTmp, boundingBox)) {
      toast(t("translation:profile:warning_invalid"), { type: toast.TYPE.INFO });
      updateProfile(profile);
    } else if (numOfCollisions > 1) {
      toast(t("translation:profile:warning_collision"), {
        type: toast.TYPE.INFO,
      });
      updateProfile(profile);
    } else if (numOfCollisions === 1) {
      const profileConnectedInfo = profileConnected(profileTmp);
      if (profileConnectedInfo.connected) {
        profileTmp = {
          ...profile,
          x: profileConnectedInfo.newCoords.x,
          y: profileConnectedInfo.newCoords.y,
        };
        //last check becase of small offset when connecting
        const numOfCollisions = getProfileNumberOfCollisions(profileTmp);
        if (elementIsOutside(profileTmp, boundingBox) || numOfCollisions > 1) {
          updateProfile(profile);
        } else {
          updateProfile(profileTmp);
          return true;
        }
      } else {
        toast(t("translation:profile:warning_collision"), {
          type: toast.TYPE.INFO,
        });
        updateProfile(profile);
      }
    } else {
      updateProfile(profileTmp);
      return true;
    }
  };

  const profileDragStart = (profile) => {
    props.setRuler(false);
    //empty connections from selected profile
    const profileTmp = {
      ...profile,
      connectorStart: {
        ...profile.connectorStart,
        connectedProfileId: null,
      },
      connectorEnd: {
        ...profile.connectorEnd,
        connectedProfileId: null,
      },
    };
    updateProfile(profileTmp);

    //remove all out-of-date connections from all other profiles
    clearOtherProfilesConnections(profile);

    navigate("/profile/" + profile.id);
    setSelectedProfile(null);
  };

  const profileRemove = (profile) => {
    props.setRuler(false);
    navigate("/profiles/" + profile.type + "/" + profile.length.apiId);
    setSelectedProfile(null);
    removeProfile(profile);
  };

  const profileRotate = (oldProfile) => {
    let profile;

    project.profiles.filter(p => p.id === oldProfile.id).map(pr => {
      profile = pr;
    })

    props.setRuler(false);
    const newOrientation = profile.orientation === "horizontal" ? "vertical" : "horizontal";
    const profileSize = getProfileSize(profile, newOrientation);
    const connectors = getProfileConnectors(profile, newOrientation);

    const profileTmp = {
      ...profile,
      orientation: newOrientation,
      width: profileSize.width,
      height: profileSize.height,
      connectorStart: connectors.connectorStart,
      connectorEnd: connectors.connectorEnd,
    };
    if (profileHasCollision(profileTmp)) {
      updateProfile(profile);
      toast(t("translation:profile:warning_collision"), {
        type: toast.TYPE.INFO,
      });
    } else if (elementIsOutside(profileTmp, boundingBox)) {
      updateProfile(profile);
      toast(t("translation:profile:warning_invalid"), { type: toast.TYPE.INFO });
    } else {
      setSelectedProfile(profileTmp);
      updateProfile(profileTmp);
    }
    setSelectedElement(null);
  };

  const smooth = (val) => {
    if (val % 1 === 0) return val + 0.5;
    return val;
  };

  const getImage = (image) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [img] = useImage(image);
    return img;
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDrop = (e) => {
    e.preventDefault();
    stageRef.current.setPointersPositions(e);
  };

  const getNextProfile = (profile) => {
    if (profile.connectorEnd.connectedProfileId) {
      return getProfileById(profile.connectorEnd.connectedProfileId);
    }
  };
  const getPrevProfile = (profile) => {
    if (profile.connectorStart.connectedProfileId) {
      return getProfileById(profile.connectorStart.connectedProfileId);
    }
  };

  const getParams = (profile) => {
    const connectorsWidth = profile.type === "suspended" || profile.type === "wall-mounted" ? 1 : 19;
    const prevProfile = getPrevProfile(profile);
    const nextProfile = getNextProfile(profile)
    const params = {
      length: 0,
      connectors: 0,
    }
    params.length = profile.elements.length * 39 + 2 * connectorsWidth;

    if(nextProfile && prevProfile) {
      if(nextProfile.orientation !== profile.orientation && prevProfile.orientation !== profile.orientation) {
        if(profile.type === "suspended" || profile.type === "wall-mounted") {
          params.length = profile.elements.length * 39 + 2 * connectorsWidth + 30 + 1;
        } else {
          params.length = profile.elements.length * 39 + 2 * connectorsWidth + 30;
        }
        params.connectors = 2;
      } else if(nextProfile.orientation !== profile.orientation || prevProfile.orientation !== profile.orientation) {
        if(profile.type === "suspended" || profile.type === "wall-mounted") {
          params.length = profile.elements.length * 39 + 14 + 2;
        } else {
          params.length = profile.elements.length * 39 + 2 * connectorsWidth + 14 + 2;
        }
        params.connectors = 2;
      } else {
        params.connectors = 1;
      }
    } else if(nextProfile && nextProfile.orientation !== profile.orientation ||
      prevProfile && prevProfile.orientation !== profile.orientation) {
      if(profile.type === "suspended" || profile.type === "wall-mounted") {
        params.length = profile.elements.length * 39 + 14 + 2;
      } else {
        params.length = profile.elements.length * 39 + 2 * connectorsWidth + 14 + 2;
      }
      params.connectors = 1;
    }

    return params;
  }

  const unselectElement = (e) => {
    if(! e.target.attrs.image) {
      setSelectedElement(null);
    }
  }

  return (
    <>
      <div onDragOver={onDragOver} onDrop={onDrop}>
        <Stage
          ref={stageRef}
          width={canvaWidth}
          height={canvaHeight}
          onWheel={handleWheel}
          scaleX={stage.scale}
          scaleY={stage.scale}
          x={stage.x}
          y={stage.y}
          onClick={(e) => unselectElement(e)}
        >
          <Layer ref={layerRef} draggable={true}>
            <Rect
              name="boundingBox"
              x={smooth(boundingBox.x)}
              y={smooth(boundingBox.y)}
              width={boundingBox.width}
              height={boundingBox.height}
              fill="white"
            />

            <Grid box={boundingBox} />
            <Title box={boundingBox} project={project} scale={stage.scale} />
            <Rulers box={boundingBox} project={project} scale={stage.scale} />
            {project.profiles.length > 0 &&
              project.profiles.map((profile, i) => {
                return (
                  <>
                    <Profile
                      key={i}
                      profile={profile}
                      profileClick={profileClick}
                      elementClick={elementClick}
                      elementProductClick={elementProductClick}
                      profileDragEnd={profileChangedPosition}
                      profileDragStart={profileDragStart}
                      selectedProfileId={profileId}
                      getImage={getImage}
                      nextProfile={getNextProfile(profile)}
                      prevProfile={getPrevProfile(profile)}
                    />
                  </>
                );
              })}
            {props.ruler ? (
                project.profiles.map(profile => {
                  return (
                    <ProfileRuler
                      prevProfile={getPrevProfile(profile)}
                      profile={profile}
                      nextProfile={getNextProfile(profile)}
                      params={getParams(profile)}
                    />
                  )
                })
              ) : (<></>)
            }
            <Tools scale={stage.scale} profile={selectedProfile} removeProfile={profileRemove} rotateProfile={profileRotate} />
            <Element
              scale={stage.scale}
              text={t("translation:profile:product_code")}
              element={selectedElement} profile={selectedProfile}
              elementProduct={selectedElementProduct}
              productCodeY={productCodeY}
              setProductCodeY={setProductCodeY}
              adapterWidth={adapterWidth}
              setAdapterWidth={setAdapterWidth}
            />
          </Layer>
        </Stage>
      </div>
      <Zoom zoomIn={zoomIn} zoomOut={zoomOut} printCanvaScreen={printCanvaScreen} showRulers={showRulers}/>
      <Legend />
    </>
  );
};

export default Area;
