import { Button } from "react-bootstrap";
import {Link, useNavigate, useParams} from "react-router-dom";
import { useProject } from "./../store/projectProvider";
import axios from "axios";
import React, {useState, useEffect} from "react";
import Loader from "./../components/loader";
import CustomScroll from "react-custom-scroll";
import i18n from "i18next";
import {useTranslation} from "react-i18next";

const SystemAccessories = () => {
  const navigate = useNavigate();
  const { project, getProfileById, getSystemById, updateAccessories } = useProject();
  const [connectorsArray, setConnectors] = useState([]);
  const [plugsArray, setPlugs] = useState([]);
  const [pendantsArray, setPendants] = useState([]);
  const [electronicsArray, setElectronics] = useState([]);
  const { systemId } = useParams();
  const [system, setSystem] = useState(getSystemById(systemId));
  const [profilesCategoryId, setProfilesCategoryId] = useState([]);
  const { t, i18n} = useTranslation("translation");

  const loadProfilesCategoryId = () => {
    const profilesIdArray = [];
    system.profiles.map(profile => {
      let flag = false;
      if(profilesIdArray.length > 0) {
        profilesIdArray.map(id => {
          if(id === profile.product.id) {
            flag = true;
          }
        })
        if(flag === false) {
          profilesIdArray.push(profile.product.id);
        }
      } else {
        profilesIdArray.push(profile.product.id);
      }
    })
    setProfilesCategoryId(profilesIdArray);
  }

  const loadAccessory = (type) => {
    let accessoryType, id, state;
    if(type === "connectors") {
      id = 4;
      accessoryType = system.accessories.connectors;
      state = setConnectors;
    } else if (type === "plugs") {
      id = 5;
      accessoryType = system.accessories.plugs;
      state = setPlugs;
    } else if (type === "pendants"){
      id = 3;
      accessoryType = system.accessories.pendants;
      state = setPendants;
    } else {
      id = 7;
      accessoryType = system.accessories.electronics;
      state = setElectronics;
    }

    axios
      .get(`https://api.konfigurator.jellydev.pl/api/category/${id}?lang=${project.language}`)
      .then((response) => {
        const accessoryArray = [];
        if(!accessoryType) {
          response.data.products.map((product) => {
            let isTooPush = false;
            product.relatedProducts.map(prod => {
              profilesCategoryId.map(id => {
                if(prod.id === id) {
                  product.count = 0;
                  isTooPush = true;
                }
              })
            })
            if(product.category.name === 'electronics') {
              product.count = 0;
              isTooPush = true;
            }
            if(isTooPush) {
              accessoryArray.push(product);
            }
          });
          if(accessoryArray.length === 0) {
            state(null);
          } else {
            state(accessoryArray);
          }
        } else {
          response.data.products.map((product) => {
            if(accessoryType.find((element) => element.id === product.id)) {
              const count = accessoryType.find((element) => element.id === product.id).count;
              product.count = count;
            } else {
              product.count = 0;
            }

            let isTooPush = false;
            product.relatedProducts.map(prod => {
              profilesCategoryId.map(id => {
                if(prod.id === id) {
                  isTooPush = true;
                }
              })
            })
            if(product.category.name === 'electronics') {
              isTooPush = true;
            }
            if(isTooPush) {
              accessoryArray.push(product);
            }
          });

          if(accessoryArray.length === 0) {
            state(null);
          } else {
            state(accessoryArray);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const countOfAccessories = (accessory) => {
    let value = 0;
    switch (accessory) {
      case "connectors":
        let straightConnectors = 0;
        let angleConnectors = 0;
        system.profiles.map(profile => {
          if(profile.connectorEnd.connectedProfileId) {
            const nextProfile = getProfileById(profile.connectorEnd.connectedProfileId);
            if(profile.orientation === nextProfile.orientation) {
              straightConnectors +=1;
            } else if(nextProfile.connectorEnd.connectedProfileId === profile.id && profile.orientation != nextProfile.orientation) {
              angleConnectors +=0.5;
            } else if(profile.orientation != nextProfile.orientation) {
              angleConnectors +=1;
            }
          }
          if(profile.connectorStart.connectedProfileId) {
            const prevProfile = getProfileById(profile.connectorStart.connectedProfileId);
            if(prevProfile.connectorStart.connectedProfileId === profile.id && profile.orientation != prevProfile.orientation) {
              angleConnectors +=0.5;
            }
          }
        })
        return (
          <>
            <p>
              {t("translation:system_accessories:straight_connector")}:
              <b> {straightConnectors}</b> {t("translation:system_accessories:count")}.<br></br>
              {t("translation:system_accessories:angle_connectors")} (90°):
              <b> {angleConnectors}</b> {t("translation:system_accessories:count")}.
            </p>
          </>
        );
      // case "pendant":
      //   value = 2;
      //   break;
      default:
        value = project.systems.length * 2;
        break;
    }
    return value;
  };

  const handleClick = (type, id, action) => {
    let arrayToEdit = "";
    let state = "";

    switch (type.category.name) {
      case "end_cap":
        arrayToEdit = plugsArray;
        state = setPlugs;
        break;
      case "connectors":
        arrayToEdit = connectorsArray;
        state = setConnectors;
        break;
      case "suspensions":
        arrayToEdit = pendantsArray;
        state = setPendants;
        break;
      default:
        arrayToEdit = electronicsArray;
        state = setElectronics;
    }

    state(() => {
      const newArray = arrayToEdit.map((element, i) => {
        if (i === id) {
          if (action === "add") {
            return {
              ...element,
              count: element.count + 1,
            };
          } else {
            if (element.count != 0) {
              return {
                ...element,
                count: element.count - 1,
              };
            } else {
              return element;
            }
          }
        } else {
          return element;
        }
      });

      return newArray;
    });
  };

  const saveAccessories = () => {
    updateAccessories(plugsArray, connectorsArray, pendantsArray, electronicsArray, system);
    navigate("/accessories");
  }

  const renderAccesories = (accesory, index) => {
    return(
      <div key={index} className="accessory py-2">
        <div className="position-relative configurator-data">
          <div className="configurator-data-img">
            <img className="img-fluid" src={accesory.thumbnail.url} alt="" />
          </div>
          <div className="configurator-data-content max-w">
            <p>{accesory.label}</p>
            {accesory.properties.map(prop => {
              return(
                prop.options[0] ? (
                  prop.label == "Attention" ? (
                    <p className="red">
                      {prop.label}: <span>{prop.options[0].label}</span>
                    </p>
                    ):( <p>
                    {prop.label}: <span>{prop.options[0].label}</span>
                    </p>
                  )
                ): <></>
              )
            })}
            <p>
              {t("translation:profile:product_code")}: <span>{accesory.code}</span>
            </p>
          </div>
          <div className="circle">{accesory.count} {t("translation:system_accessories:count")}.</div>
        </div>
        <div className="battery-pill">
          <Button className="btn-text" onClick={() => handleClick(accesory, index, "remove")}>
            <img src="../img/remove-circle.svg" alt="" />
          </Button>
          <Button className="btn-text" onClick={() => handleClick(accesory, index, "add")}>
            <img src="../img/add-circle.svg" alt="" />
          </Button>
        </div>
      </div>
    );
  }

  useEffect(() => {
    if(connectorsArray && connectorsArray.length === 0) {
      loadAccessory("connectors");
    } else if(plugsArray && plugsArray.length === 0) {
      loadAccessory("plugs");
    } else if(pendantsArray && pendantsArray.length === 0) {
      loadAccessory("pendants");
    } else if(electronicsArray && electronicsArray.length === 0) {
      loadAccessory("electronics");
    }

    if(profilesCategoryId.length === 0) {
      loadProfilesCategoryId();
    }
  }, [connectorsArray, pendantsArray, plugsArray, electronicsArray, profilesCategoryId]);

  useEffect(() => {
    loadAccessory("connectors");
  }, [i18n.language])

  return (
    <>
      <div className="configurator-nav">
        {" "}
        <Link to="/accessories" className="btn btn-text text-start btn-back img-left">
          <img src="/img/arrow-circle-left.svg" alt="" />
          {t("translation:system_accessories:back_to_systems")}
        </Link>
        <Button className="btn-text btn-next img-right" onClick={() => saveAccessories()}>
          {t("translation:system_accessories:save_accessory")}
          <img src="/img/arrow-circle-right.svg" alt="" />{" "}
        </Button>
      </div>
      <CustomScroll>
        {(connectorsArray && connectorsArray.length !== 0) || connectorsArray === null
        && (plugsArray && plugsArray.length > 0) || plugsArray === null
          && (pendantsArray && pendantsArray.length > 0) || pendantsArray === null ? (
          <div className="configurator-wrapper choose-accessories">
            <h1>{t("translation:system_accessories:choose_accessories")}</h1>
            {connectorsArray && connectorsArray.length !== 0 ? (
              <>
                <div className="accessory bt pt-3 pb-1">
                  <h3>{t("translation:accessories:connectors")}:</h3>
                </div>
                <span className="hint">{t("translation:system_accessories:accessories_count")}: {countOfAccessories("connectors")}</span>
                {connectorsArray.map((connectors, index) => renderAccesories(connectors, index))}
              </>
            ) : (<></>)}
            {plugsArray && plugsArray.length > 0 ? (
                <>
                  <div className="accessory pt-3 pb-1">
                    <h3>{t("translation:accessories:plugs")}:</h3>
                  </div>
                  {plugsArray.map((plugs, index) => renderAccesories(plugs, index))}
                </>
              ) :
              (<></>)
            }
            {pendantsArray && pendantsArray.length > 0 ? (
                <>
                  <div className="accessory pt-3 pb-1">
                    <h3>{t("translation:accessories.pendants")}:</h3>
                  </div>
                  {pendantsArray.map((pendants, index) => renderAccesories(pendants, index))}
                </>
              ) :
              (<></>)
            }
            {electronicsArray && electronicsArray.length > 0 ? (
                <>
                  <div className="accessory pt-3 pb-1">
                    <h3>{t("translation:accessories.electronics")}:</h3>
                  </div>
                  {electronicsArray.map((electronics, index) => renderAccesories(electronics, index))}
                </>
              ) :
              (<></>)
            }
          </div>
        ): (
          <Loader />
        )}
      </CustomScroll>
    </>
  );
};

export default SystemAccessories;