import React from "react";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import i18n from "i18next";
import { Translation } from 'react-i18next';

class Delete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      id: props.project.id,
      username: props.project.creatorName,
      load: props.reload,
      header: props.header,
    };
  }

  deleteProject(id, username) {
    const body = {
      username: username,
    }
    axios
      .delete(`https://api.konfigurator.jellydev.pl/api/project/${id}?lang=${i18n.language}`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      }
      )
      .then(response => {
        this.state.load();
      })
      .catch(err => {
        console.log(err);
      })
  }
  handleModal() {
    this.setState({ show: !this.state.show });
  }
  render() {
    return (
      <div>
        <Button className="list-icon"
          onClick={() => {
            this.handleModal();
          }}
        >
          <img src="img/delete.svg" alt="" />
        </Button>
        <Modal show={this.state.show} onHide={()=>this.handleModal()} className="delete-modal delete list-modal">
          <Modal.Header>
            <Translation>{(t) => t('translation:project_list:delete_project')}</Translation>
          </Modal.Header>
          <Button onClick={() => {
            this.deleteProject(this.state.id, this.state.username);
          }}>
            <Translation>{(t) => t('translation:project_list:confirm')}</Translation>
          </Button>
          <Button onClick={() => {
            this.handleModal();
          }}>
            <Translation>{(t) => t('translation:project_list:deny')}</Translation>
          </Button>
        </Modal>
      </div>
    );
  }
}

export default Delete;