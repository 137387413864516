import React, { useEffect, useState } from "react";
import { Container, Button } from "react-bootstrap";
import Area from "../components/area";
import ConfiguratorNav from "../components/configuratorNav";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useProject } from "./../store/projectProvider";
import ProfileObject from "../components/profile-object";
import CustomScroll from "react-custom-scroll";
import ProductRow from "../components/product-row";
import {useTranslation} from "react-i18next";

const Profile = () => {
  let { profileId } = useParams();
  const { getProfileById, getProfileCode, getProfileLength } = useProject();
  const [profile, setProfile] = useState({});
  const { t, i18n} = useTranslation("translation");

  useEffect(() => {
    (async () => {
      const profileTmp = await getProfileById(profileId);
      setProfile(profileTmp);
    })();
  }, [profileId]);

  return (
    <>
      <ConfiguratorNav />
      <CustomScroll>
        <div className="configurator-wrapper">
          <div className="configurator-title">
            <h1>
              {t("translation:profile:config")}
            </h1>
            <p>{t("translation:profile:edit")}</p>
            <div>{profile && <ProfileObject profile={profile} />}</div>
          </div>

          <div className="item-configurator">
            <div className="position-relative configurator-data parameters-selected">
              <div className="configurator-data-img">
                <img className="img-fluid" src={profile.product?.thumbnail.url} alt="" />
                <div className="icon-container">
                  <Link to={"/product-profile/" + profile.id} className="btn btn-icon">
                    <img src="/img/edit.svg" alt="edit" />
                  </Link>
                </div>
              </div>
              <div className="configurator-data-content">
                <p>{profile.product?.label}</p>
                <p>
                  {t("translation:profile:length")}: <span>{profile.length && profile.length.label}</span>
                  {profile.type !== "suspended" ? (
                      profile.type !== "wall-mounted" ? <span> (+280 mm)</span>:<></>
                    )
                    :<></>}
                </p>
                {getProfileLength(profile) ?
                  (
                    <p>{t("translation:profile:connectors_length")}: <span>{getProfileLength(profile)} mm</span></p>
                  ) : (<></>)}

                <p>
                  {t("translation:profile:finishing")}:{" "}
                  <span>{profile.product?.selectedProperties && profile.product.selectedProperties.finishing.label}</span>
                </p>
                <p>
                  {t("translation:profile:product_code")}: <span>{profile.length && getProfileCode(profile)}</span>
                </p>
              </div>
            </div>
            {profile.elements
              ?.filter((e) => e.product !== null)
              .map((element) => {
                return (
                  <div key={element.id} >
                    <ProductRow profile={profile} element={element}/>
                    {element.product.elements.length > 0 &&
                      element.product.elements
                        .filter((e) => e.product !== null)
                        .map((trackElement) => {
                          return (
                            <ProductRow
                              profile={profile}
                              element={element}
                              trackElement={trackElement}
                              key={element.id + "_" + trackElement.id}
                            />
                          );
                        })}
                  </div>
                );
              })}
          </div>
        </div>
      </CustomScroll>
    </>
  );
};

export default Profile;
